import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu, Breadcrumb, Tag, Divider, Dropdown } from "antd";
import dayjs from "dayjs";

import { orderDetail } from "@/api/donation-api";
import { statusTypes } from "@/types/enum";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import styled from "styled-components";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import { view } from "@/model/view";
import './order.less'

const OrderContainer = styled.div``;

const ContributionOrder = () => {
  const history = useHistory();
  let { id } = useParams() as any;
  const { store: { isMobile } } = useModel(view)
  const [info, setInfo] = useState<any>();

  const { store } = useModel(user);

  useEffect(() => {
    orderDetail(id).then(({ schedulePaymentDate, artist, ...others }: any) =>
      setInfo({
        ...others,
        schedulePaymentDate:
          (schedulePaymentDate &&
            dayjs(schedulePaymentDate).format("MM/DD/YYYY")) ||
          "",
        artist: [
          {
            value: artist?.id || "",
            label: artist?.name || "N/A",
          },
        ],
      })
    );
  }, [id]);

  return (
    <OrderContainer className="order-view-container">
      <Breadcrumb separator=">">
        {history.location.pathname.indexOf("/dashboard/history") > -1 ? (
          <Breadcrumb.Item href="/dashboard/history">
            Donation History
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item href="/dashboard/income">
            Income Overview
          </Breadcrumb.Item>
        )}

        <Breadcrumb.Item>Order details</Breadcrumb.Item>
      </Breadcrumb>
      <div className="flex order-header">
        <h2 className="title flex">
          Invoice {info?.orderId}
          {(info?.status && store.roles.includes("artist") && (
            <Tag
              className="status-tag"
              color={
                statusTypes[info?.status]
                  ? statusTypes[info?.status]?.color
                  : "gray"
              }
            >
              {statusTypes[info?.status]
                ? statusTypes[info?.status]?.label
                : info?.status}
            </Tag>
          )) ||
            ""}
        </h2>
        {info?.status !== 'failed' && <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item
                key="message"
                onClick={() =>
                  history.push(history.location.pathname + "/note")
                }
              >
                View thank you note
              </Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>}
        
      </div>
      {/*<div className="des">*/}
      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod*/}
      {/*  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim*/}
      {/*  veniam, quis nostrud exercitation ullamco.*/}
      {/*</div>*/}

      <div className="review-card-container">
        <div className="card-wrap">
          <h2 className="sub-title">Billing information</h2>
          <p className="flex">
            Invoice number
            <span>{info?.orderId}</span>
          </p>
          <p className="flex">
            Transaction ID
            <span>{info?.transactionId}</span>
          </p>
          <p className="flex">
            Payment method
            <span>{info?.paymentMethod}</span>
          </p>
          <p className="flex">
            Date paid
            <span>
              {info?.paymentMethod === 'check' ? (info?.status === 'check_pending' ? 'N/A' : dayjs(info?.billDate).format("MM/DD/YYYY")) : dayjs(info?.createDate).format("MM/DD/YYYY")}
            </span>
          </p>
        </div>
        <div className={`${isMobile ? '' : 'flex'}`}>
          <div className="card-wrap">
            <h2 className="sub-title">Issued to</h2>
            <p className="flex">
              Name
              <span translate="no">{info?.issuedTo?.name}</span>
            </p>
            <p className="flex">
              Email address
              <span translate="no">{info?.issuedTo?.email}</span>
            </p>
            <p className="flex">
              Billing address
              <span translate="no">{info?.issuedTo?.address}</span>
            </p>
          </div>
          <div className="card-wrap">
            <h2 className="sub-title">Issued By</h2>
            <p className="flex">
              Name
              <span translate="no">{info?.issuedBy?.name}</span>
            </p>
            <p className="flex">
              Email address
              <span translate="no">{info?.issuedBy?.email}</span>
            </p>
            <p className="flex">
              Billing address
              <span translate="no">{info?.issuedBy?.address}</span>
            </p>
          </div>
        </div>
        <div className="card-wrap">
          <h2 className="sub-title">Invoice summary </h2>
          <p className="flex">
            Product type
            <span>{info?.productType}</span>
          </p>
          <p className="flex">
            Donation type
            <span>{info?.donationType || 'N/A'}</span>
          </p>
          <p className="flex">
            Frequency
            <span>{info?.frequency === "once" ? "One time" : "Recurring"}</span>
          </p>
          <p className="flex">
            Payment method
            <span>{info?.paymentMethod}</span>
          </p>
          <p className="flex">
            Amount
            <span>
              {Number(info?.amount ?? 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
          <p className="flex">
            Tax
            <span>
              {Number(info?.tax ?? 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
          <Divider />
          <h3 className="flex total-item">
            Total amount
            <span>
              {Number(
                Number(info?.tax ?? 0) + Number(info?.amount ?? 0)
              ).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </h3>
        </div>

        <div className="card-wrap">
          <h2 className="sub-title">Donor note</h2>
          <p>{info?.donorNote || "N/A"}</p>
        </div>
      </div>
    </OrderContainer>
  );
};

export default ContributionOrder;
