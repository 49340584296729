//created by Pegg on 2021/1/27
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Divider, Dropdown, Menu, message } from "antd";
import DatePicker from "@/components/DatePicker";

import { ReloadOutlined } from "@ant-design/icons";
import { Column } from "@ant-design/charts";
import styled from "styled-components";

import avatar_img from "@/images/avatar.svg";
import "@/styles/dashboard/artist.less";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import { getArtist, artistChart, putArtist } from "@/api/artist-api";
import dayjs from "dayjs";
import { getEnv } from "@/libs/cfg";
import CopyToClipboard from "react-copy-to-clipboard";

const ArtistContainer = styled.div``;

const Artist = () => {
  const history = useHistory();
  let config = {
    data: [],
    xField: "type",
    yField: "sales",
    columnWidthRatio: 0.2,
    yAxis: {
      grid: {
        line: {
          style: {
            lineDash: [4, 5],
          },
        },
      },
    },
    meta: {
      type: { alias: "type" },
      sales: { alias: "amount" },
    },
  };
  const [user, setUser] = useState<any>("");
  const [chartData, setChartData] = useState<any>(config);
  const [views, setViews] = useState(0);

  const getArtistInfo = () => {
    let _data = {};
    getArtist(_data).then((data) => {
      setUser(data);
    });
  };
  const getChart = (e?: any) => {
    const [start, end] = e || [null, null];
    let _data = {
      start: start?.toDate(),
      end: end?.toDate(),
    };
    artistChart(_data).then((data) => {
      setChartData({
        ...config,
        data: data?.history,
      });
      setViews(data.total);
    });
  };

  const handleAllow = (allowSearch: boolean) => {
    let _data = {
      allowSearch,
    };
    putArtist(_data).then(() => {
      message.success("update successfully");
      getArtistInfo();
    });
  };

  useEffect(() => {
    getArtistInfo();
    getChart();
    // eslint-disable-next-line
  }, []);

  const profileLink = (id: number | string) => `${getEnv("WEB_HOST")}/profile/${(
    user?.displayName || "-"
  ).replace(/[^\w]/g, "-")}/${id}`;


  return (
    <ArtistContainer className="artist-container">
      <h1>Artist profile</h1>
      <div className="artist-list">
        <div className="artist-item">
          <img src={user?.avatar || avatar_img} alt="avatar" />
          <div className="artist-item-info">
            <p className="flex align-end">
              <h3 className="flex">
                {user?.displayName || user?.fullName}
                {/*<Tag color="orange">Profile strength: Intermediate</Tag>*/}
              </h3>
              <span className="artist-time">
                Last updated {"  "}
                {dayjs(user?.updateDate).format("DD/MM/YYYY h:mm A")}
              </span>
            </p>
            <div className="flex">
              <div className="ul-wrap flex start align-start">
                <ul>
                  <li style={{ fontWeight: 'bold', color: 'black' }}>{user?.primaryTag}</li>
                  {user?.tags?.slice(0, 4)?.map((item: string) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
                <ul>
                  {user?.tags?.slice(4, 8)?.map((item: string) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="btn-wrap flex end align-end">
                <Button onClick={() => handleAllow(!user?.allowSearch)}>
                  {user?.allowSearch ? "Hide" : "Publish"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => history.push(`/home/profile/${user.id}/edit`)}
                >
                  Edit profile
                </Button>
                <Dropdown
                  trigger={["click"]}
                  overlay={() => (
                    <>
                      <Menu>
                        <Menu.Item onClick={() => window.open(profileLink(user.id), '_blank')}>
                          View public profile
                        </Menu.Item>
                        <CopyToClipboard
                          text={profileLink(user.id)}
                          onCopy={() => message.success("Copy success")}
                        >
                          <Menu.Item >
                            Copy link to profile
                          </Menu.Item>
                        </CopyToClipboard>
                      </Menu>
                    </>
                  )}
                >
                  <span className="ant-dropdown-link" style={{ marginLeft: '16px', width: '56px', height: '56px', lineHeight: '56px' }}>
                    <FilterMore />
                  </span>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="range-picker-wrap field-form-items">
        <span>Date range</span>
        <DatePicker.RangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          dropdownClassName="date-picker-mobile"
          onChange={(e) => getChart(e)}
        />
      </div>
      <div className="artist-chart">
        <p className="flex chart-title">
          <span className="">Page views</span>
          <span>
            {/*Updated 10 minutes ago{" "}*/}
            <Button
              onClick={() => getChart()}
              icon={<ReloadOutlined />}
            ></Button>
          </span>
        </p>
        <h2>
          {views} views
          {/*<p>*/}
          {/*  <RiseOutlined /> 0 views*/}
          {/*</p>*/}
        </h2>
        <Divider style={{ color: "#aaa" }} />
        <Column {...chartData} />
      </div>
    </ArtistContainer>
  );
};

export default Artist;
