//created by Peggy on 2021/3/24
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, Steps } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Modal from "@/components/Modal/Info";
import { getSponsor } from "@/api/merbership-api";

import icon_grant from "@/images/icons/icon_grant.svg";

import styled from "styled-components";
import icon_campaign from "@/images/icons/icon_campaign.svg";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { getPopupButtonByMembershipStatus } from "@/utils/common";
import { TheField } from "@/components/GoogleTranslate";
const StepContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  text-align: center;
`;

const Step = ({ initFlag = false }: any) => {
  const history = useHistory();

  const {
    store: { isMobile },
  } = useModel(view);
  const [flag, setFlag] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [membershipStatus, setMembershipStatus] = useState("");
  const handleCheck = () => {
    let _data = {};
    getSponsor(_data).then((data: any) => {
      setMembershipStatus(data.status)
      if (data.status === "completed") {
        history.push("/dashboard/grant/request");
      } else {
        setShowRegistration(
          ["approved", "paid", "wait_for_signature"].includes(data.status)
        );
        setFlag(true);
      }
    });
  };
  return (
    <StepContainer className="step-container flex">
      <div className="step-wrap flex column start">
        <img src={icon_campaign} alt="campaign_icon" />
        <h2>You have no grant applications</h2>
        <p>
          If you are applying for a grant using <TheField/> as your fiscal
          sponsor, you must submit your application materials at least 10
          business days before the deadline. This gives ample time to review
          your application, provide feedback if necessary, and gather the
          documents required by the granting organization. Late application
          reviews are subject to availability. A $75 rush fee will apply.
        </p>
        <Steps
          current={0}
          progressDot={(dot: any, { index }: any) => (
            <span className="border-item">{index + 1}</span>
          )}
          style={{ display: isMobile ? "none" : "flex" }}
        >
          <Steps.Step
            title="Share"
            description={<>Share your grant and solicitation materials to <TheField/> for review.</>}
          />
          <Steps.Step
            title="Review"
            description={<><TheField/> reviews your materials and gathers supporting documents as needed.</>}
          />
          <Steps.Step
            title="Feedback"
            description={<><TheField/> provides valuable feedback and instructions for the application</>}
          />
        </Steps>
        <Button type="primary" onClick={handleCheck}>
          request grant review
        </Button>
        <Link to="/grants-and-opportunities">Explore Grants</Link>
      </div>
      <Modal
        visible={flag}
        width={420}
        className="grant-info-modal"
        footer={[
          <Button key="back" onClick={() => setFlag(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
        img={icon_grant}
      >
        <h2>
          {showRegistration
            ? "One more step to apply for the grant"
            : "Two more steps to apply for the grant"}
        </h2>
        {showRegistration ? (
          <p>
            In order to proceed, you will need to complete your registration for
            Fiscal Sponsorship.
          </p>
        ) : (
          <>
            <p>
              In order to apply for this grant, you will need to apply and
              register for Fiscal Sponsorship. Here's what you'll get:
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Eligibility for this grant</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>
                Free review and feedback on grant applications and fundraising
                materials
              </span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>And much more</span>
            </p>
          </>
        )}
      </Modal>
    </StepContainer>
  );
};

export default Step;
