import React from "react";
import { Link } from "react-router-dom";
import FooterLayout from "./FooterLayout";
import logo from "@/images/logo-text.svg";

interface props {
  children: React.ReactNode;
}

const HomeLayout = ({ children }: props) => {
  return (
    <>
      <header className="sign-wrap flex start">
        <span>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </span>
        <span></span>
      </header>
      <div className="sign-main-container">
        <main className="sign-main">{children}</main>
        <FooterLayout />
      </div>
    </>
  );
};

export default HomeLayout;
