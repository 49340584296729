//created by Peggy on 2021/5/24
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Divider, Input, Select, Button, Tag } from "antd";

import HomeLayout from "@/components/layouts/HomeLayout";

import { disciplineOptions as options } from "@/types/enum";
import { getArtistList } from "@/api/artist-api";

import "@/styles/home/profile-list.less";
import styled from "styled-components";
import SocialLayout from "@/components/layouts/SocialLayout";
import avatar_img from "@/images/avatar_default.jpg";
import dayjs from "dayjs";
import GAEvent from "@/utils/GAEvent";
import { NoTranslate } from "@/components/GoogleTranslate";
const ListContainer = styled.div``;

const List = () => {
  const history = useHistory();
  let { discipline } = useParams() as any;
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 24,
    relevance: "relevance",
    sponsored: 1,
    withCrowd: 1, // request campaigns as well
    discipline:
      discipline &&
      options?.includes(
        discipline?.replace(/^\S/, (s: string) => s.toUpperCase())
      )
        ? discipline
        : undefined,
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const getList = () => {
    setLoading(true);
    const _params = { ...params, keyword: text };
    getArtistList(_params)
      .then((data: any) => {
        setTableData(tableData.concat(data.items));
        setTotal(data.meta.totalItems);
        setLoading(false);
        if (
          params?.keyword ||
          params?.discipline ||
          params?.relevance !== "relevance"
        ) {
          setShowResult(true);
        }
      })
      .catch((e) => setLoading(false));
  };

  const [showResult, setShowResult] = useState(false);
  const handleReset = () => {
    setShowResult(false);
    setText("");
    setParams({
      page: 1,
      limit: 24,
      keyword: "",
      discipline: undefined,
      relevance: "relevance",
      sponsored: 1,
      withCrowd: 1, // request campaigns as well
    });
  };
  useEffect(() => {
    if (
      discipline &&
      !options?.includes(
        discipline?.replace(/^\S/, (s: string) => s.toUpperCase())
      )
    ) {
      history.replace("/contribute-to-an-artist");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (tableData.length) {
      const items = tableData.map((item, index) => ({
        item_name: item.displayName,       // Name or ID is required.
        item_id: item.id,
        index: index + 1,
        quantity: 1
      }))
      GAEvent("view_item_list", {
        items,
      })
    }

  }, [tableData])
  return (
    <HomeLayout style={{ background: "#f1f1f6" }}>
      <ListContainer className="profile-list-container flex column start">
        <h1>Contribute to an Artist </h1>
        <p>
          Find artists and campaigns by name or keywords, or browse by
          disciplines <br />
          to find new artists you’ll love!
        </p>
        <div className="flex search-wrap">
          <div className="field-form-items">
            <span className="item-label">Artist name</span>
            <Input.Search
              onSearch={(e) => {
                console.log(e);
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 24,
                  keyword: e,
                });
              }}
              value={text}
              onChange={(e: any) => setText(e.target.value)}
              placeholder="Search artist or campaign name"
              onPressEnter={(e: any) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 24,
                  keyword: e.target?.value,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span className="item-label">Discipline</span>
            <Select
              allowClear
              bordered={false}
              value={params.discipline}
              placeholder="Please select "
              onChange={(e) => {
                history.replace(
                  e
                    ? `/contribute-to-an-artist/${e}`
                    : "/contribute-to-an-artist"
                );
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 24,
                  discipline: e,
                });
              }}
            >
              {options.map((item) => (
                <Select.Option key={item} value={item.toLocaleLowerCase()}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="field-form-items">
            <span className="item-label">MEMBERSHIP STATUS</span>
            <Select
              bordered={false}
              value={params.sponsored}
              placeholder="Please select "
              onChange={(e) => {
                history.replace(
                  e
                    ? `/contribute-to-an-artist/${e}`
                    : "/contribute-to-an-artist"
                );
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 24,
                  sponsored: e,
                });
              }}
            >
              <Select.Option key={"sponsored"} value={1}>
                Accepting Donations
              </Select.Option>
              <Select.Option key={"sponsored"} value={2}>
                Live Campaign
              </Select.Option>
              <Select.Option key={"sponsored"} value={0}>
                All
              </Select.Option>
            </Select>
          </div>
          <span className="clear-all" onClick={handleReset}>
            Clear
          </span>
        </div>
        <Divider />
        <p className="flex result-wrap">
          <span>
            {showResult && (
              <>
                <i>Showing</i> {tableData.length} results
              </>
            )}
          </span>
          <span>
            Sort by :{" "}
            <Select
              bordered={false}
              value={params.relevance}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 24,
                  relevance: e,
                });
              }}
            >
              <Select.Option value="relevance">Relevance</Select.Option>
              <Select.Option value="Alphabetical A-Z">
                Alphabetical A-Z
              </Select.Option>
              <Select.Option value="Alphabetical Z-A">
                Alphabetical Z-A
              </Select.Option>
              <Select.Option value="Most Recently Updated">
                Most Recently Updated
              </Select.Option>
            </Select>
          </span>
        </p>

        <div className="profile-list-wrap">
          {tableData?.map((item: any) =>
            item.type === "crowd" ? (
              <div className="profile-cell crowd-cell">
                <Link to={`/home/donation/crowd/view/${item.id}`}>
                  <img src={item?.avatar || avatar_img} alt="avatar" />
                  <Tag color="green">Live Campaign</Tag>
                  <h3 translate="no">{item?.name}</h3>
                  <span>A Campaign by <NoTranslate style={{display:'contents'}}>{item.displayName}</NoTranslate></span>
                  <p>
                    Campaign ends:{" "}
                    {dayjs(item.tz_date || item.date).format(
                      "MMM DD ,  YYYY | hh:mm A "
                    ) + (item.tz_zone || "EST")}
                    <br />
                    {item?.biography
                      ?.replace(/(<([^>]+)>)/gi, "")
                      .replace(/&nbsp;/g, "")}
                  </p>
                  <span className="link">{"view detail >"}</span>
                </Link>
              </div>
            ) : (
              <div className="profile-cell">
                <Link
                  to={`/profile/${(item?.displayName || "-").replace(
                    /[^\w]/g,
                    "-"
                  )}/${item.id}`}
                >
                  <img src={item?.avatar || avatar_img} alt="avatar" />
                  {item.sponsored && (
                    <Tag color="green">Accepting Donations</Tag>
                  )}
                  <h3 translate="no">{item?.displayName}</h3>
                  <span>
                    {item?.discipline?.join(" • ")?.replace(/Other -/g, "")}
                  </span>
                  <p>
                    {item?.biography
                      ?.replace(/(<([^>]+)>)/gi, "")
                      .replace(/&nbsp;/g, "")}
                  </p>
                  <span className="link">{"view detail >"}</span>
                </Link>
              </div>
            )
          )}
        </div>
        {total > params.page * params.limit && (
          <p className="add-more">
            <Button
              disabled={loading}
              type="primary"
              onClick={() =>
                setParams({
                  ...params,
                  page: params.page + 1,
                })
              }
            >
              Load More
            </Button>
          </p>
        )}
      </ListContainer>
      <SocialLayout />
    </HomeLayout>
  );
};

export default List;
