import Home from "@/pages/homepage/Home";
import Profile from "@/pages/homepage/Profile";
import Donation from "@/pages/homepage/donation";
import Success from "@/pages/homepage/donation/Success";
import CampaignPreview from "@/pages/dashboard/donation/Preview";

import EventPreview from "@/pages/homepage/events/Preview";
import EventList from "@/pages/homepage/events/List";

import GrantPreview from "@/pages/homepage/grants/Preview";
import GrantList from "@/pages/homepage/grants/List";

import ProjectPreview from "@/pages/homepage/project";

import ProfileList from "@/pages/homepage/profile/list";

import InstagramAuth from "../pages/instagram/InstagramAuth";

import Consultation from "@/pages/homepage/checkout/consultation";
import ConsultationSuccess from "@/pages/homepage/checkout/success";

import CheckoutEventPurchase from "@/pages/homepage/checkout/event/purchase";
import CheckoutEventPay from "@/pages/homepage/checkout/event/pay";

import { router_insta_auth } from "@/types/enum";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/",
    component: Home,
    exact: true,
    strict: true,
  },
  {
    path: "/home/profile/:id(\\d+)/:edit(edit)?",
    component: Profile,
    exact: true,
    strict: true,
  },
  {
    path: "/contribute-to-an-artist/:discipline?",
    component: ProfileList,
    exact: true,
    strict: true,
  },
  {
    path: "/profile/:name/:id(\\d+)",
    component: Profile,
    key: "profile-view",
    exact: true,
    strict: true,
  },
  {
    path: "/home/donation/success/:id(\\d+)",
    component: Success,
    exact: true,
    strict: true,
  },
  {
    path:
      "/home/donation/:type(general|crowd|field)/:id(\\d+)/:step(\\d+)?/:payId(\\d+)?",
    component: Donation,
    exact: true,
    strict: true,
  },

  {
    path: "/contribute-to-the-field",
    component: Donation,
    exact: true,
    strict: true,
  },
  {
    path: "/checkout/:type(consultation-artist|misc-fee|misc-fee-nosign|carryover-fee)",
    component: Consultation,
    exact: true,
    strict: true,
  },
  {
    path: "/checkout/success",
    component: ConsultationSuccess,
    exact: true,
    strict: true,
  },
  {
    path:
      "/home/donation/:type(general|crowd)/:preview(preview|view)/:id/:name([\\w\\-]+)?",
    component: CampaignPreview,
    exact: true,
  },
  {
    path: "/explore-artist-community-events",
    component: EventList,
    exact: true,
  },
  {
    path: "/event/:id(\\d+)/:edit(edit|[\\w\\-]+)?",
    component: EventPreview,
    exact: true,
  },
  {
    path: "/checkout/:eventId(\\d+)/purchase",
    component: CheckoutEventPurchase,
    exact: true,
    strict: true,
  },
  {
    path: "/checkout/:orderId(\\d+)/pay",
    component: CheckoutEventPay,
    exact: true,
    strict: true,
  },
  {
    path: "/grants-and-opportunities",
    component: GrantList,
    exact: true,
  },
  {
    path: "/home/grant/:id(\\d+)/:name?",
    component: GrantPreview,
    exact: true,
  },
  {
    path: "/home/project/:id(\\d+)",
    component: ProjectPreview,
    exact: true,
  },

  {
    path: router_insta_auth,
    component: InstagramAuth,
    exact: true,
  },
];
