//created by Peggy on 2021/8/18
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import useConfirm from "@/components/Modal/Confirm";
import SelectAndInput from "@/components/Select/SelectAndInput";
import { getUser, user } from "@/model/user";
import { putArtist } from "@/api/artist-api";
import { useModel } from "use-reaction";
import { localStorageGet } from "@/utils/storage";

const AddRoleButtonContainer = styled.div``;

const AddRoleButton = () => {
  const { doAction } = useModel(user);
  const [form] = Form.useForm();
  const [showModal, setModal] = useState(false);
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        putArtist({ role: "artist", ...values }).then(() => {
          message.success("Create success");
          setModal(false);
          doAction(getUser);
        });
      })
      .catch((e) => {
        console.log("Validate Failed:", e);
        message.error(e.errorFields[0].errors[0]);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
  };
  const { setShow, component } = useConfirm(
    <div className="flex column start align-start">
      <p>
        You are adding an artist account with free membership which unlocks a
        wealth of fundraising and marketing opportunities, help guides, and
        other resources vetted by and for artists.
      </p>
    </div>,
    {
      title: "You are adding a free artist account",
      className: "suspend-confirm-modal",
      okText: "Confirm",
      onOk: (id?: any) => {
        setModal(true);
        const { pronouns, tags, companyName } = localStorageGet(
          "info"
        );
        form.setFieldsValue({
          pronouns,
          tags: tags || [],
          companyName,
        });
      },
    }
  );
  useEffect(() => { });
  return (
    <AddRoleButtonContainer className="AddRoleButton-container">
      <Button
        className="role-to-artist-btn"
        type="primary"
        onClick={() => setShow(true)}
      >
        Create an artist account
      </Button>
      <Modal
        className="common-modal"
        title="We’d love to learn more about you"
        visible={showModal}
        onOk={handleSubmit}
        okText="save"
        cancelText="discard changes"
        onCancel={handleCancel}
        centered
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            label="Company name"
            name="companyName"
            rules={[
              {
                message: "Company name",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={100}
              placeholder="Enter company name"
            />
          </Form.Item>
          <Form.Item
            label="Pronouns"
            name="pronouns"
            rules={[
              {
                message: "pronouns",
              },
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Enter pronouns"
              maxLength={100}
            />
          </Form.Item>
          <Form.Item
            name="tags"
            label={
              <div>
                Disciplines
                <Tooltip
                  className="tooltip-field-item"
                  title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: true,
                type: "array",
                message: "Please select your disciplines",
              },
            ]}
          >
            <SelectAndInput />
          </Form.Item>
        </Form>
      </Modal>
      {component}
    </AddRoleButtonContainer>
  );
};

export default AddRoleButton;
