import React, { useState } from "react";
import { Form, Input, Button, message, Divider } from "antd";
import { useParams } from "react-router-dom";
import { localStorageGet } from "@/utils/storage";

interface Props {
  onNext?: (value: any) => void;
  info?: any;
}
const BaseInfo = ({ onNext, info = {} }: Props) => {
  const { type } = useParams() as any;

  const [form] = Form.useForm();
  const [state] = useState(info);

  const onFinish = (values: any) => {
    console.log("Success:", values);
    onNext && onNext(values);
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  return (
    <div className="base-container sign-form">
      <Form
        layout="vertical"
        size="large"
        name="base"
        form={form}
        initialValues={{ ...state }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {localStorageGet("source") === "facebook" && (
          <Form.Item
            label="email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                whitespace: true,
                message: "Enter email",
              },
            ]}
          >
            <Input autoComplete="off" placeholder="Enter email" />
          </Form.Item>
        )}

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter first Name",
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={30}
            placeholder="Enter first name"
          />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter last name",
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={30}
            placeholder="Enter last name"
          />
        </Form.Item>
        <Form.Item
          label="Phone number"
          name="phone"
          normalize={(e) => {
            return e.replace(/[^\d]*/g, "").replace(/(\d{3})(?=\d{2})/g, "$1-");
          }}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Enter phone number",
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={12}
            placeholder="Enter phone number"
          />
        </Form.Item>
        {type === "artist" && (
          <Form.Item
            label="Zip code"
            name="zipCode"
            normalize={(e) => {
              return e.replace(/[^\d]*/g, "");
            }}
            rules={[
              {
                required: false,
                whitespace: true,
                message: "Zip code",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={5}
              placeholder="Enter zip code"
            />
          </Form.Item>
        )}

        <Form.Item
          label="Company (Optional)"
          name="companyName"
          rules={[
            {
              whitespace: false,
              message: "Company (Optional)",
            },
          ]}
        >
          <Input
            autoComplete="off"
            maxLength={30}
            placeholder="Enter company name"
          />
        </Form.Item>
        {type === "artist" && (
          <Form.Item
            label="Title (Optional)"
            name="title"
            rules={[
              {
                whitespace: false,
                message: "Title (Optional)",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter title"
            />
          </Form.Item>
        )}
      </Form>
      <Divider />
      <p style={{ textAlign: "right" }}>
        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </p>
    </div>
  );
};

export default BaseInfo;
