//created by Peggy on 2021/3/29
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Divider, Input, Select, Button, Spin } from "antd";
import DatePicker from "@/components/DatePicker";
import { ClockCircleOutlined, EnvironmentOutlined } from "@ant-design/icons";

import HomeLayout from "@/components/layouts/HomeLayout";
import { disciplineOptions as options } from "@/types/enum";
import avatar_img from "@/images/avatar_default.jpg";

import { eventListSearch } from "@/api/event-api";

import dayjs, { Dayjs } from "dayjs";

import styled from "styled-components";
import SocialLayout from "@/components/layouts/SocialLayout";
import { AppContainerStyle } from "@/styles/util";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";
const ListContainer = styled.div``;
const limit = 10;
const List = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState<any>({
    keyword: "",
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const getList = (page = 1) => {
    setLoading(true);
    const { date, created, skipReq, ...req } = params;
    if (date?.length) {
      const [fromDate, toDate] = date.map((e: Dayjs) => e.format("YYYY-MM-DD"));
      req.fromDate = fromDate;
      req.toDate = toDate;
    }
    if (created?.length) {
      const [createdFromDate, createdToDate] = created.map((e: Dayjs) =>
        e.format("YYYY-MM-DD")
      );
      req.createdFromDate = createdFromDate;
      req.createdToDate = createdToDate;
    }
    req.limit = limit;
    req.page = page;
    eventListSearch(req)
      .then((data: any) => {
        setTableData(page > 1 ? tableData.concat(data.items) : data.items);
        setHasMore(data.meta.totalPages > data.meta.currentPage);
        setLoading(false);
        data.items.length && setPage(page);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    if (!params.skipReq) {
      getList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <HomeLayout>
      <AppContainerStyle/>
      <ListContainer className="home-event-list-container common-container">
        <h1>Discover Events</h1>
        <p>
          Explore upcoming events from our artists from all across the
          countries.
        </p>
        <div className="flex search-wrap">
          <div className="field-form-items">
            <span>keyword</span>
            <Input.Search
              onSearch={(e) => {
                getList();
              }}
              placeholder="Enter event keyword"
              value={params.keyword}
              onChange={(e) =>
                setParams({
                  ...params,
                  keyword: e.target?.value,
                  skipReq: true,
                })
              }
              onPressEnter={(e: any) => {
                getList();
              }}
            />
          </div>
          <div className=" field-form-items">
            <span>location</span>
            <Input
              bordered={false}
              value={params.location}
              onChange={(e) =>
                setParams({
                  ...params,
                  location: e.target?.value,
                  skipReq: true,
                })
              }
              placeholder="Please select "
              onPressEnter={(e: any) => {
                getList();
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Discipline</span>
            <Select
              allowClear
              bordered={false}
              value={params.discipline}
              placeholder="Please select "
              onChange={(e) => {
                setParams({
                  ...params,
                  discipline: e,
                });
              }}
            >
              {options.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              inputReadOnly
              dropdownClassName="date-picker-mobile"
              value={params.date}
              onChange={(e) => {
                setParams({
                  ...params,
                  date: e,
                });
              }}
            />
          </div>
          <div className="range-picker-wrap field-form-items">
            <span>created</span>
            <DatePicker.RangePicker
              inputReadOnly
              dropdownClassName="date-picker-mobile"
              value={params.created}
              onChange={(e) => {
                setParams({
                  ...params,
                  created: e,
                });
              }}
            />
          </div>
          <div className="field-form-items clear">
            <div
              onClick={(e) => {
                setParams({
                  keyword: "",
                  skipReq: false,
                });
              }}
            >
              CLEAR
            </div>
          </div>
        </div>
        <Divider />
        <p className="flex result-wrap">
          <span>
            <i>Showing</i> {tableData.length} results
          </span>
          <span>
            Sort by :{" "}
            <Select
              defaultValue=""
              bordered={false}
              onChange={(e) => {
                setParams({
                  ...params,
                  category: e,
                });
              }}
              dropdownMatchSelectWidth={false}
            >
              <Select.Option value="">All</Select.Option>
              <Select.Option value="artist">Artists events</Select.Option>
              <Select.Option value="the_field">
                <TheField/>’s events
              </Select.Option>
            </Select>
          </span>
        </p>
        <div className="event-list-wrap">
          {tableData.map((item) => (
            <div className="event-list-cell" key={item.id}>
              <img src={item.thumbnail} alt="" />
              <div className="flex right-item">
                <div className="flex column align-start">
                  <h2>{item.name}</h2>
                  <div className="icon-block-wrap">
                    <p className="img_name">
                      <img alt="" src={item?.artist?.avatar || avatar_img} />
                      <NoTranslate>{item?.artist?.displayName ||
                          item?.artist?.fullName ||
                          item?.presenter}</NoTranslate>
                    </p>
                    <p>
                      <ClockCircleOutlined />{" "}
                      <span>
                        {item?.time?.startTime
                          ? dayjs(item?.time?.startTime).format("h:mm A")
                          : "N/A"}{" "}
                        {item?.time?.endTime
                          ? `- ${dayjs(item?.time?.endTime).format("h:mm A")}`
                          : ""}{" "}
                        {item?.time?.timezone ?? ""}・
                        {dayjs(item?.time?.startDate).format(
                          "dddd, MMMM D, YYYY"
                        )}
                        {item?.time?.frequency === "recurring" &&
                          item?.nextStartDate &&
                          `・Next start date is ${dayjs(
                            item?.nextStartDate
                          ).format("MM/DD/YYYY")}`}
                      </span>
                    </p>
                    <p>
                      <EnvironmentOutlined />
                      <span
                        className="icon-block-wrap-address"
                        title={
                          [
                            item?.location?.address,
                            item?.location?.city,
                            item?.location?.state,
                            item?.location?.zipCode,
                          ]
                            .filter((item) => item)
                            .join(", ") || item?.location?.url
                        }
                      >
                        {[
                          item?.location?.address,
                          item?.location?.city,
                          item?.location?.state,
                          item?.location?.zipCode,
                        ]
                          .filter((item) => item)
                          .join(", ") || item?.location?.url}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="btn-item flex column">
                  {/*<Button*/}
                  {/*  type="primary"*/}
                  {/*  onClick={() => window.open(item.button.url)}*/}
                  {/*>*/}
                  {/*  {item.button.label}*/}
                  {/*</Button>*/}
                  <Button
                    onClick={() =>
                      history.push(
                        `/event/${item.id}/${item.name.replace(/[^\w]/g, "-")}`
                      )
                    }
                  >
                    view detail
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className="loading-wrap">
          {loading && <Spin /> ? (
            ""
          ) : (
            <Button
              type="primary"
              disabled={!hasMore}
              onClick={() => getList(page + 1)}
            >
              load more
            </Button>
          )}
        </p>
      </ListContainer>
      <SocialLayout />
    </HomeLayout>
  );
};

export default List;
