//created by Peggy on 2021/2/10
import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { Button, Dropdown, Menu, message, Radio, Space } from "antd";
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

import { eventGet, eventPut, reportEvent } from "@/api/event-api";
import { addFavorite } from "@/api/favorite-api";
import HeaderLayout from "@/components/layouts/HeaderLayout";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import styled from "styled-components";

import "@/styles/home/preview.less";
import Modal from "antd/lib/modal/Modal";

import { getUserInfo } from "@/api/sign-api";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import { view } from "@/model/view";

import avatar_img from "@/images/avatar.svg";

import { InfoModal } from "@/pages/dashboard/events/List";
import NoteModal from "@/components/Modal/Info";
import Caption from "@/components/Image/caption";
import { capitalize } from "lodash";
import icon_grant from "@/images/icons/icon_grant.svg";
import dayjs from "dayjs";
import { AppContainerStyle } from "@/styles/util";
import { NoTranslate } from "@/components/GoogleTranslate";

const PreviewContainer = styled.div``;

const Preview = () => {
  const { edit, id } = useParams() as any;
  const history = useHistory();

  const {
    store: { token },
  } = useModel(user);
  const [flag, setFlag] = useState(0);

  const location = useLocation();
  const [showlogin, setShowlogin] = useState(0);
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState<any>({});
  const [reportReason, setReportReason] = useState("spam");
  const [showReport, setShowReport] = useState(false);
  const {
    store: { isMobile },
  } = useModel(view);
  const handleSave = (type: string) => {
    let _data = {
      status: type,
    };
    eventPut(info.id, _data).then((data) => {
      type === "live" ? setShow(true) : history.push(`/dashboard/event`);
    }).catch(() => {
      history.push(`/dashboard/event/create/${id}?checkField=true`);
    });
  };

  const handleEventProgram = () => {
    if (edit === "preview") {
      return;
    }
    if (
      !token &&
      showlogin === 0 &&
      !(info?.eligibleUserRole || []).includes("guest")
    ) {
      setShowlogin(1);
      return;
    }
    const _num =
      info?.pricing?.remaining ||
      info?.pricing?.customTierPricing?.reduce(
        (sum = 0, item: any) => sum + item.remaining,
        0
      );
    if (!_num) {
      message.error("The tickets to this event are sold out");
      return false;
    }
    if (flag === 1) {
      message.warning(
        "You are not eligible to register for this event, please sign in first"
      );
    } else if (flag === 2) {
      message.warning(
        "You are not eligible to register for this event, please contact support@thefield.org for further assistance."
      );
    } else if (
      info?.openDate?.startDate &&
      dayjs(info.openDate.startDate).isAfter(dayjs())
    ) {
      message.warning(
        `The Open Register date is ${dayjs(info.openDate.startDate).format(
          "MMM, DD, YYYY"
        )}, please try again later.`
      );
    } else if (info?.status === "closed") {
      message.warning("This event is already closed");
    } else if (info?.status === "expired") {
      message.warning("This event is already expired");
    } else {
      history.push(`/checkout/${id}/purchase`);
    }
  };

  useEffect(() => {
    eventGet(id).then(async (data) => {
      setInfo(data);
      if (!token) {
        if (!data?.eligibleUserRole?.includes("guest")) {
          setFlag(1);
        }
      } else {
        let { roles, sponsorshipValid } = await getUserInfo();
        if (roles?.includes("donor") && !roles.includes("artist")) {
          if (!data?.eligibleUserRole?.includes("donor")) {
            setFlag(2);
          }
        } else if (roles?.includes("artist") && !sponsorshipValid) {
          if (!data?.eligibleUserRole?.includes("free_artist")) {
            setFlag(2);
          }
        } else if (roles?.includes("artist") && sponsorshipValid) {
          if (!data?.eligibleUserRole?.includes("sponsored_artist")) {
            setFlag(2);
          }
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <PreviewContainer className="event-preview-container common-container">
      {token && edit === "edit" && info?.status === "draft" && (
        <>
          <div className="preview-btn flex">
            <span>{isMobile ? "preview" : "Event live site preview"}</span>
            <div className={`btn-item ${isMobile ? "flex" : ""}`}>
              <Button
                onClick={() => history.push(`/dashboard/event/create/${id}`)}
                type={isMobile ? "text" : "default"}
              >
                {isMobile ? "edit" : " Back to edit"}
              </Button>
              <Button
                type={isMobile ? "text" : "default"}
                onClick={() => handleSave("draft")}
              >
                {isMobile ? "save" : "Save as draft"}
              </Button>
              <Button type="primary" onClick={() => handleSave("live")}>
                Publish
              </Button>
            </div>
          </div>
          <InfoModal
            {...{
              current: {
                id: info.id,
                name: info.name,
              },
              history,
              show,
              setShow: () => history.push(`/dashboard/event`),
            }}
          />
        </>
      )}
      <AppContainerStyle/>
      <HeaderLayout />
      <div
        className="event-header"
        style={{
          backgroundImage: `url(${info?.banner || info?.thumbnail})`,
        }}
      >
        <div className="header-content">
          <h4>Upcoming event</h4>
          <h1>
            {info?.name}
            {edit !== "edit" && (
              <Dropdown
                className="filter-item"
                trigger={["click"]}
                placement="bottomRight"
                overlay={() => (
                  <Menu>
                    <>
                      <Menu.Item
                        onClick={async () => {
                          await addFavorite(info.id, "event");
                          message.success("Saved to favorite successfully!");
                        }}
                      >
                        Save as favorite
                      </Menu.Item>
                      <Menu.Item onClick={() => setShowReport(true)}>
                        Report this event
                      </Menu.Item>
                    </>
                  </Menu>
                )}
              >
                <span className="ant-dropdown-link">
                  <FilterMore />
                </span>
              </Dropdown>
            )}
          </h1>
          {(info.category === "artist" && (
            <p
              className="event-content-user"
              onClick={() =>
                history.push(
                  `/profile/${(
                    info?.artist?.fullName ||
                    info?.presenter ||
                    "-"
                  ).replace(/[^\w]/g, "-")}/${info?.artist?.id}`
                )
              }
              translate="no"
            >
              <img src={info?.artist?.avatar || avatar_img} alt="avatar" />{" "}
              {info?.presenter ||
                info?.artist?.displayName ||
                info?.artist?.fullName}
            </p>
          )) || <p className="event-content-user" translate="no">{info?.presenter}</p>}
          <div className="icon-block-wrap">
            <p>
              <ClockCircleOutlined />{" "}
              <span>
                {info?.time?.startTime
                  ? dayjs(info?.time?.startTime).format("h:mm A")
                  : "N/A"}{" "}
                {info?.time?.endTime
                  ? `- ${dayjs(info?.time?.endTime).format("h:mm A")}`
                  : ""}{" "}
                {info?.time?.timezone ?? "N/A"}・
                {info?.time?.frequency === "recurring" &&
                  info?.time?.repeatEvery &&
                  info?.time?.repeatOn &&
                  `Recurring every ${info?.time?.repeatEvery} on ${info?.time?.repeatOn} ・`}
                {info?.time?.startDate
                  ? dayjs(info?.time?.startDate).format("MM/DD/YYYY")
                  : "N/A"}{" "}
                {info?.time?.endDate &&
                info?.time?.startDate &&
                !dayjs(info?.time?.startDate).isSame(
                  dayjs(info?.time?.endDate),
                  "date"
                )
                  ? `- ${dayjs(info?.time?.endDate).format("MM/DD/YYYY")}`
                  : ""}
                {info?.time?.frequency === "recurring" &&
                  info?.nextStartDate &&
                  `・ Next start date is ${dayjs(info?.nextStartDate).format(
                    "MM/DD/YYYY"
                  )}`}
              </span>
            </p>
            <p>
              <EnvironmentOutlined />
              <span>
                {info?.location?.name || info?.location?.address
                  ? `${info.location.name ? info.location.name + " - " : ""}${
                      [info.location.address, info.location.city]
                        .filter(Boolean)
                        .join(" ") + ","
                    } ${[info.location.state, info.location.zipCode]
                      .filter(Boolean)
                      .join(" ")}`
                  : info?.location?.url || "N/A"}
              </span>
            </p>
            {(info.category === "artist" && (
              <p>
                <DollarCircleOutlined />
                <span> {info?.price ?? "N/A"}</span>
              </p>
            )) || (
              <>
                <p>
                  <DollarCircleOutlined />
                  <span translate="no">
                    {" "}
                    {(info?.pricing?.showDisplayPrice &&
                      info?.pricing?.displayPrice) ||
                      info?.pricing?.asYouWishPricing?.minimum ||
                      info?.pricing?.standardPricing?.price ||
                      info?.pricing?.roleTierPricing?.map(
                        (item: any, index: number) => (
                          <i key={index}>
                            {item.role
                              ?.split("_")
                              ?.map((_: string) => capitalize(_))
                              .join(" ") +
                              " : $" +
                              item.price}{" "}
                            <br />
                          </i>
                        )
                      ) ||
                      info?.pricing?.customTierPricing?.map(
                        (item: any, index: number) => (
                          <i key={index}>
                            {item.name + " : $" + item.price} <br />
                          </i>
                        )
                      ) ||
                      (info?.pricing?.model === "free" && "Free") ||
                      "N/A"}
                  </span>
                </p>
                <p>
                  <FileTextOutlined />
                  <span>
                    {" "}
                    <b>Accessibility Notice</b> :{" "}
                    {info?.accessibilityNotice ?? "N/A"}
                  </span>
                </p>
              </>
            )}
          </div>
          {((info.category === "artist" ||
            info.type === "Third Party Event") && (
            <Button onClick={() => window.open(info?.button?.url, "_blank")}>
              {info?.button?.label}
            </Button>
          )) || (
            <div className="flex start">
              <Button onClick={handleEventProgram}>
                {info?.button?.label}
              </Button>{" "}
              {info?.pricing?.showTicketInventory &&
                info?.pricing?.inventory > -1 && (
                  <span>
                    {info?.pricing?.inventory > -1
                      ? info?.pricing?.remaining > 0
                        ? info?.pricing?.remaining
                        : 0
                      : info?.pricing?.customTierPricing.reduce(
                          (sum = 0, item: any) => sum + item.remaining,
                          0
                        )}{" "}
                    tickets left
                  </span>
                )}
            </div>
          )}
        </div>
        <Caption hideImage src={info?.thumbnail} />
      </div>
      <div className="event-content">
        <p
          dangerouslySetInnerHTML={{
            __html:
              info?.description && info.description.startsWith("<")
                ? info.description
                : `<p>${info?.description || ""}</p>`,
          }}
        ></p>
        {[
          info?.contactInformation?.name,
          info?.contactInformation?.email,
          info?.contactInformation?.phone,
        ].filter(Boolean).length > 0 && (
          <p className="contact-info">
            <div className="info-btn-receive">
              {((info.category === "artist" ||
                info.type === "Third Party Event") && (
                <Button
                  className="ant-btn-white"
                  onClick={() => window.open(info?.button?.url, "_blank")}
                >
                  {info?.button?.label}
                </Button>
              )) || (
                <Button className="ant-btn-white" onClick={handleEventProgram}>
                  {info?.button?.label}
                </Button>
              )}
            </div>
            <h3>Contact information</h3>
            <div className="info-item">{info.contactInformation.name}</div>
            {info.contactInformation?.email
              ?.split(",")
              ?.map((e: string, idx: number) => (
                <div key={idx} className="info-item">
                  {e}
                </div>
              ))}
            <div className="info-item">{info.contactInformation.phone}</div>
          </p>
        )}
      </div>
      <div className="event-photo-list" hidden={info?.category === "the_field"}>
        {info?.photos?.map((item: any) => (
          <Caption src={item} key={item} />
        ))}
      </div>
      {/* {info?.documents && <div className="sub-infos">
        <h3 className="sub-title">Documents</h3>
        <div className="documents">
          {info?.documents?.map((d: string, idx: number) => (
            <div className="flex start doc">
              <img src={fileIcon} alt="file" />
              <a key={'d-url-' + idx} href={d}>
                {d.split("/").pop()}
              </a>
            </div>
          ))}
        </div>
      </div>} */}
      <Modal
        visible={showReport}
        onCancel={(e) => setShowReport(false)}
        onOk={(e) => {
          setShowReport(false);
          reportEvent(info.id, reportReason).then((res) => {
            res?.success && message.success("report success!");
          });
        }}
        maskClosable
        className="modal-report"
        cancelText="CANCEL"
        okText="REPORT"
        title={<h1>Report Event</h1>}
      >
        <Radio.Group
          onChange={(e) => setReportReason(e.target.value)}
          value={reportReason}
        >
          <Space direction="vertical">
            <Radio key="spam" value="spam">
              Spam
            </Radio>
            <Radio key="inappropriate" value="inappropriate">
              Inappropriate
            </Radio>
            <Radio key="legal" value="legal">
              Copyright or other legal complaints
            </Radio>
          </Space>
        </Radio.Group>
      </Modal>
      <NoteModal
        visible={showlogin > 0}
        width={420}
        footer={[
          // <Button key="back" onClick={() => setShowlogin(-1)}>
          <Button key="back" onClick={() => window.location.reload()}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push(`/sign/in?to=${location.pathname}`)}
          >
            SIGN IN
          </Button>,
        ]}
        img={icon_grant}
      >
        <h2>Sign in to register </h2>
        <p>
          Please log in to register for this event. Pricing and availbility may
          vary based on your membership plan.
        </p>
      </NoteModal>
    </PreviewContainer>
  );
};

export default Preview;
