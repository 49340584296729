//created by Peggy on 2021/3/19
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Breadcrumb, Divider } from "antd";

import { getInvoiceDetail } from "@/api/merbership-api";

import "@/styles/dashboard/mebership.less";
import styled from "styled-components";
const OverviewDetailContainer = styled.div``;

const OverviewDetail = () => {
  const { id } = useParams() as any;
  const [info, setInfo] = useState<any>();
  const getDetail = () => {
    getInvoiceDetail(id).then((data) => setInfo(data));
  };
  useEffect(() => {
    getDetail();
    // eslint-disable-next-line
  }, []);
  return (
    <OverviewDetailContainer className="overview-detail-membership-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/membership/overview">
          Overview
        </Breadcrumb.Item>
        <Breadcrumb.Item>Invoice detail</Breadcrumb.Item>
      </Breadcrumb>
      <h1>Invoice {info?.summary?.serialNumber}</h1>
      <div className="flex">
        <div className="card-wrap">
          <h2>Issued to</h2>
          <p className="flex">
            Name
            <span translate="no">{info?.issuedTo?.name}</span>
          </p>
          <p className="flex">
            Email address
            <span translate="no">{info?.issuedTo?.email}</span>
          </p>
          <p className="flex">
            Billing address
            <span translate="no">{info?.issuedTo?.billingAddress}</span>
          </p>
        </div>
        <div className="card-wrap">
          <h2>Issued By</h2>
          <p className="flex">
            Name
            <span translate="no">{info?.issuedBy?.name}</span>
          </p>
          <p className="flex">
            Email address
            <span translate="no">{info?.issuedBy?.email}</span>
          </p>
          <p className="flex">
            Billing address
            <span translate="no">{info?.issuedBy?.billingAddress}</span>
          </p>
        </div>
      </div>
      <div className="card-wrap">
        <h2>Invoice summary </h2>
        <p className="flex">
          Product
          <span>{info?.summary?.product}</span>
        </p>
        <p className="flex">
          Invoice number
          <span>{info?.summary?.serialNumber}</span>
        </p>
        <p className="flex">
          Payment method
          <span>{info?.summary?.paymentMethod}</span>
        </p>
        <p className="flex">
          Subscription period
          <span>{info?.summary?.subscriptionPeriod}</span>
        </p>
        <p className="flex">
          Date
          <span>{info?.summary?.date}</span>
        </p>
        <p className="flex">
          Price
          <span>
            {Number(info?.summary?.amount ?? 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </p>
        <p className="flex">
          Discount
          <span>
            {Number(info?.summary?.discount ?? 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </p>
        <p className="flex">
          Coupon
          <span>
            {info?.summary?.coupon || 'N/A'}
          </span>
        </p>
        <p className="flex">
          Tax
          <span>
            {Number(info?.summary?.tax ?? 0).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </p>
        <Divider />
        <h3 className="flex total-item">
          Total amount
          <span>
            {Number(
              Number(info?.summary?.amount ?? 0) -
                Number(info?.summary?.discount ?? 0)
            ).toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </h3>
      </div>
    </OverviewDetailContainer>
  );
};

export default OverviewDetail;
