import { useState, useRef, useEffect } from "react";

const ReadMore = ({ text = "", maxHeight = 100 }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [needReadMore, setNeedReadMore] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      if (textRef.current?.scrollHeight > maxHeight) {
        setNeedReadMore(true);
      } else {
        setNeedReadMore(false);
      }
    }
  }, [maxHeight, text]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div>
      <div
        ref={textRef}
        style={{
          maxHeight: isReadMore ? `${maxHeight}px` : "none",
          overflow: "hidden",
        }}
      >
        {text}
      </div>
      {needReadMore && (
        <span
          onClick={toggleReadMore}
          className="block text-[16px] font-bold text-[#4A4A4A] underline mt-1"
        >
          {isReadMore ? "Read more" : " Show less"}
        </span>
      )}
    </div>
  );
};

export default ReadMore;
