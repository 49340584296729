import { useReactive } from "ahooks";
import { createContext, useContext } from "react";
import { Consultation } from "./typings";

export type BookingContextValue = ReturnType<typeof useCreateBookingContext>;

export const BookingContext = createContext<BookingContextValue>(
  {} as BookingContextValue
);

export const useCreateBookingContext = () => {
  const state = useReactive({
    step: 0,
    topic: "",
    description: "",
    bookingUrl: "",
    eventTypeUri: "",
    staff: {} as any,
    consultation: {} as Consultation,
  });
  return {
    state,
  };
};

export const useBookingContext = () => {
  const context = useContext(BookingContext);
  return context;
};
