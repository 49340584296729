//created by Peggy on 2021/1/29
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Steps, Button, Tag, Divider, Dropdown, Menu, Input, message } from 'antd'
import { CheckOutlined, RightOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { getSponsor, finishSignature } from '@/api/merbership-api'

import InvoiceTable from '@/pages/dashboard/donation/InkindTable'
import dayjs from 'dayjs'
import { ReactComponent as FilterMore } from '@/images/filter-more.svg'
import useConfirm from '@/components/Modal/Confirm'
import { downloadDocument, getDocument } from '@/api/document-api'
import { TheField } from '@/components/GoogleTranslate'

const SponsorshipContainer = styled.div``

enum StepType {
  'no_apply',
  'in_review',
  'approved',
  expired = 2,
  'paid',
  'completed',
  'wait_for_signature' = 4,
  'rejected',
  'signed',
  'applying',
  deleted = 0
}

const Sponsorship = () => {
  const history = useHistory()

  const [current, setCurrent] = useState(Number(sessionStorage.getItem('sponsorship') || 0))
  const [status, setStatus] = useState('')
  const [allowSubsidized, setAllowSubsidized] = useState(false)
  const [applySubsidized, setApplySubsidized] = useState(false)

  const timeout = useRef<any>(null)

  const onCancel = () => {
    history.push('/dashboard/membership/payable')
    // cancelSubscription().then((data) => getStatus());
  }

  const getStatus = () => {
    let _data = {}
    getSponsor(_data).then((data: any) => {
      setStatus(data?.status)
      setAllowSubsidized(data?.allowSubsidized || false)
      setApplySubsidized(data?.artistInformation?.applySubsidized || false)
      setCurrent(StepType[data?.status] as any)
      data.status === 'wait_for_signature' && loopStatus()
    })
  }

  const loopStatus = () => {
    finishSignature({}).then((data) => {
      if (data.success) {
        timeout?.current && clearTimeout(timeout?.current)
        setStatus('completed')
      } else {
        timeout.current = setTimeout(loopStatus, 8000)
      }
    })
  }

  useEffect(() => {
    getStatus()
    return () => timeout?.current && clearTimeout(timeout?.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //table
  const columns: any[] = [
    {
      title: 'document name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      className: 'doc-name',
      ellipsis: true,
      render: (name: string) => decodeURI(name)
    },
    {
      title: 'document type',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true
    },

    {
      title: 'date added',
      dataIndex: 'createDate',
      render: (text: string, row: any) => dayjs(text).format('MM/DD/YYYY')
    },
    {
      title: '',
      width: 80,
      align: 'center',
      key: 'action',
      render: (_: string, row: any) => (
        <Dropdown
          trigger={['hover']}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() => {
                    if (row.type === 'Sponsorship Agreement') {
                      showDownload(row.id)
                    } else {
                      window.open(row.url)
                    }
                  }}
                >
                  Download
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      )
    }
  ]

  //download need password
  const [password, setPassword] = useState('')
  const [wait, setWait] = useState(false)
  const { setShow: showDownload, component } = useConfirm(
    <div className="flex column start align-start">
      <p>Please input your log-in password in order to download this document</p>
      <Input.Password
        size="large"
        autoComplete="off"
        value={password}
        onChange={(e) => setPassword(e?.target.value)}
        placeholder="Please input your password"
      />
    </div>,
    {
      title: 'Download contract',
      className: 'suspend-confirm-modal',
      okText: 'Download',
      width: 420,
      onOk: (id?: any) => {
        if (!password) {
          message.error('please input your password')
          return false
        }
        if (wait) {
          return false
        } else {
          setWait(true)
        }
        downloadDocument({
          id,
          password
        })
          .then((data) => {
            showDownload(false)
            setWait(false)
            setPassword('')
            window.open(data.url)
          })
          .catch((e) => {
            setPassword('')
            setWait(false)
          })
      }
    }
  )

  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 20
  })
  const [total, setTotal] = useState(0)
  const [tableData, setTableData] = useState([])

  const getList = () => {
    const { pageSize: limit, ...others } = params
    let _data = {
      ...others,
      limit,
      type: 'Sponsorship Agreement'
    }
    getDocument(_data).then((data) => {
      setTableData(data.items)
      setTotal(data.total)
    })
  }
  useEffect(() => {
    current === 4 && getList()
    // eslint-disable-next-line
  }, [params, current, status])

  const { setShow, component: passwordComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>You only need to update your contract if there are changes to your: </p>
      <ul style={{ listStyleType: 'disc' }}>
        <li>
          <b>Legal Name</b>
        </li>
        <li>
          <b>Tax ID (Social Security Number or Employer Identification Number)</b>
        </li>
        <li>
          <b>Mailing Address</b>
        </li>
      </ul>
      <p>
          Clicking on UPDATE CONTRACT will open a page that you can populate your contract information.
      </p>
      <p style={{ fontStyle: 'italic' }}>
          If you would like to change your display name, please use the Artist Profile section.
      </p>
    </div>,
    {
      title: 'Update Contract Information',
      className: 'suspend-confirm-modal',
      okText: 'update CONTRACT ',
      onOk: (orderId?: any) => {
        history.push('/dashboard/membership/fiscal/agreement/resign')
      }
    }
  )

  return (
    <SponsorshipContainer className="sponsor-ship-container common-container">
      <h1>Fiscal sponsorship</h1>
      <h4>
        Fund your artistic projects with tax-deductible donations and grants – and leave the
        paperwork to us.
      </h4>
      <Steps current={current}>
        <Steps.Step
          // title={isMobile ? "Application" : "Fiscal sponsorship application"}
          title="Application"
          description=""
        />
        <Steps.Step title="Review" description="" />
        <Steps.Step title="Payment" description="" />
        <Steps.Step title="Agreement" description="" />
        {/*<Steps.Step title="Completed" description="" />*/}
      </Steps>
      <div className="sponsorship-wrap">
        <div className="flex wrap">
          <h2 className="flex start">
            Fiscal sponsorship application
            {current === 1 ? (
              <Tag color="orange">Pending review</Tag>
            ) : current === 2 && status !== 'expired' ? (
              <Tag color="green">Approved</Tag>
            ) : current === 2 && status === 'expired' ? (
              <Tag color="red">Expired</Tag>
            ) : current === 3 ? (
              <Tag color="blue">Payment completed</Tag>
            ) : current === 4 && status === 'completed' ? (
              <Tag color="green">Completed</Tag>
            ) : current === 4 && status === 'wait_for_signature' ? (
              <Tag color="blue">Waiting for signature</Tag>
            ) : null}
            {current === 1
              ? applySubsidized && <Tag color="orange">Social Justice Pending</Tag>
              : current >= 1
              ? allowSubsidized && <Tag color="default">Social Justice Program</Tag>
              : null}
          </h2>
          <span className="btn-span">
            {current === 0 ? (
              <Button
                type="primary"
                onClick={() => {
                  history.push('/dashboard/membership/fiscal/application')
                }}
              >
                start application now
              </Button>
            ) : current === 2 ? (
              <Button
                type="primary"
                onClick={() => {
                  history.push('/dashboard/membership/fiscal/summary')
                }}
              >
                Proceed to payment
              </Button>
            ) : (current === 3 || status === 'wait_for_signature')? (
              <Button
                type="primary"
                onClick={() => {
                  history.push('/dashboard/membership/fiscal/agreement')
                }}
              >
                Sign agreement
              </Button>
            ) : (current === 4 && status !== 'wait_for_signature') ? (
              <Button type="primary" onClick={onCancel}>
                SET UP PAYABLE
              </Button>
            ) : null}
          </span>
        </div>
        <div className="flex align-start sponsorship-content">
          <div className="flex column start align-start">
            <h4>As a Fiscally Sponsored Artist you get</h4>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>
                Eligibility for grants, crowdfunding, selling tickets, corporate matching gifts, and
                other funding opportunities.
              </span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Tax-deductible donations of money and goods.</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Free review and feedback on grant applications and fundraising materials.</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>All the basic Member benefits of <TheField/>.</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Discounted consultations with Artist Services staff</span>
            </p>
          </div>
          <div className="flex column start align-start">
            <h4>Here's how to apply:</h4>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>
                Complete this application form while signed into your account. Please allow up to
                five business days for us to confirm your eligibility.
              </span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>
                Once you are approved, you will receive an email with instructions to log into your
                account and pay the $110 annual fee (saved $34) or $12 monthly fee.
              </span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Fill out your contract information and sign it.</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>Become fiscally sponsored and start raising money.</span>
            </p>
            <p className="flex start align-start">
              <CheckOutlined />
              <span>
                Applying for our Subsidized Fiscal Sponsorship for Social Justice Practitioners
                program? Fill out this application and additional questions
              </span>
            </p>
          </div>
        </div>

        {current >= 1 && (
          <div
            className="flex align-start sponsorship-content view-application"
            onClick={() => {
              history.push('/dashboard/membership/review/fiscal/SponsorshipReview')
            }}
          >
            view Application
            <RightOutlined />
          </div>
        )}
      </div>

      {current === 4 && status === 'completed' && (
        <>
          <Divider />
          <div className="table-wrap">
            <div className="table-item">
              <InvoiceTable
                title="Sponsorship Agreements"
                tableData={tableData}
                columns={columns}
                onSearch={(e) =>
                  setParams({
                    ...params,
                    keyword: e
                  })
                }
                pagination={{
                  total,
                  current: params.page,
                  pageSize: params.pageSize,
                  onChange: (page: number, pageSize: number) => {
                    setParams({
                      ...params,
                      ...{
                        page,
                        pageSize
                      }
                    })
                  }
                }}
              />
            </div>
          </div>
          <p style={{ width: '100%', textAlign: 'right', marginTop: '20px' }}>
            <Button type="primary" onClick={() => setShow(true)}>
              update contract
            </Button>
          </p>
        </>
      )}
      {component}
      {passwordComponent}
    </SponsorshipContainer>
  )
}

export default Sponsorship
