//created by Peggy on 2021/3/24
import React from "react";

import EventList from "./List";

import "@/styles/home/event.less";
import styled from "styled-components";
const IndexContainer = styled.div``;

const Index = () => {
  return (
    <IndexContainer className="event-container flex column">
      <EventList />
    </IndexContainer>
  );
};

export default Index;
