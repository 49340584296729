//created by Peggy on 2021/4/23
import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getUser, user, userLogin } from "@/model/user";
import { useModel } from "use-reaction";
import qs from "query-string";

const Auth = () => {
  const { token } = useParams() as any;
  const history = useHistory();
  const { to } = qs.parse(history.location.search);

  const { doAction } = useModel(user);
  const handleRedirect = async () => {
    const data: any = await doAction(getUser);
    const isNewDonor = data?.roles?.length === 1 && data.roles?.[0] === 'donor'
    if (to) {
      history.push(to as string);
    } else if (data?.progress >= 4 || isNewDonor) {
      if (data?.roles?.includes("artist")) {
        history.push("/dashboard/income");
      } else {
        history.push("/dashboard/history");
      }
    } else {
      history.push(`/sign/${data?.roles[0]}/${data?.progress ? data.progress - 1 : 0}`);
    }
  };

  const handleLink = async () => {
    await doAction(userLogin, {
      save: true,
      token: token,
      fromAdmin: true,
    });
    await handleRedirect();
  };

  useEffect(() => {
    handleLink();
    // eslint-disable-next-line
  }, []);

  return <div className="Auth-container">link ...</div>;
};

export default Auth;
