//created by Pegg on 2021/1/25
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import HomeLayout from "@/components/layouts/HomeLayout";
import success_img from "@/images/icons/icon_donation.svg";

import styled from "styled-components";
import { user } from "@/model/user";
import { useModel } from "use-reaction";
const DonationSuccessContainer = styled.div``;

const Success = () => {
  const { store } = useModel(user);

  return (
    <HomeLayout>
      <DonationSuccessContainer className="donation-success-container flex column">
        <img src={success_img} alt="" />
        <h1>You're all set!</h1>
        <h4>
          {console.log(window.location.href)}
          {window.location.href.indexOf("event") > -1 ? (
            "Thank you for registering! Please check your inbox for order details, as well as updates as this event approaches."
          ) : (
            <>
              Thank you for your order! To view your order detail, please go to
              Transaction
              <br /> History under Account Settings.
            </>
          )}
        </h4>
        {(store.roles?.includes("artist") && (
          <Link to="/dashboard/income">
            <Button type="primary">Go to Dashboard</Button>
          </Link>
        )) || (
          <Link to="/dashboard/history">
            <Button type="primary">Go to Dashboard</Button>
          </Link>
        )}
        <p>
          <a href={`mailto:support@thefield.org`}>Contact us</a>
        </p>
      </DonationSuccessContainer>
    </HomeLayout>
  );
};

export default Success;
