import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Popover, Modal } from "antd";

import SignLayout from "@/components/layouts/SignLayout";
import BaseInfo from "@/pages/sign/flow/BaseInfo";
import OtherInfo from "@/pages/sign/flow/OtherInfo";
import AddressInfo from "@/pages/sign/flow/AddressInfo";
import Hobby from "@/pages/sign/flow/Hobby";

import { ReactComponent as Lock } from "@/images/lock.svg";

import { useModel, Action } from "use-reaction";
import { user, saveUser } from "@/model/user";

import { saveUserInfo } from "@/api/sign-api";
import { localStorageGet } from "@/utils/storage";
import { TheField } from "@/components/GoogleTranslate";

const textArr: any = {
  artist: [
    {
      title: "Let’s get started",
      description: (
        <>
          Please fill out your contact information below. If you’re signing up
          as an organization or create work under a different name - that’s
          okay! You can enter that information later. Be assured your
          information is safe with us.
        </>
      ),
    },
    {
      title: "Tell us more about yourself",
      description: "We'd love to learn more about you",
    },
    {
      title: "What is your primary artistic discipline?",
      description:
        "You can select one discipline as your primary practice.",
    },
    {
      title: "Please indicate any other creative discipline(s) you practice.",
      description:
        "Choose all that apply",
    },
  ],
  donor: [
    {
      title: "Let’s get started",
      description: (
        <>
          Please fill out your contact information below. If you’re signing up
          as an organization or create work under a different name - that’s
          okay! You can enter that information later. Be assured your
          information is safe with us.
        </>
      ),
    },
    {
      title: "Tell us more about yourself",
      description: (
        <>
          Please fill out your information below. Be assured that your
          information is safe with us.
        </>
      ),
    },
    {
      title: "What are your art interests?",
      description:
        "What are the types of art you or your organization interested in? ",
    },
  ],
};

type typeProp = "artist" | "donor";
interface paramsProps {
  type: typeProp;
  step: any;
}

const onNext = (
  _data: any,
  callback: (params?: string) => void,
  doAction: (_: Action<any>, params?: any) => void
) => {
  doAction(saveUser, _data);
  saveUserInfo(_data).then((data: any) => callback(data.id));
};

const renderDom = ({ step, history, type, doAction, info }: any) => {
  switch (step) {
    case "3":
      return (
        <Hobby
          info={info}
          mode="multi"
          onPrevious={() => {
            history.push(`/sign/${type}/2`)
          }}
          onNext={(value) => {
            onNext(
              {
                tags: value,
                progress: 5,
                type,
              },
              (id) => history.push(`/sign/success/${id}`),
              doAction
            );
          }}
        />
      );
    case "2":
      return (
        <Hobby
          info={info}
          mode="single"
          onPrevious={(id) => {
            history.push(`/sign/${type}/1`)
          }}
          onNext={(value) => {
            onNext(
              {
                primaryTag: value[0],
                progress: 4,
                type,
              },
              () => history.push(`/sign/${type}/3`),
              doAction
            );
          }}
        />
      );
    case "1":
      return type === "artist" ? (
        <OtherInfo
          info={info}
          onPrevious={() => {
            history.push(`/sign/${type}/0`)
          }}
          onNext={(value) => {
            onNext(
              {
                ...value,
                progress: 3,
                type,
              },
              () => history.push(`/sign/${type}/2`),
              doAction
            );
          }}
        />
      ) : (
        <AddressInfo
          info={info}
          onPrevious={() => {
            history.push(`/sign/${type}/0`)
          }}
          onNext={(value) => {
            onNext(
              {
                ...value,
                progress: 2,
                type,
              },
              () => history.push(`/sign/${type}/2`),
              doAction
            );
          }}
        />
      );
    case "0":
    default:
      return (
        <BaseInfo
          info={info}
          onNext={(value) => {
            onNext(
              {
                ...value,
                progress: 1,
                type,
              },
              () => history.push(`/sign/${type}/1`),
              doAction
            );
          }}
        />
      );
  }
};
const SignFlow = () => {
  const { store, doAction } = useModel(user);
  const { type, step = 0 } = useParams() as paramsProps;
  const history = useHistory();
  const [show, setShow] = useState(false);

  console.log("user", store, localStorageGet(""));

  return (
    <SignLayout>
      <div className="sign-container sign-flow-container  flex column">
        <span className="step-wrap">
          step <i>{Number(step) + 1}</i> of <i> 4</i>
        </span>
        <h1>{textArr[type] ? textArr[type][step]?.title : ""}</h1>
        <h4>
          {textArr[type] ? textArr[type][step]?.description : ""}
          {type === "artist" && step === "0" && (
            <Popover
              className="sign-wrap-popover"
              overlayClassName="sign-pop"
              content="Your information is protected with industry-standard
256-bit SSL encryption. The Field will never share your
information without your consent."
              title="The Field Security"
            >
              <Lock />
            </Popover>
          )}
          <Lock className="mobile-popover" onClick={() => setShow(true)} />
        </h4>
        {renderDom({
          step,
          type,
          history,
          doAction,
          info: store?.info,
        })}
        <Modal
          title="The Field Security"
          wrapClassName="sign-popover-modal"
          visible={show}
          onCancel={() => {
            setShow(false);
          }}
          onOk={() => {
            setShow(false);
          }}
        >
          Your information is protected with industry-standard 256-bit SSL
          encryption. <TheField/> will never share your information without your
          consent.
        </Modal>
      </div>
    </SignLayout>
  );
};

export default SignFlow;
