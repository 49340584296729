//created by pegg on 2021/1/20
import React, { useEffect } from "react";
import { Button, Divider, Form, Input, message, Select } from "antd";
import Back from "@/components/Back";
import { stateList } from "@/types/enum";

interface Props {
  onPrevious?: () => void;
  onNext?: (value: any) => void;
  info?: any;
}

const AddressInfo = ({ onNext, onPrevious, info = {} }: Props) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);
    const { address, address2, ...others } = values;
    onNext &&
      onNext({
        addresses: [address, address2],
        ...others,
      });
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  useEffect(() => {}, []);

  return (
    <div className="address-info-container">
      <Form
        layout="vertical"
        size="large"
        name="basic"
        form={form}
        initialValues={{ ...info }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Address",
            },
          ]}
        >
          <Input placeholder="Address" maxLength={230} />
        </Form.Item>
        <Form.Item
          label="Address 2 (Optional)"
          name="address2"
          rules={[
            {
              whitespace: true,
              message: "Address 2 (Optional)",
            },
          ]}
        >
          <Input placeholder="Address 2 (Optional)" maxLength={230} />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "City",
            },
          ]}
        >
          <Input placeholder="City" maxLength={30} />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          className="select-container"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "State",
            },
          ]}
        >
          <Select placeholder="State">
            {Object.values(stateList)?.map((item) => (
              <Select.Option value={item} key={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Zip Code"
          name="zipCode"
          rules={[
            {
              required: false,
              whitespace: true,
              message: "Zip Code",
            },
          ]}
        >
          <Input placeholder="Zip Code" maxLength={5} />
        </Form.Item>
      </Form>
      <Divider />
      <p className="flex">
        <Back onClick={() => {
          if (onPrevious) {
            onPrevious()
          }
        }}>Previous step</Back>
        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </p>
    </div>
  );
};

export default AddressInfo;
