//created by Peggy on 2021/1/22
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

import HomeLayout from "@/components/layouts/HomeLayout";

import "@/styles/home/donation.less";
import { Steps } from "antd";
import DonationInput from "@/pages/homepage/donation/DonationInput";
import DonationPay from "@/pages/homepage/donation/DonationPay";
import { crowGet } from "@/api/donation-api";
import { getArtistById } from "@/api/artist-api";
import { getEnv } from "@/libs/cfg";
import { NoTranslate } from "@/components/GoogleTranslate";

const DonationContainer = styled.div``;

const Index = () => {
  const history = useHistory();
  const { step = "0", type = "field", id = 0, payId } = useParams() as any;
  const [user, setUser] = useState<any>("");

  useEffect(() => {
    const init = async () =>{
      if (type === "crowd") {
        const data = await crowGet({id})
        setUser(data);
      } else if (type === "general") {
        const data = await getArtistById({id})
        const { tags, feeRate, avatar, displayName } = data;
        setUser({
          name: displayName,
          artist: {
            avatar,
            tags,
            feeRate,
            displayName,
          },
        });
      } else {
        setUser({
          name: "The Field",
          artist: {
            firstName: "",
            lastName: "",
            tags: [],
            feeRate: getEnv("DONATION_TO_FIELD_FEE"),
          },
        });
      }
    }
    init()
    // eslint-disable-next-line
  }, []);
  return (
    <HomeLayout>
      <DonationContainer className="donation-container">
        <h1>Support <NoTranslate>{user?.name}</NoTranslate></h1>
        <h2>{user?.summary}</h2>
        <Steps current={step ? Number(step) : 0}>
          <Steps.Step title="Donation detail" />
          <Steps.Step title="Payment" />
        </Steps>
        <div className="donation-info flex align-start">
          {step === "0" ? (
            <DonationInput
              artistInfo={user}
              onNext={(url?: string) =>
                history.push(url || `/home/donation/${type}/${id}/1`)
              }
            />
          ) : (
            <DonationPay
              artistInfo={user}
              type={type}
              onNext={() => history.push(`/home/donation/success/${payId}`)}
            />
          )}
        </div>
      </DonationContainer>
    </HomeLayout>
  );
};

export default Index;
