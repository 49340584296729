//created by Peggy on 2021/4/9
import React, { useEffect, useState, useRef } from "react";

import { List, Input } from "antd";
import {
  DownOutlined,
  SearchOutlined,
  CloseCircleFilled,
} from "@ant-design/icons";

import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import VList from "react-virtualized/dist/commonjs/List";

import ClickOutside from "@leafyjava/react-click-away";

import defaultImg from "@/images/ins_01.jpg";
import styled from "styled-components";
import dayjs from "dayjs";
import './SelectCard.less'

const SelectCardContainer = styled.div`
  
`;

const VirtualizedListContainer = styled.div`
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
`;

interface Props {
  label?: string;
  value?: any;
  options?: any[];
  onChange?: (val?: any) => void;
  onSearch?: (val: any) => void;
  initSelect?: any;
  disable?:boolean
}

const VirtualizedList = ({
  data,
  selected = "",
  onSelect = (val?: any) => {},
}: any) => {
  const renderItem = ({ index, key, style }: any) => {
    const item = data[index];
    return (
      <List.Item
        className={
          selected && JSON.stringify(selected) === JSON.stringify(item)
            ? "selected"
            : ""
        }
        key={key}
        style={style}
        onClick={() => onSelect(item)}
      >
        <div>{item?.name}</div>
      </List.Item>
    );
  };

  const vlist = ({ height, width }: any) => (
    <VList
      autoHeight
      height={height}
      rowCount={data.length}
      rowHeight={50}
      rowRenderer={renderItem}
      width={width}
    />
  );
  return (
    <VirtualizedListContainer>
      {data.length > 0 && (
        <AutoSizer>
          {({ width, height }) =>
            vlist({
              height,
              width,
            })
          }
        </AutoSizer>
      )}
    </VirtualizedListContainer>
  );
};

const SelectCard = ({
  label = "grant",
  options = [],
  value,
  initSelect,
  disable,
  onChange = () => {},
  onSearch = () => {},
}: Props) => {
  const currentInput = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<any>();

  const handleSearch = (val: string) => {
    console.log(val);
    setSearch(val);
    onSearch(val);
  };
  useEffect(() => {
    setShow(false);
    selected !== undefined && onChange(selected);
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (show) {
      setSearch("");
      onSearch("");
      currentInput?.current?.input?.focus();
    }
    // eslint-disable-next-line
  }, [show]);
  const val = value || selected;
  return (
    <SelectCardContainer className="select-card-container">
      <label>{label}</label>
      <div
        className="select-wrap"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if(disable) return
          !show && setShow(true);
        }}
      >
        <span>{val?.name || <i>select a options</i>}</span>
        {val && !disable && (
          <CloseCircleFilled
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSelected("");
              setShow(false);
            }}
          />
        )}
        <DownOutlined className={(show && "active") || ""} />
      </div>
      {val && (
        <div className="selected-card-wrap">
          <img src={defaultImg} alt="img" />
          <div className="right-item">
            <h4>
              {val?.name}{" "}
              <a
                rel="noreferrer"
                href={`/home/grant/${val.id}/${val.name.replace(
                  /[^\w]/g,
                  "-"
                )}`}
                target="_blank"
              >
                view detail {"  >"}
              </a>
            </h4>
            <ul>
              <li>
                <p>
                  <span>Deadline</span>
                  <i>{dayjs(val?.deadline).format("MM/DD/YYYY")}</i>
                </p>
              </li>
              <li>
                <p>
                  <span>Value</span>
                  <i>{val?.award}</i>
                </p>
              </li>
              <li>
                <p>
                  <span>Discipline</span>
                  <i>{val?.discipline}</i>
                </p>
              </li>
            </ul>
          </div>
        </div>
      )}

      <ClickOutside onClickOutside={() => setShow(false)}>
        <div className={`list-wrap ${show ? "show" : "hidden"}`}>
          <Input
            value={search}
            ref={currentInput}
            size="large"
            bordered={false}
            placeholder="Search"
            prefix={<SearchOutlined />}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <VirtualizedList
            data={options}
            onSelect={(val: any) => setSelected(val)}
            selected={val}
          />
        </div>
      </ClickOutside>
    </SelectCardContainer>
  );
};

export default SelectCard;
