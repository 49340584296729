//created by Peggy on 2021/7/2
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { Button, Dropdown, Menu, Tag } from 'antd'
import dayjs from 'dayjs'
import DocumentTable from '@/pages/dashboard/donation/InkindTable'
import { ReactComponent as FilterMore } from '@/images/filter-more.svg'
import { grantApply, grantListInArtist } from '@/api/grant-api'
import qs from 'query-string'
import { localStorageGet } from '@/utils/storage'
import { typeRequest } from '@/types/enum'

import styled from 'styled-components'
import GrantStep from '@/pages/dashboard/grants/Step'
import { getSponsor } from '@/api/merbership-api'
import { useModel } from 'use-reaction'
import { view } from '@/model/view'
const GrantListContainer = styled.div`
  .table-item {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    box-shadow: 4px 4px 14px rgb(0 0 0 / 8%);
    .ant-table {
      border: 0;
    }
    .ant-table-tbody > tr > td.ant-table-cell-ellipsis {
      max-width: 200px;
    }
    .ant-pagination {
      padding: 0 32px;
      .ant-pagination-total-text {
        position: absolute;
        left: 16px;
        color: #000;
        font-weight: 700;
      }
    }
  }
`

const statusDisplay = {
  applied: {
    lbl: 'Pending',
    color: 'orange'
  },
  rush_pending: {
    lbl: 'Rush fee required',
    color: 'red'
  },
  incomplete: {
    lbl: "Incomplete",
    color: "orange",
  },
  approved: {
    lbl: 'Approved',
    color: 'green'
  },
  rejected: {
    lbl: 'Declined',
    color: 'red'
  },
  failed: {
    lbl: 'Failed',
    color: 'red'
  },
  accepted: {
    lbl: 'Accepted',
    color: 'blue'
  }
}

const GrantList = () => {
  const history = useHistory()
  const { request } = qs.parse(history.location.search)
  const userInfo = localStorageGet('info')
  const canGoRequest = request && userInfo.sponsorshipValid
  //table
  const columns: any[] = [
    {
      title: 'Request Name',
      width: 120,
      dataIndex: 'grant_name',
      ellipsis: {
        title: true
      },
      fixed: 'left'
    },
    {
      title: 'Request type',
      width: 120,
      dataIndex: 'grant_type',
      fixed: 'left',
      ellipsis: true,
      render: (text: string) => typeRequest[text]
    },
    {
      title: 'Status',
      width: 90,
      dataIndex: 'status',
      render: (status: keyof typeof statusDisplay, item: any) => (
        <Tag color={statusDisplay[status]?.color || 'orange'} className="status">
          {status === 'rush_pending'
            ? item.rush_card_filled
              ? 'Pending'
              : statusDisplay[status]?.lbl
            : statusDisplay[status]?.lbl || 'Pending'}
        </Tag>
      )
    },

    {
      title: 'due date',
      width: 100,
      dataIndex: 'grant_deadline',
      render: (text: any, row: any) => (text && dayjs(text).format('MM/DD/YYYY')) || 'N/A'
    },
    {
      title: 'Date submitted',
      width: 130,
      dataIndex: 'createDate',
      render: (text: any, row: any) => (text && dayjs(text).format('MM/DD/YYYY')) || 'N/A'
    },
    {
      title: 'Date approved',
      width: 130,
      dataIndex: 'approveDate',
      render: (text: any, row: any) => (text && dayjs(text).format('MM/DD/YYYY')) || 'N/A'
    },
    {
      title: '',
      width: 80,
      align: 'center',
      key: 'action',
      render: (_: string, row: any) => (
        <Dropdown
          trigger={['click']}
          className="grant-list-item-dropdown"
          overlay={() => (
            <>
              <Menu>
                {row.status === 'rush_pending' && !row.rush_card_filled && (
                  <Menu.Item onClick={() => goPayment(row)}>Pay Rush Fee</Menu.Item>
                )}
                <Menu.Item
                  onClick={() =>{
                    let url;
                    if(row.grant_type === 'grant'){
                      if(row.status === 'incomplete'){
                        url = `/dashboard/grant/create?request=${row.grant_id}&applyId=${row.id}&mode=Update`;
                      }else{
                        url = `/dashboard/grant/application/${row.id}`
                      }
                    }else{
                      if(row.status === 'incomplete'){
                        url = `/dashboard/grant/request/${row.id}?mode=Update`
                      }else{
                        url = `/dashboard/grant/request/${row.id}`
                      }
                    }
                    history.push(url);
                  }}
                >
                  {row.status === 'incomplete' ? 'Edit application' : 'View application'}
                  <span className="red-dot" hidden={!row.reddot}></span>
                </Menu.Item>

                <Menu.Item onClick={() => history.push('/dashboard/message')}>
                  Message admin
                </Menu.Item>
                {/*<Menu.Item onClick={() => handleDelete(row.id)}>*/}
                {/*  Delete application*/}
                {/*</Menu.Item>*/}
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
            <span className="red-dot" hidden={!row.reddot}></span>
          </span>
        </Dropdown>
      )
    }
  ]
  const [params, setParams] = useState<any>({
    keyword: '',
    page: 1,
    pageSize: 20
  })
  const [tableData, setTableData] = useState([])
  const {
    store: { isMobile }
  } = useModel(view)
  const getList = () => {
    const { pageSize: limit, ...others } = params
    let _data = {
      ...others,
      limit
    }
    grantListInArtist(_data).then((data) => {
      setTableData(data.items)
    })
  }
  useEffect(() => {
    getList()
    // eslint-disable-next-line
  }, [params])

  const goPayment = (item: any) => {
    grantApply({ id: item.grant_id }).then((data) => {
      history.push(`/dashboard/grant/${data.order?.id}/${data?.rushFee}/${encodeURIComponent(item.grant_name)}`)
    })
  }

  // const handleDelete = (id: any) => {
  //   grantDelete(id).then((data) => {
  //     message.success('Delete successfully')
  //     getList()
  //   })
  // }

  const [sponsorship, setSponsorship] = useState(true)

  useEffect(() => {
    getSponsor({}).then((data: any) => setSponsorship(data.status === 'completed'))
  }, [])

  return canGoRequest ? (
    <Redirect to={`/dashboard/grant/create?request=${request}`} />
  ) : sponsorship ? (
    <GrantListContainer className="home-grant-list-container common-container">
      <h1
        className={isMobile ? '' : 'flex'}
        style={{ width: '100%', ...(isMobile ? { fontSize: '32px' } : '') }}
      >
        Grants & Solicitations
        <span className={isMobile ? 'flex start' : ''}>
          <Button
            style={{ marginRight: 20 }}
            type="primary"
            onClick={() => history.push(`/dashboard/grant/request`)}
          >
            Request review
          </Button>
          <Button
            type={isMobile ? 'default' : 'primary'}
            onClick={() => history.push(`/grants-and-opportunities`)}
          >
            {isMobile ? 'Explore' : 'Explore Grants'}
          </Button>
        </span>
      </h1>
      <DocumentTable
        title="Requests"
        className="grant-table"
        tableData={tableData}
        columns={columns}
        scroll={{
          scrollToFirstRowOnChange: true,
          x: 'max-content',
          ...(tableData?.length ? { y: 'calc(100vh - 350px)' } : {})
        }}
        onSearch={(e) =>
          setParams({
            ...params,
            keyword: e
          })
        }
        pagination={{
          current: params.page,
          pageSize: params.pageSize,
          simple: isMobile,
          showTotal: (total: any) => `Total applications :  ${total} `,
          onChange: (page: number, pageSize: number) => {
            setParams({
              ...params,
              ...{
                page,
                pageSize
              }
            })
          }
        }}
      />
    </GrantListContainer>
  ) : (
    <GrantStep initFlag={!sponsorship} />
  )
}

export default GrantList
