//created by Peggy on 2021/3/5
import React from "react";
import { Divider, Form, Input, message, Modal, Select } from "antd";
import DatePicker from "@/components/DatePicker";
import { InKindAdd } from "@/api/donation-api";
import { stateList } from "@/types/enum";

interface Props {
  show?: boolean;
  onClose?: () => void;
}

const GeneralFundModal = ({ show = false, onClose = () => {} }: Props) => {
  //Modal
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { description, date, ...others } = values;
      InKindAdd({
        description,
        date,
        donor: others,
      }).then((data) => {
        message.success("In-kind donation added successfully.");
        onCancel();
      });
    });
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };
  return (
    <Modal
      className="common-modal"
      title="Add in-kind donation"
      visible={show}
      onOk={handleSubmit}
      okText="save"
      cancelText="cancel"
      onCancel={onCancel}
      forceRender
      centered
    >
      <Form form={form} layout="vertical" name="form_inking">
        <h2>Donation information</h2>
        <Form.Item
          label="Donation Description"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Donation Description",
            },
          ]}
        >
          <Input maxLength={200} placeholder="Donation Description" />
        </Form.Item>
        <Form.Item
          label="Donation date"
          name="date"
          rules={[
            {
              required: true,
              message: "Donation date",
            },
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Divider />
        <h2>Donor information</h2>
        <Form.Item
          label="Donor name"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Donor name",
            },
          ]}
        >
          <Input maxLength={50} placeholder="Donor name" />
        </Form.Item>
        <Form.Item
          label="Donor public name"
          name="publicName"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Donor public name",
            },
          ]}
        >
          <Input maxLength={50} placeholder="Donor public name" />
        </Form.Item>
        <Form.Item
          label="email address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              whitespace: true,
              message: "email address",
            },
          ]}
        >
          <Input maxLength={50} placeholder="email address" />
        </Form.Item>
        <Form.Item
          label="address"
          name="address"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Address",
            },
          ]}
        >
          <Input maxLength={200} placeholder="Address" />
        </Form.Item>
        <Form.Item
          label="City"
          name="city"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "City",
            },
          ]}
        >
          <Input placeholder="City" maxLength={30} />
        </Form.Item>
        <div className="flex">
          <Form.Item
            label="State"
            name="state"
            className="select-container"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "State",
              },
            ]}
          >
            <Select placeholder="State">
              {Object.values(stateList)?.map((item) => (
                <Select.Option value={item} key={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Zip Code"
            name="zipCode"
            rules={[
              {
                required: false,
                whitespace: true,
                message: "Zip Code",
              },
            ]}
          >
            <Input placeholder="Zip Code" maxLength={5} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default GeneralFundModal;
