//created by Peggy on 2021/3/25
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Select } from "antd";
import { CheckSquareFilled, MinusSquareFilled } from "@ant-design/icons";

const SelectCheckBoxContainer = styled.div``;

interface Props {
  options: any[];
  value?: any[];
  onChange?: (val: any) => void;
  bordered?: boolean;
}

const SelectCheckBox = ({
  options,
  value = [],
  onChange = (val: any) => {},
  bordered = true,
}: Props) => {
  const [val, setVal] = useState<any[]>(value);
  useEffect(() => {
    value?.length && setVal(value);
  }, [value]);
  return (
    <SelectCheckBoxContainer className="select-checkbox-container">
      <Select
        bordered={bordered}
        value={val}
        mode="multiple"
        placeholder="Select an event discipline"
        optionLabelProp="label"
        dropdownClassName="checkbox-select-dropdown"
        onBlur={() => onChange(val)}
        onChange={(e) => {
          if (e?.includes("")) {
            if (e?.length === options?.length + 1) {
              console.log(1);
              setVal([]);
            } else {
              console.log(2);
              setVal(options);
            }
          } else {
            console.log(3);
            setVal(e);
          }
        }}
      >
        <Select.Option
          key="-1"
          value=""
          label=""
          className="select-all-option"
          onClick={(e: any) => {
            e.stopPropagation();
            e.preventDefault();
            if (val?.length < options?.length) {
              setVal(options);
            } else {
              setVal([]);
            }
          }}
        >
          {val?.length === 0 ? (
            <span className="uncheck"></span>
          ) : val?.length < options?.length ? (
            <MinusSquareFilled />
          ) : (
            <CheckSquareFilled />
          )}
          Select all
        </Select.Option>
        {options.map((item) => (
          <Select.Option key={item} value={item} label={item}>
            <>
              {(val?.includes(item) && <CheckSquareFilled />) || (
                <span className="uncheck"></span>
              )}{" "}
              {item}
            </>
          </Select.Option>
        ))}
      </Select>
    </SelectCheckBoxContainer>
  );
};

export default SelectCheckBox;
