//created by Peggy on 2021/1/22
import React from "react";
import { Divider, Tooltip } from "antd";
import styled from "styled-components";

import avatar from "@/images/default_head.svg";
import card_img from "@/images/card.svg";

import { useModel } from "use-reaction";
import { donation } from "@/model/donation";
import { InfoCircleFilled } from "@ant-design/icons";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

const DonationCardContainer = styled.div``;

interface Props {
  type?: string;
  hidden?: boolean;
  artistInfo: any;
  children?: React.ReactNode;
}

const TotalCard = ({
  type = "general",
  children = "",
  hidden = false,
  artistInfo = {},
}: Props) => {
  const { store } = useModel(donation);
  const totalAmount = store?.cover
    ? (
        Number(store?.fieldAmount || 0) +
        Number(store?.dAmount || store?.cAmount || 0) / (1 - store?.feeRate)
      ).toFixed(2)
    : Number(store?.dAmount || store?.cAmount || 0) + Number(store?.fieldAmount || 0);
  const transactionFee = store?.cover
    ? (
        Number(store?.dAmount || store?.cAmount || 0) / (1 - store?.feeRate) -
        Number(store?.dAmount || store?.cAmount || 0)
      ).toFixed(2)
    : 0;
  return (
    <DonationCardContainer className="donation-card-container">
      {type !== "field" && (
        <>
          <div className="flex">
            <div className="flex column align-start" style={{ width: "100%" }}>
              <img src={artistInfo?.artist?.avatar || avatar} alt="avatar" />
              <h1><NoTranslate>{artistInfo?.artist?.displayName}</NoTranslate></h1>
              <h4>
                {artistInfo?.artist?.tags?.join(" • ").replace(/Other -/g, "")}
              </h4>
            </div>
          </div>
          <Divider />
        </>
      )}
      <div className="donation-order-wrap">
        <p className="flex">
          <span>
            Donation amount -{" "}
            {store?.frequency === "once" ? "One time" : "Recurring"}
          </span>
          <span>
            {Number(store?.dAmount || store?.cAmount || 0).toLocaleString(
              "en",
              {
                style: "currency",
                currency: "USD",
              }
            )}
          </span>
        </p>
        {store?.cover && (
          type === 'field'? <p className="flex">
            <span>
              Transaction fee - {(store?.feeRate * 100).toFixed(2)}%
            </span>
            <span>{
              (
                Number(store?.dAmount || store?.cAmount || 0) / (1 - store?.feeRate) - Number(store?.dAmount || store?.cAmount || 0)
              ).toFixed(2)
              }</span>
          </p> :
          <p className="flex">
            <span>
              Transaction fee{" "}
              {type !== "field" && <Tooltip
                title={`7% of the total goes towards The Field's processing fees so that ${Number(
                  store?.dAmount || store?.cAmount || 0
                ).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                })} goes to the artist.`}
              >
                <InfoCircleFilled
                  style={{ fontSize: 16, color: "#696969", marginLeft: 4 }}
                />
              </Tooltip>}
            </span>
            <span>
              {Number(transactionFee).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
        )}
        {type !== "field" && store?.contribution && store?.fieldAmount && (
          <p className="flex">
            <span>Contribute to <TheField/></span>
            <span>
              {Number(store?.fieldAmount || 0).toLocaleString("en", {
                style: "currency",
                currency: "USD",
              })}
            </span>
          </p>
        )}
        <Divider />
        <p className="flex total-item">
          <span>Total amount</span>
          <span>
            {totalAmount.toLocaleString("en", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        </p>
      </div>
      {children}
      {!hidden && (
        <div className="donation-tips flex column start align-start">
          <span>
            We accept most major credit, debit, and prepaid cards, including
            Visa, MasterCard, American Express, Discover, and JCB. We also
            accept PayPal and ACH transfer.{" "}
          </span>
          <img src={card_img} alt="" />
          <span>
            {type !== "field"
              ? <>Donations can also be made by check and mailed to <TheField/> by way of the Sponsored Artist. 
              Please make the check payable to <TheField/>, include the Sponsored Artist's name in the memo section,
              and send your checks to the Sponsored Artist to begin processing.
              </>
              : <> Donations can also be made by check and mailed to <TheField/>, 228 Park Ave S, Suite 97217 New York, NY 10003-1502.</>}
          </span>
        </div>
      )}
    </DonationCardContainer>
  );
};

export default TotalCard;
