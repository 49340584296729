//created by Pegg on 2021/1/20
import React, { useEffect, useState } from "react";
import { Button, message } from "antd";

import Input from "@/components/Input";
import Back from "@/components/Back";

interface Props {
  onNext?: (value: any) => void;
  onPrevious?: (value?: any) => void;
  info?: any;
  mode: 'single' | 'multi'
}

const Hobby = ({ onNext, onPrevious, info = {}, mode }: Props) => {
  const state = [
    "Dance",
    "Film",
    "Music",
    "Performance Art",
    "Writing",
    "Theatre",
    "Visual Art",
    "Other",
  ];
  const [select, setSelect] = useState<string[]>(mode === 'single' ? (info?.primaryTag ? [info?.primaryTag] : []) : info?.tags || []);
  const [text, setText] = useState("");

  const handleSelect = (item: string) => {
    const index = select.findIndex((cell) => item === cell);
    if (index > -1) {
      let _temp = JSON.parse(JSON.stringify(select));
      _temp.splice(index, 1);
      setSelect(_temp);
    } else {
      setSelect(mode === 'multi' ? Array.from(new Set([...select, item])) : [item]);
    }
  };

  const handleSubmit = async () => {
    let _temp = select;
    if (_temp.includes("Other")) {
      if (text) {
        _temp = select.filter((item) => item.indexOf("Other") === -1);
        _temp.push("Other - " + text);
      } else {
        message.error("Enter your other disciplines");
        return false;
      }
    }
    onNext && (await onNext(_temp));
  };

  useEffect(() => {
    setSelect(mode === 'single' ? (info?.primaryTag ? [info?.primaryTag] : []) : info?.tags || [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  return (
    <div className="hobby-container">
      <div className="hobby-list flex wrap">
        {state.map((item) => {
          const disabled = mode === 'multi' && info?.primaryTag === item
          return (
            <span
              className={disabled ? "disabled" : select.includes(item) ? "active" : ""}
              onClick={() => !disabled && handleSelect(item)}
              key={item}
            >
              {item}
            </span>
          );
        })}
      </div>
      {select.indexOf("Other") > -1 && (
        <div className="other-input">
          <Input
            label="please specify"
            maxLength={30}
            placeholder="Enter your disciplines here"
            onChange={(e: any) => setText(e)}
          />
        </div>
      )}

      <div className="flex hobby-btn">
        <Back onClick={() => {
          if (onPrevious) {
            onPrevious();
          }
        }}>Previous step</Back>
        <Button type="primary" onClick={handleSubmit} disabled={!(info?.primaryTag || (mode === 'single' && select.length))}>
          {mode === 'single' ? 'Next' : 'Done'}
        </Button>
      </div>
    </div>
  );
};

export default Hobby;
