import SignIn from "@/pages/sign/SignIn";
import SignUp from "@/pages/sign/SignUp";

import SignFlow from "@/pages/sign/SignFlow";

import SignSuccess from "@/pages/sign/flow/Success";

import RecoverPassword from "@/pages/sign/password/Recover";
import ChangePassword from "@/pages/sign/password/ChangePwd";

import Auth from "@/pages/sign/Auth";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: "/sign/in",
    component: SignIn,
    exact: true,
  },
  {
    path: "/sign/up/:type(artist|donor)?",
    component: SignUp,
    exact: true,
  },
  {
    path: "/sign/success/:id(\\d+)",
    component: SignSuccess,
    exact: true,
  },
  {
    path: "/sign/:type(artist|donor)/:step(\\d+)?",
    component: SignFlow,
    exact: true,
  },
  {
    path: "/sign/password/change/:id/:salt/:from(donor)?",
    component: ChangePassword,
    exact: true,
  },
  {
    path: "/sign/password",
    component: RecoverPassword,
    exact: true,
  },
  {
    path: "/auth/:token([\\w\\.\\-]+)",
    component: Auth,
    exact: true,
  },
];
