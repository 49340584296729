import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import NoMatch from "@/pages/error/404";
import dashboardRoutes from "./dashboard";
import homeRoutes from "./homepage";
import signRoutes from "./sign";
import { useRequestHandler, useLoadingHandler } from "@/libs/fetch";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import { Spin } from "antd";
import { useWindowSize } from "react-use";
import { useEffect } from "react";
import { view } from "@/model/view";
import { mobileSize } from "@/types/enum";

interface props {
  token: string;
  routes: any[];
}

const mapRoutes = [...homeRoutes, ...signRoutes];

const AuthRoute = ({ token, routes }: props): any => {
  if (token) {
    return routes.map((item) => <Route key={item.path} {...item} strict />);
  } else {
    localStorage.clear();
    return <Redirect to="/sign/in" />;
  }
};

const AppRouter = () => {
  useRequestHandler();
  const [show] = useLoadingHandler();
  const {
    store: { token },
  } = useModel(user);

  const { doAction } = useModel(view)
  const { width: winSize } = useWindowSize();
  useEffect(() => {
    doAction(() => ({ isMobile: winSize < mobileSize }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winSize])

  return (
    <Router>
      {show && <Spin className="global-spin-container"></Spin>}

      <Switch>
        {mapRoutes.map((item) => (
          <Route key={item.path} {...item} strict />
        ))}
        <AuthRoute token={token} routes={dashboardRoutes} />
        <Route path="*" component={NoMatch} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
