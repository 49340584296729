//created by Peggy on 2021/2/1
import React from "react";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import styled from "styled-components";

const InfoContainer = styled.div`
  text-align: center;
  img {
    height: 130px;
    margin-bottom: 36px;
    margin-top: 64px;
  }
  h4 {
    font-size: 32px;
    line-height: 42px;
  }
  p {
    color: #696969;
  }
`;

interface Props {
  width?: string | number;
  content?: string;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  visible: boolean;
  okText?: string;
  closable?: boolean;
  onOk?: () => void;
  onClose?: () => void;
  className?: string;
  title?: string;
}

const Warning = ({
  width = 470,
  children = "",
  visible = true,
  okText = "Done",
  closable = true,
  onOk = () => {},
  onClose = () => {},
  footer = null,
  className = "",
  title = "Tips",
  content = "",
}: Props) => {
  return (
    <Modal
      title=""
      width={width}
      closable={closable}
      maskClosable={false}
      visible={visible}
      footer={footer}
      onOk={onOk}
      onCancel={onClose}
      className={`warning-modal ${className}`}
    >
      <InfoContainer className="info-container flex column">
        <p className=" title-wrapper flex start">
          <ExclamationCircleOutlined />
          <span>{title}</span>
        </p>
        {children ? (
          children
        ) : (
          <div className="modal-content-wrapper">{content}</div>
        )}
      </InfoContainer>
    </Modal>
  );
};

export default Warning;
