import { user } from "@/model/user";
import { sleep } from "@/utils/util";
import { Modal } from "antd";
import styled from "styled-components";
import { useModel } from "use-reaction";

const Container = styled.div`
    color:#1da57a;
    margin-bottom: 10px;
    cursor: pointer;
`

const IsThisCorrect = ({isStep2}:{isStep2?:boolean}) => {
    const { store } = useModel(user);
    const onSignOut = async () => {
      Modal.destroyAll()
      localStorage.clear();
      if(isStep2){
        window.history.go(-1)
        await sleep(300)
      }
      window.location.reload()
    };

    return store.token ? <Container onClick={()=>{
        Modal.confirm({
          width:'800px',
          title:"Is this incorrect?",
          content:"You are currently signed into the account associated with this contact info. To complete your transaction under a separate name or email address, please sign out.",
          okText:'CONTINUE AS GUEST',
          cancelText:'CONTINUE WITH THIS ACCOUNT',
          onOk:onSignOut
        })
      }}>Is this incorrect?</Container> : <></>
}

export default IsThisCorrect