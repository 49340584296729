import React from "react";
import styled from "styled-components";

const ErrorContainer = styled.div``;

const Error = () => {
  return <ErrorContainer>404</ErrorContainer>;
};

export default Error;
