//created by peggy on 2021/1/20
import React from "react";
import { Button, Divider, Form, Input, Select } from "antd";
import Back from "@/components/Back";

import dayjs from "dayjs";
import SelectAndInput from "@/components/Select/SelectAndInput";

interface Props {
  onPrevious?: () => void;
  onNext?: (value: any) => void;
  info?: any;
}

export const ethnic = [
  "American indian / Alaskan native",
  "Arab / Middle Easten",
  "Asian / Asian-American",
  "Black / African-American",
  "Hispanic / Latinx",
  "Native Hawaiian / Pacific Islander",
  "White / Caucasian",
  "Other",
];

const OtherInfo = ({ onNext, onPrevious, info = {} }: Props) => {
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    console.log("Success:", values);
    onNext && onNext(values);
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
  };

  return (
    <div className="address-info-container">
      <Form
        layout="vertical"
        size="large"
        name="basic"
        form={form}
        initialValues={{ ...info }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Pronouns (Optional)"
          name="pronouns"
          rules={[
            {
              required: false,
              whitespace: true,
              message: "Address",
            },
          ]}
        >
          <Input
            autoComplete="off"
            placeholder="Enter pronouns (Optional)"
            maxLength={30}
          />
        </Form.Item>
        <Form.Item
          label="ethnic background (Optional)"
          name="background"
          initialValue={[]}
          className="select-container"
          rules={[
            {
              required: false,
              type: "array",
              message: "ethnic background",
            },
          ]}
        >
          <SelectAndInput
            placeholderForSelect="Please select all that apply"
            placeholder="Enter your ethnic background(s) here"
            options={ethnic}
          />
          {/*<Select*/}
          {/*  className="multiple-row-selector"*/}
          {/*  mode="multiple"*/}
          {/*  placeholder="Select ethnic background"*/}
          {/*>*/}
          {/*{ethnic.map((item) => (*/}
          {/*  <Select.Option key={item} value={item}>*/}
          {/*    {item}*/}
          {/*  </Select.Option>*/}
          {/*))}*/}
          {/*</Select>*/}
        </Form.Item>
        <Form.Item
          label="What year did you begin your artistic career? (Optional)"
          name="startYear"
          className="select-container"
          rules={[
            {
              required: false,
              type: "number",
              message: "ethnic background",
            },
          ]}
        >
          <Select placeholder="Select a year">
            {new Array(dayjs().year() - 1900)
              .fill(1)
              .map((_, index) => (
                <Select.Option key={index} value={1901 + index}>
                  {1901 + index}
                </Select.Option>
              ))
              .reverse()}
          </Select>
        </Form.Item>
      </Form>
      <Divider />
      <p className="flex">
        <Back onClick={() => {
          if (onPrevious) {
            onPrevious();
          }
        }}>Previous step</Back>
        <Button type="primary" onClick={() => form.submit()}>
          Continue
        </Button>
      </p>
    </div>
  );
};

export default OtherInfo;
