//created by Pegg on 2021/2/10
import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import {
  Progress,
  Menu,
  Dropdown,
  Divider,
  Input,
  Tag,
  Button,
  Tooltip,
} from "antd";
import { Pie } from "@ant-design/charts";
import styled from "styled-components";
import DatePicker from "@/components/DatePicker";

import Search from "@/pages/dashboard/donation/Search";
import InKindTable from "@/pages/dashboard/donation/InkindTable";
import InKindModal from "@/pages/dashboard/donation/InKindModal";
import GeneralFundModal from "@/pages/dashboard/donation/GeneralFundModal";
import Modal from "@/components/Modal/Info";
import VirtualTable from "@/components/VirtualTable";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { incomeList, donationOverview } from "@/api/donation-api";
import { formatDuration } from "../income/CampaignCard";
import { ReactComponent as NoData } from "@/images/icon_menu_donation.svg";
import { donationTypes, paymentTypes, statusTypes } from "@/types/enum";
import queryString from "query-string";
import { user } from "@/model/user";
import { useModel } from "use-reaction";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getSponsor } from "@/api/merbership-api";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

const StatsContainer = styled.div``;

const Overview = () => {
  const history = useHistory();

  const [val, setVal] = useState<any>();
  const [status, setStatus] = useState<any>({
    income: [],
  });
  const {
    store: {
      info: { id: userId },
    },
  } = useModel(user);
  //pie config
  let isMobile = document.body.offsetWidth < 1024;
  const pie: any = {
    width: isMobile ? document.body.offsetWidth - 64 : 534,
    height: isMobile ? 286 : 226,
    autoFit: false,
    padding: isMobile ? [24, 122, 74, 24] : [32, 320, 32, 16],
    appendPadding: 0,
    data: status?.income,
    angleField: "value",
    colorField: "type",
    color: ["#FF7456", "#25F2FF"],
    radius: 1,
    innerRadius: 0.72,
    statistic: {
      title: {
        style: {
          fontSize: "12px",
          color: "#ccc",
          lineHeight: "20px",
          marginTop: "26px",
        },
        customHtml: () => {
          return `<span>total earned</span>`;
        },
      },
      content: {
        style: {
          fontSize: "20px",
          color: "#304659",
          lineHeight: "20px",
          marginTop: "-20px",
        },
      },
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.7,
        },
      },
    },
    meta: {
      value: {
        formatter: function formatter(v: any) {
          const _v = v?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return "$ ".concat(_v);
        },
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: { textAlign: "center" },
      autoRotate: false,
      content: "{value}",
    },
    interactions: [{ type: "element-active" }],
    legend: {
      position: "left",
      flipPage: false,
      offsetX: isMobile ? 32 : 220,
      offsetY: isMobile ? 120 : 0,
      maxItemWidth: 534,
      itemHeight: 24,
      itemName: {
        formatter: (text: string, item: any, index: number) => {
          return `${donationTypes[text]} ${
            text === "crowdfunding" ? "          " : "     "
          }     ${(
            (status?.income[index].value * 100) /
            (status?.income?.reduce(
              (sum: any, cur: any) => sum - 0 + cur?.value,
              0
            ) || 1)
          ).toFixed(2)} % ${"         "}  $ ${status?.income[index].value
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
        },
      },
    },
  };

  // Membership Model
  const [membershipStatus, setMembershipStatus] = useState("");
  const [membershipShow, setMembershipShow] = useState(false);
  const [showAction, setShowAction] = useState("");

  useEffect(() => {
    getSponsor({}).then((data: any) => {
      setMembershipStatus(data?.status);
    });
  }, []);

  useEffect(() => {
    const { date } = val || {};
    donationOverview(date).then((data) => setStatus(data));
  }, [val]);

  //In-kind table
  const [total, setTotal] = useState(0);
  const [inParams, setInParams] = useState<any>({
    page: 1,
    limit: 8,
    keyword: "",
  });
  const [inKindData, setInKindData] = useState<any[]>([]);
  const getKindList = () => {
    incomeList({
      ...inParams,
      type: "in_kind_donation_to_artist",
    }).then((data) => {
      setTotal(data?.meta?.totalItems);
      setInKindData(data.items);
    });
  };

  useEffect(() => {
    getKindList();
    // eslint-disable-next-line
  }, [inParams]);

  const chartMemo = useMemo(
    () => (
      <div className="flex align-start chart-wrapper">
        <div className="chart-wrap pie-wrap">
          <h4>Donation overview</h4>
          <Pie {...pie} />
        </div>
        <div className="chart-wrap crow-wrap">
          <h4>Crowdfunding campaign</h4>
          {status?.crowdfunding ? (
            <div className="crowdfunding-info">
              <h2 translate="no">{status?.crowdfunding?.name}</h2>
              <h2>
                {Number(status?.crowdfunding?.currentAmount).toLocaleString(
                  "en",
                  {
                    style: "currency",
                    currency: "USD",
                  }
                )}{" "}
                <i>
                  Raised of
                  {Number(status?.crowdfunding?.goalAmount).toLocaleString(
                    "en",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}{" "}
                  goals
                </i>
              </h2>
              <Progress
                showInfo={false}
                strokeLinecap="square"
                percent={
                  status?.crowdfunding?.goalAmount
                    ? Number(
                        Number(status?.crowdfunding?.currentAmount) /
                          Number(status?.crowdfunding?.goalAmount)
                      ) * 100
                    : 0
                }
              />
              <p>
                Donation ends in{" "}
                <b>{formatDuration(status?.crowdfunding?.endDate)}</b>
              </p>
            </div>
          ) : (
            <div className="no-data">
              <NoData />
              <h4>No crowdfunding campaign</h4>
              <p>
                Create a crowdfunding campaign to raise funds that will help you
                reach your goals{" "}
              </p>
              <Link to="/dashboard/donation/list">Get started</Link>
            </div>
          )}
        </div>
      </div>
    ),
    // eslint-disable-next-line
    [status]
  );

  //table
  const column: any[] = [
    {
      title: "Donation type",
      dataIndex: "type",
      key: "donationType",
      fixed: "left",
      width: 120,
      ellipsis: true,
      render: (text: string, row: any) => donationTypes[text || "_null"],
    },
    {
      title: "Donor",
      dataIndex: "donor",
      key: "name",
      ellipsis: true,
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "Donation Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text: string, row: any) => {
        if(row.paymentMethod === 'check'){
          if(row.status === 'check_pending'){
            return 'N/A';
          }
          return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
        }else{
          return dayjs(row.createDate).format("MM/DD/YYYY")
        }
      },
    },
    // {
    //   title: "received date",
    //   dataIndex: "createDate",
    //   key: "receivedDate",
    //   render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    // },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <ul>
                <li>
                  Paid - Donation successful; payment to artist scheduled or
                  complete.
                </li>
                <li>
                  Pending - Donation initiated; verifying payment information.
                </li>
                <li>
                  Check Pending - Check entered online; will process upon
                  receipt.
                </li>
                <li>
                  Processing - Donation successful; payment to artist scheduled
                  for 10 days later.
                </li>
                <li>
                  Failed - Donor’s payment information incomplete or declined.
                </li>
              </ul>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text ?? "processing"].color}>
          {statusTypes[text ?? "processing"].label}
        </Tag>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      width: 125,
      align: "right",
      key: "amount",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: <>
      Fee
      <Tooltip
        overlay={
          <div>
            All donations to Fiscally Sponsored Artists processed by <TheField/> are subject to a <a href="https://www.thefield.org/faq/#What-fees-do-you-charge-fiscally-sponsored-artists?" target="_blank" rel="noreferrer">7% processing fee</a>, with the following exceptions:
            <br/><br/>
            <ul>
            <li>
            {`Monthly recurring donations initiated before April 26, 2023, when a > 7% fee applied`}
            </li>
            <li>
            Specific awards from granting organizations with whom <TheField/> has agreed to apply a different processing fee.
            </li>
          </ul>
          </div>
        }
      >
        <QuestionCircleOutlined
          size={12}
          style={{ padding: 6, minWidth: 20 }}
        />
      </Tooltip>
    </>,
      dataIndex: "fees",
      width: 90,
      align: "right",
      key: "fees",
      render: (text: number, row: any) =>{
        if(dayjs(row.createDate).isBefore('2021-06-05','day')){
          return '*'
        }
        return Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })
      }
    },
    {
      title: "Received Amount",
      dataIndex: "received",
      width: 125,
      align: "right",
      key: "received",
      render: (text: number, row: any) =>{
        if(dayjs(row.createDate).isBefore('2021-06-05','day')){
          return '*'
        }
        return Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })
      }
    },
    {
      title: "",
      width: 80,
      align: "right",
      key: "action",
      render: (_: any, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() =>
                    history.push(`/dashboard/income/detail/${row.id}`)
                  }
                >
                  View Order Detail
                </Menu.Item>
                <Menu.Item
                  hidden={row.status === 'failed'}
                  onClick={() =>
                    history.push(`/dashboard/income/detail/${row.id}/note`)
                  }
                >
                  View thank you note
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
    status: Object.keys(statusTypes).filter((_) => _ !== "failed"),
  });
  const [tableCount, setTableCount] = useState({
    total: 0,
    pageIndex: 0,
    pageTotal: 0,
    fees: 0,
    amount: 0,
    received: 0,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const reset = () => {
    setTableData([]);
    setTableCount({
      total: 0,
      pageIndex: 0,
      pageTotal: 0,
      fees: 0,
      amount: 0,
      received: 0,
    });
  };

  const getList = () => {
    const { range, ...others } = params,
      [startAmount, endAmount] = range || ["", ""],
      _data = {
        type: "donation_to_artist,crowdfunding",
        ...others,
        startAmount,
        endAmount,
      };
    incomeList(_data).then((data) => {
      if (!data?.items?.length) return false;
      setTableData([...tableData, ...data.items]);
      setTableCount({
        ...tableCount,
        ...data?.summary,
        total: data?.meta?.totalItems || 0,
        pageIndex: data?.meta?.currentPage || 0,
        pageTotal: data?.meta?.totalPages || 0,
      });
    });
  };

  useEffect(() => {
    if (params) {
      getList();
    }
    // eslint-disable-next-line
  }, [params]);

  //Modal
  const [show, setShow] = useState(false);
  const [showInKind, setShowInKind] = useState(false);
  const onSetModalShow = (type: string, isShow: boolean) => {
    setShowAction(type);
    if (membershipStatus === "completed") {
      if (type === "Check") {
        setShow(isShow);
      } else if (type === "In-Kind") {
        setShowInKind(isShow);
      }
    } else {
      setMembershipShow(true);
    }
  };

  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/income/export-csv?${queryString.stringify({
        ...others,
        userId,
        tz: dayjs.tz.guess(),
      })}`,
      "_blank"
    );
  };
  return (
    <StatsContainer className="stats-container flex column start align-start">
      <h1>Overview</h1>
      <div className="range-picker-wrap field-form-items">
        <span>Date range</span>
        <DatePicker.RangePicker
          disabledDate={(current) => {
            return current && current > dayjs().endOf("day");
          }}
          dropdownClassName="date-picker-mobile"
          onChange={(e) => {
            const [start, end] = e || ["", ""];
            setVal({
              ...val,
              date: {
                start,
                end,
              },
            });
          }}
        />
      </div>
      {chartMemo}
      <Divider />
      <div className="table-wrap">
        <div className="flex start">
          <Search
            date={false}
            value={params}
            onChange={(e) => {
              reset();
              setParams({
                ...params,
                ...e,
              });
            }}
            // hiddenDropdown={!isMobile}
            action={[
              ...(isMobile
                ? [
                    {
                      text: "Add check donation",
                      onClick: () => {
                        onSetModalShow("Check", true);
                      },
                    },
                    {
                      text: "Add in-kind donation",
                      onClick: () => {
                        onSetModalShow("In-Kind", true);
                      },
                    },
                  ]
                : []),
              "export",
            ]}
            onExport={handleExport}
          />
          {!isMobile && (
            <>
              <Button onClick={() => onSetModalShow("Check", true)}>
                Add check donation
              </Button>
              <Button onClick={() => onSetModalShow("In-Kind", true)}>
                Add in-kind donation
              </Button>
            </>
          )}
        </div>

        {isMobile ? (
          <div className="table-item kind-table-item mb-24">
            <InKindTable
              title="Donation details"
              columns={column}
              tableData={tableData}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  keyword: e,
                });
              }}
              pagination={{
                total,
                current: params.page,
                pageSize: params.limit,
                onChange: (page: number, pageSize: number) => {
                  setParams({
                    ...params,
                    ...{
                      page,
                      limit: pageSize,
                    },
                  });
                },
              }}
              scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
            />
          </div>
        ) : (
          <div className="table-item mb-24">
            <>
              <div className="flex table-search-wrap">
                <span>Donation details</span>
                <Input.Search
                  onSearch={(e) => {
                    setTableData([]);
                    setParams({
                      ...params,
                      keyword: e,
                      page: 1,
                    });
                  }}
                  placeholder="Search"
                />
              </div>
              <VirtualTable
                height={240}
                column={column}
                dataSource={tableData}
                tableCount={tableCount}
                pageChange={() => {
                  setParams({
                    ...params,
                    page: params.page + 1,
                  });
                }}
              />
            </>
          </div>
        )}
        <div className="table-item kind-table-item">
          <InKindTable
            tableData={inKindData}
            onSearch={(e) => {
              setInKindData([]);
              setInParams({
                ...inParams,
                page: 1,
                keyword: e,
              });
            }}
            pagination={{
              total,
              current: inParams.page,
              pageSize: inParams.limit,
              onChange: (page: number, pageSize: number) => {
                setInParams({
                  ...inParams,
                  ...{
                    page,
                    limit: pageSize,
                  },
                });
              },
            }}
            scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
          />
        </div>
      </div>
      <Modal
        visible={membershipShow}
        width={420}
        footer={[
          <Button key="back" onClick={() => setMembershipShow(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            membershipStatus,
            `One More Step to Add ${showAction} Donation`
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            membershipStatus,
            "Fund your artistic projects with tax-deductible donations and grants – and leave the paperwork to us."
          )}
        </p>
      </Modal>

      <InKindModal
        show={showInKind}
        onClose={() => {
          reset();
          setParams({
            page: 1,
            limit: 10,
            keyword: "",
          });
          setShowInKind(false);
        }}
      />
      <GeneralFundModal
        show={show}
        onClose={() => {
          reset();
          setParams({
            page: 1,
            limit: 10,
            keyword: "",
          });
          setShow(false);
        }}
      />
    </StatsContainer>
  );
};

export default Overview;
