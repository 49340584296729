//created by Peggy on 2021/3/11
import React, { useEffect, useState } from "react";

import { Tag, Tooltip } from "antd";
import dayjs from "dayjs";

import CardItem from "@/pages/dashboard/income/CardItem";
import VirtualTable from "@/components/VirtualTable";
import { ReactComponent as NoData } from "@/images/icon_menu_donation.svg";

import { incomeList } from "@/api/donation-api";

import "@/styles/dashboard/income.less";
import styled from "styled-components";
import { statusTypes } from "@/types/enum";
import InKindTable from "@/pages/dashboard/donation/InkindTable";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { NoTranslate } from "@/components/GoogleTranslate";
const FundCardContainer = styled.div``;

const FundCard = ({
  start: startTime = undefined,
  end: endTime = undefined,
}: any) => {
  const {
    store: { isMobile },
  } = useModel(view);
  //table
  const column: any[] = [
    {
      title: "Name",
      dataIndex: "donor",
      key: "name",
      render: (text: string) => {
        return <div title={text} className="fund-card-name"><NoTranslate>{text}</NoTranslate> </div>
      }
    },
    {
      title: "Donation Date",
      dataIndex: "createDate",
      key: "createDate",
      render: (text: string, row: any) => {
        if(row.paymentMethod === 'check'){
          if(row.status === 'check_pending'){
            return 'N/A';
          }
          return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
        }else{
          return dayjs(row.createDate).format("MM/DD/YYYY")
        }
      },
    },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <ul>
                <li>
                  Paid - Donation successful; payment to artist scheduled or
                  complete.
                </li>
                <li>
                  Pending - Donation initiated; verifying payment information.
                </li>
                <li>
                  Check Pending - Check entered online; will process upon
                  receipt.
                </li>
                <li>
                  Processing - Donation successful; payment to artist scheduled
                  for 10 days later.
                </li>
                <li>
                  Failed - Donor’s payment information incomplete or declined.
                </li>
              </ul>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (text: string = "22", _: any) => (
        <Tag color={statusTypes[text ?? "processing"].color}>
          {statusTypes[text ?? "processing"].label}
        </Tag>
      ),
    },
    {
      title: "Received Amount",
      dataIndex: "received",
      align: "center",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 25,
    status:['paid','pending','processing','check_pending']
  });
  const [tableCount, setTableCount] = useState({
    total: 0,
    pageIndex: 0,
    pageTotal: 0,
    fees: 0,
    amount: 0,
    received: 0,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const getList = () => {
    let _data = {
      ...params,
      startTime,
      endTime,
      // startTime: start? dayjs(start).toISOString() : undefined,
      // endTime: end? dayjs(end).toISOString() : undefined
    };
    incomeList(_data).then((data) => {
      setTableData([...tableData, ...data.items]);
      setTableCount({
        ...tableCount,
        ...data?.summary,
        total: data?.meta?.totalItems || 0,
        pageIndex: data?.meta?.currentPage || 0,
        pageTotal: data?.meta?.totalPages || 0,
      });
    });
  };

  useEffect(() => {
    if (params) {
      getList();
    }
    // eslint-disable-next-line
  }, [params, startTime, endTime]);
  return (
    <FundCardContainer className="fund-card-container flex-1">
      <CardItem title={<>Income details</>} url="/dashboard/income/detail">
        {(tableData?.length &&
          (isMobile ? (
            <div className="table-item kind-table-item mb-24">
              <InKindTable
                title=""
                columns={column}
                tableData={tableData}
                onSearch={(e) => {
                  setTableData([]);
                  setParams({
                    ...params,
                    page: 1,
                    keyword: e,
                  });
                }}
                pagination={{
                  total: tableCount,
                  current: params.page,
                  pageSize: params.limit,
                  onChange: (page: number, pageSize: number) => {
                    setParams({
                      ...params,
                      ...{
                        page,
                        limit: pageSize,
                      },
                    });
                  },
                }}
                scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
              />
            </div>
          ) : (
            <div className="table-wrap">
              <VirtualTable
                height={120}
                column={column}
                dataSource={tableData}
                tableCount={tableCount}
                pageChange={() => {
                  setParams({
                    ...params,
                    page: params.page + 1,
                  });
                }}
              />
            </div>
          ))) || (
          <div className="no-data">
            <NoData />
            <h4>No general fundraising</h4>
            <p>
              Apply for Fiscal Sponsorship to enable the fundrasing feature{" "}
            </p>
          </div>
        )}
      </CardItem>
    </FundCardContainer>
  );
};

export default FundCard;
