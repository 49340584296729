//created by Peggy on 2021/3/4
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "@/styles/dashboard/donation.less";
import {
  Breadcrumb,
  Divider,
  Form,
  Input,
  Select,
  Button,
  Radio,
  Menu,
  Dropdown,
} from "antd";
import DatePicker from "@/components/DatePicker";
import { incomeDetail } from "@/api/donation-api";
import { useParams } from "react-router-dom";

// import { ReactComponent as Print } from "@/images/print.svg";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { stateList } from "@/types/enum";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

const InKindDetail = () => {
  const history = useHistory();
  const { id, type } = useParams() as any;
  const [form] = Form.useForm();
  //detail
  const [info, setInfo] = useState<any>("");
  useEffect(() => {
    let _data = {
      id,
    };
    incomeDetail(_data).then((data) => {
      const { donor, date, ...others } = data;
      setInfo({
        ...others,
        ...donor,
        date: dayjs(date),
      });
      form.setFieldsValue({
        ...others,
        ...donor,
        date: dayjs(date),
      });
    });
    // eslint-disable-next-line
  }, [id]);
  return (
    <div className="campaign-detail-container donation-detail-container">
      <Breadcrumb separator=">">
        {type === "crowed" ? (
          <>
            <Breadcrumb.Item href="/dashboard/donation/list">
              Crowdfunding campaigns
            </Breadcrumb.Item>
            <Breadcrumb.Item
              href={`/dashboard/donation/detail/${info?.crowdfunding?.id}`}
            >
              {info?.crowdfunding?.name || "campaigns name"}
            </Breadcrumb.Item>
            <Breadcrumb.Item>Donation by {info.name}</Breadcrumb.Item>
          </>
        ) : (
          <>
            <Breadcrumb.Item href="/dashboard/donation/list">
              General Fundraising
            </Breadcrumb.Item>
            <Breadcrumb.Item>Donation detail</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
      <h1 className="flex">
        Donation by {info.name}
        <span>
          <Dropdown
            trigger={["click"]}
            overlay={() => (
              <>
                <Menu>
                  <Menu.Item>View thank you note</Menu.Item>
                  {/*<Menu.Item>Print receipt</Menu.Item>*/}
                </Menu>
              </>
            )}
          >
            <span className="ant-dropdown-link">
              <FilterMore />
            </span>
          </Dropdown>
        </span>
      </h1>
      <div className="common-form shadow">
        <Form form={form} layout="vertical" name="general-fund">
          <h2>Check information</h2>
          <Form.Item label="Check number" name="checkNumber">
            <Input maxLength={50} placeholder="Check number" />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            normalize={(e) => {
              return Number(e.replace(/,/g, "")).toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
            }}
          >
            <Input prefix="$" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item label="Donation date" name="date">
            <DatePicker />
          </Form.Item>
          <Divider />
          <h2>Donor information</h2>
          <Form.Item label="Donor name" name="name">
            <Input maxLength={50} placeholder="Donor name" />
          </Form.Item>
          <Form.Item label="Donor public name" name="name">
            <Input maxLength={50} placeholder="Donor public name" />
          </Form.Item>
          <Form.Item label="email address" name="email">
            <Input maxLength={50} placeholder="email address" />
          </Form.Item>
          <Form.Item label="address" name="address">
            <Input maxLength={200} placeholder="Address" />
          </Form.Item>
          <Form.Item label="City" name="city">
            <Input placeholder="City" maxLength={30} />
          </Form.Item>
          <div className="flex">
            <Form.Item label="State" name="state" className="select-container">
              <Select placeholder="State">
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Zip Code" name="zipCode">
              <Input placeholder="Zip Code" maxLength={5} />
            </Form.Item>
          </div>
          {type === "general" && (
            <>
              <p className="text-12">
                IMPORTANT: Please make sure to physically mail your check to us at:
                <div translate="no">The Field</div>
                <div translate="no">228 Park Ave S</div>
                <div translate="no">Suite 97217</div>
                <div translate="no">New York, NY 10003-1502.</div>
              </p>
            </>
          )}
          {type === "crowed" && (
            <>
              <h2>Note from donor</h2>
              <Form.Item label="Donor name on check" name="note">
                <Input.TextArea rows={4} placeholder="Donor name on check" />
              </Form.Item>
            </>
          )}
        </Form>
      </div>
      <div className="flex end">
        <Button
          type="primary"
          onClick={() => history.push("/dashboard/message")}
        >
          contact admin
        </Button>
      </div>
    </div>
  );
};

export default InKindDetail;
