import { Button, Steps } from "antd";
import { useHistory } from "react-router-dom";
import icon_event from "@/images/icons/icon_event.svg";

const Empty = () => {
  const history = useHistory();
  const handleSchedule = () => {
    history.push("/dashboard/scheduling/booking");
  };
  return (
    <div className="step-container flex">
      <div className="step-wrap flex column start">
        <img src={icon_event} alt="icon_event" />
        <h2>You have no consultation schedule</h2>
        <p>Schedule a consultation in 5 simple steps.</p>
        <Steps
          current={0}
          progressDot={(dot: any, { index }: any) => (
            <span className="border-item">{index + 1}</span>
          )}
        >
          <Steps.Step
            title="Choose Your Topic"
            description="From building a website to fundraising, our expert staff is ready to help."
          />
          <Steps.Step
            title="Select a Staff Member"
            description="Browse our team of experienced professionals and find the perfect match for your needs."
          />
          <Steps.Step
            title="Schedule"
            description="Pick a date and time that works for you."
          />
          <Steps.Step
            title="Payment"
            description="Secure your booking with our simple and secure payment process."
          />
          <Steps.Step
            title="Complete"
            description="Get ready for your consultation! Keep an eye on your inbox for details about your meeting as it approaches."
          />
        </Steps>

        <Button type="primary" onClick={handleSchedule}>
          Schedule a Consultation meeting
        </Button>
      </div>
    </div>
  );
};

export default Empty;
