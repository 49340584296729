import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "@/images/logo-color.svg";
import { ReactComponent as MenuIcon } from "@/images/icon_hamburger.svg";
import { ReactComponent as MenuClose } from "@/images/icon_menu_close.svg";
import { Drawer, Collapse } from "antd";
import MenuLayout from "./MenuLayout";
import { useModel } from "use-reaction";
import { NavLink, useHistory } from "react-router-dom";

import { user, loginOut } from "@/model/user";
import user_img from "@/images/admin_icon_user.svg";

export const DashboardHeaderMobile = ({onClickMenu}:any) => {
  const [show, setShow] = useState(false);
  const { store, doAction } = useModel(user);
  const history = useHistory();

  const onSignOut = () => {
    doAction(loginOut);
    history.replace("/sign/in");
  };
  // header zindex 9991, drawer zindex 9990, helloSign zindex 9996
  return (
    <header className="flex home-wrap mobile" style={{ zIndex: 991 }}>
      <Logo onClick={(e) => window.open("https://thefield.org", "_blank")} />
      {show ? (
        <MenuClose onClick={(e) => setShow(!show)} />
      ) : (
        <MenuIcon onClick={(e) => {
          onClickMenu?.();
          setShow(!show)
        }} />
      )}

      <Drawer
        visible={show}
        onClose={(e) => setShow(false)}
        className="dashboard-header-mobile-menu"
        placement="top"
        closeIcon={null}
        height="66%"
        contentWrapperStyle={{
          top: "64px",
        }}
        zIndex={990}
      >
        <MenuLayout onTabChg={(e) => setShow(false)} />
        <Collapse expandIconPosition="right">
          <Collapse.Panel
            header={
              <div className="user-wrap flex">
                <span translate="no">
                  <img
                    className="avatar"
                    src={store?.info?.avatar || user_img}
                    alt="user"
                  />
                  {(store?.info?.firstName ?? "") +
                    " " +
                    (store?.info?.lastName ?? "")}{" "}
                </span>
              </div>
            }
            key="1"
          >
            <NavLink to="/dashboard/setting">Account settings</NavLink>
            <NavLink to="/dashboard/transactions">Transaction History</NavLink>
            {store.roles.includes("artist") && (
              <>
                <NavLink to="/dashboard/membership/payable">
                  Payment settings
                </NavLink>
                <NavLink to="/dashboard/document">Documents</NavLink>
              </>
            )}
          </Collapse.Panel>
        </Collapse>
        {!store?.fromAdmin && (
          <span className="btm-menu-item" onClick={onSignOut}>
            Sign out
          </span>
        )}
      </Drawer>
    </header>
  );
};
