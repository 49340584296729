import React, { useEffect } from "react";

import { Button, Divider, Form, Input, message, Select, Tooltip } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";

import { KV, useModel } from "use-reaction";
import { getUser, loginOut, user } from "@/model/user";

import { putArtist } from "@/api/artist-api";
import { changePassword, deactiveAccount } from "@/api/sign-api";

import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import Password from "@/components/Password";
import "@/styles/dashboard/setting.less";

import SelectAndInput from "@/components/Select/SelectAndInput";
import { ethnic } from "@/pages/sign/flow/OtherInfo";

import styled from "styled-components";
import dayjs from "dayjs";
import { stateList } from "@/types/enum";
import { UserRole } from "../myMsg/myMsg";
import { view } from "@/model/view";
import AddRoleButton from "@/pages/dashboard/accountSetting/AddRoleButton";
import useConfirm from "@/components/Modal/Confirm";
import { intersection } from "lodash";
const Container = styled.div``;

const AccountSetting = () => {
  const [form] = Form.useForm();
  const { store, doAction } = useModel(user);
  const {
    store: { isMobile },
  } = useModel(view);
  const onFinish = (values: KV) => {
    const { avatar, address1, address2, background, primaryTag, ...others } = values;
    putArtist({
      ...others,
      background: background?.length ? background : undefined,
      addresses: [address1, address2],
      avatar: avatar?.map((item: any) => item.url).join(","),
      progress: 5,
      primaryTag: Array.isArray(primaryTag) ? primaryTag[0] : primaryTag
    }).then(() => {
      message.success("update success");
      doAction(getUser);
    });
  };

  useEffect(() => {
    const {
      avatar,
      addresses,
      primaryTag,
      background = [],
      tags,
      ...others
    } = store?.info;
    const [address1, address2] = addresses || ["", ""];
    form.setFieldsValue({
      avatar:
        avatar?.split(",")?.map((item: any) => ({
          uid: Math.random(),
          name: item,
          url: item,
          status: "success",
        })) || [],
      tags: tags || [],
      primaryTag: Array.isArray(primaryTag) ? primaryTag : [primaryTag],
      address1: address1 || "",
      address2: address2 || "",
      background: background || [],
      ...others,
    });
    // eslint-disable-next-line
  }, []);

  //password
  const [password] = Form.useForm();
  const onPassword = (values: KV) => {
    const { confirm, ...data } = values;
    changePassword(data).then(() => {
      message.success("update success");
      password.resetFields();
    });
  };

  // deactive account
  const { setShow: setShowDeactive, component: deactiveComp } = useConfirm(
    <div style={{ fontSize: 16 }}>
      <p>You are deactivating {store.info.firstName} {store.info.lastName} {'<'}{store.info.email}{'>'} account. By clicking DEACTIVATE, you will:</p>
      <ol>
        <li>
          Cancel all subscriptions
        </li>
        <br />
        <li>
          Deactivate this account, and
        </li>
        <br />
        <li>
          Unpublish all content from this user, including artist profile, sponsor artist contract, all active events and campaigns
        </li>
        <br />
        <li>
          Still be able to log in again and request to recover your account.
        </li>
        <br />
        <li>
          Back up all content in the database.
        </li>
      </ol>
    </div>, {
    title: 'Are you sure you want to deactive this account?',
    okText: 'Deactive',
    onOk: () => {
      setShowDeactive(false)
      deactiveAccount().then(res => {
        if (res?.success) {
          message.success('deactive succeed!')
          doAction(loginOut)
        }
      })
    }
  })
  useEffect(() => { }, []);

  return (
    <Container className="account-setting-container">
      <h1>Account Settings</h1>
      <section className="profile-section">
        <Form
          layout="vertical"
          size="large"
          name="profile"
          form={form}
          onFinish={onFinish}
        >
          <h2>Profile photo</h2>
          <Form.Item noStyle name="avatar">
            <UploadCrop
              listType="picture-card"
              className="upload-item-wrap"
              showUploadList
              maxCount={1}
            />
          </Form.Item>
          {!store.roles.includes("artist") && <AddRoleButton />}
          <Divider />
          <h2>Account information</h2>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter first Name",
                },
              ]}
            >
              <Input maxLength={30} placeholder="enter first name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Enter last name",
                },
              ]}
            >
              <Input
                maxLength={30}
                autoComplete="off"
                placeholder="enter last name"
              />
            </Form.Item>
          </div>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              name="phone"
              normalize={(e) => {
                return e
                  .replace(/[^\d]*/g, "")
                  .replace(/(\d{3})(?=\d{2})/g, "$1-");
              }}
              rules={[
                {
                  required: true,
                  message: "Enter phone number",
                },
              ]}
              label="Phone Number"
            >
              <Input
                maxLength={15}
                autoComplete="off"
                placeholder="enter phone number"
              />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  type: "email",
                  message: "Enter email address",
                },
              ]}
            >
              <Input disabled placeholder="enter email address" />
            </Form.Item>
          </div>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              label="Company (Optional)"
              name="companyName"
              rules={[
                {
                  whitespace: false,
                  message: "Company (Optional)",
                },
              ]}
            >
              <Input
                autoComplete="off"
                maxLength={30}
                placeholder="Enter company name"
              />
            </Form.Item>
            {store.roles.includes("artist") && (
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    whitespace: false,
                    message: "Title (Optional)",
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  maxLength={30}
                  placeholder="Enter title"
                />
              </Form.Item>
            )}
          </div>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              label="Address"
              name="address1"
              rules={[
                {
                  whitespace: true,
                  message: "Address",
                },
              ]}
            >
              <Input maxLength={30} autoComplete="off" placeholder="Address" />
            </Form.Item>
            <Form.Item
              label="Address 2"
              name="address2"
              rules={[
                {
                  whitespace: true,
                  message: "Address 2",
                },
              ]}
            >
              <Input
                maxLength={30}
                autoComplete="off"
                placeholder="Address 2"
              />
            </Form.Item>
          </div>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "City",
              },
            ]}
          >
            <Input autoComplete="off" maxLength={30} placeholder="City" />
          </Form.Item>
          <div className={isMobile ? "" : "flex"}>
            <Form.Item
              label="Zip code"
              name="zipCode"
              normalize={(e) => {
                return e.replace(/[^\d]*/g, "");
              }}
              rules={[
                {
                  required: false,
                  whitespace: true,
                  message: "Zip code",
                },
              ]}
            >
              <Input
                autoComplete="off"
                maxLength={5}
                placeholder="Enter zip code"
              />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  whitespace: true,
                  message: "State",
                },
              ]}
            >
              <Select
                placeholder="State"
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "account-setting-container"
                  )[0] as any
                }
              >
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {!(store?.roles?.length === 1 && store?.roles[0] === UserRole.DONOR) && <Form.Item
            label="Primary Discipline"
            name="primaryTag"
            dependencies={['tags']}
            rules={[
              {
                whitespace: true,
                message: "Primary Discipline",
                validator: (rule, value, cb) => {
                  const otherTags = form.getFieldValue('tags')
                  if (intersection(otherTags, value).length) {
                    const error = 'Your primary discipline can not be the same as your other disciplines'
                    message.error(error)
                    return Promise.reject(error)
                  }
                  return Promise.resolve()
                }
              },
            ]}
          >
            <SelectAndInput mode="single" />
          </Form.Item>}
          <Form.Item
            name="tags"
            label={
              <div>
                {`${store?.roles?.length === 1 && store?.roles[0] === UserRole.DONOR ? "Disciplines/Interest" : "Other Disciplines"
                  }`}
                <Tooltip
                  className="tooltip-field-item"
                  title="Input your discipline here. If you select other, please be sure to hit enter on your keyboard to save your option. "
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </div>
            }
            rules={[
              {
                type: "array",
                message: "Please select your disciplines",
                validator: (rule, value, cb) => {
                  const primaryTag = form.getFieldValue('primaryTag')
                  if (intersection(primaryTag, value).length) {
                    const error = 'Your primary discipline can not be the same as your other disciplines'
                    return Promise.reject(error)
                  }
                  return Promise.resolve()
                }
              },
            ]}
          >
            <SelectAndInput />
          </Form.Item>
          {store.roles.includes("artist") && (
            <>
              <Form.Item
                label="ethnic background (Optional)"
                name="background"
                className="select-container"
                rules={[
                  {
                    required: false,
                    type: "array",
                    message: "ethnic background",
                  },
                ]}
              >
                <SelectAndInput options={ethnic} placeholderForSelect="Please select all that apply" placeholder="Enter other ethnic background(s) here" />
              </Form.Item>
              <div className={isMobile ? "" : "flex"}>
                <Form.Item
                  label="Pronouns"
                  name="pronouns"
                  rules={[
                    {
                      whitespace: true,
                      message: "pronouns",
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder="Enter pronouns (Optional)"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  label="What year did you begin your artistic career? (Optional)"
                  name="startYear"
                  className="select-container"
                  rules={[
                    {
                      required: false,
                      type: "number",
                      message: "ethnic background",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a year"
                    getPopupContainer={() =>
                      document.getElementsByClassName(
                        "account-setting-container"
                      )[0] as any
                    }
                  >
                    {new Array(dayjs().year() - 1900)
                      .fill(1)
                      .map((_, index) => (
                        <Select.Option key={index} value={1901 + index}>
                          {1901 + index}
                        </Select.Option>
                      ))
                      .reverse()}
                  </Select>
                </Form.Item>
              </div>
            </>
          )}
        </Form>
      </section>
      <p className="flex end ">
        <Button
          className="save-btn"
          type="primary"
          style={isMobile ? { flex: 1 } : {}}
          onClick={() => form.submit()}
        >
          save changes
        </Button>
      </p>
      <Divider />

      <section className="section-changepwd flex between">
        <Form
          layout="vertical"
          size="large"
          name="profile"
          form={password}
          onFinish={onPassword}
        >
          <h2>{store.progress > 0 ? "Change" : "Set up"} password</h2>
          {store.progress > 0 && (
            <Form.Item
              name="currentPassword"
              label="Current password"
              rules={[
                {
                  required: true,
                  message: "Enter current password",
                },
              ]}
            >
              <Password visibilityToggle={false} />
            </Form.Item>
          )}
          <Form.Item
            name="password"
            label="New password"
            rules={[
              {
                required: true,
                message: "Enter new password",
              },
            ]}
          >
            <Password
              visibilityToggle={false}
              placeholder="Enter new password"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="confirm New password"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Password
              visibilityToggle={false}
              placeholder="Enter new password again"
            />
          </Form.Item>
        </Form>
      </section>
      <p className="flex end">
        <Button
          className="save-btn"
          type="primary"
          style={isMobile ? { flex: 1 } : {}}
          onClick={() => password.submit()}
        >
          {store.progress > 0 ? "Change" : "Set up"} password
        </Button>
      </p>
      <Divider />
      <section className="profile-section section-deactive">
        <h2>Deactive Account</h2>
        <p>You will lose access to all your data if you take this action. </p>
        <Button type="default" onClick={e => setShowDeactive(true)}>Deactive account</Button>
        <p style={{marginTop: "1em"}}>If you’d like to permanently delete your account, please contact our admin at support@thefield.org </p>
      </section>
      {deactiveComp}
    </Container>
  );
};
export default AccountSetting;