import { Action } from "use-reaction";
// import { localStorageSet } from "@/utils/storage";

export interface Donation {
  name?: string;
  email?: string;
  amount?: number;
  otherAmount?: number;
  dAmount: number;
  cAmount: number;
  fieldAmount: number;
  cover: boolean;
  feeRate: number;
  frequency: string;
  contribution: boolean;
}

export const donation: Donation = {
  dAmount: 0,
  cAmount: 0,
  fieldAmount: 0,
  cover: false,
  feeRate: 0,
  frequency: "once",
  contribution: false,
};

export const saveDonation: Action<Donation> = async ({ store, payload }) => {
  try {
    // localStorageSet("donation", payload);
    return {
      ...payload,
    };
  } catch (e) {
    return Promise.resolve(e);
  }
};
