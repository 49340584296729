//created by Peggy on 2021/3/1
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { incomeList } from "@/api/donation-api";
import { Dropdown, Input, Tag, Menu, Button, Tooltip } from "antd";

import Search from "@/pages/dashboard/donation/Search";
import GeneralFundModal from "@/pages/dashboard/donation/GeneralFundModal";
import Modal from "@/components/Modal/Info";
import VirtualTable from "@/components/VirtualTable";
import dayjs from "dayjs";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { paymentTypes, statusTypes } from "@/types/enum";
import InKindTable from "@/pages/dashboard/donation/InkindTable";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import queryString from "query-string";
import { user } from "@/model/user";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getSponsor } from "@/api/merbership-api";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";

const GeneralFundContainer = styled.div``;

const GeneralFund = () => {
  const history = useHistory();
  const {
    store: { isMobile },
  } = useModel(view);
  const {
    store: {
      info: { id: userId },
    },
  } = useModel(user);

  const [membershipStatus, setMembershipStatus] = useState("");

  useEffect(() => {
    getSponsor({}).then((data: any) => setMembershipStatus(data?.status));
  }, []);

  const hasMembership = () => {
    return ["completed"].includes(membershipStatus);
  };

  //table
  const column: any[] = [
    {
      title: "Donor",
      dataIndex: "donor",
      key: "name",
      fixed: "left",
      ellipsis: true,
      ...(isMobile ? { width: 100 } : {}),
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Donor email",
      dataIndex: "donorEmail",
      width: 150,
      ellipsis: true,
      key: "email",
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Donation Date",
      width: 120,
      dataIndex: "createDate",
      key: "createDate",
      render: (text: string, row: any) => {
        if(row.paymentMethod === 'check'){
          if(row.status === 'check_pending'){
            return 'N/A';
          }
          return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
        }else{
          return dayjs(row.createDate).format("MM/DD/YYYY")
        }
      },
    },
    // {
    //   title: "received date",
    //   dataIndex: "createDate",
    //   key: "receivedDate",
    //   render: (text: string, row: any) => dayjs(text).format("MM/DD/YYYY"),
    // },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      width: 120,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <ul>
                <li>
                  Paid - Donation successful; payment to artist scheduled or
                  complete.
                </li>
                <li>
                  Pending - Donation initiated; verifying payment information.
                </li>
                <li>
                  Check Pending - Check entered online; will process upon
                  receipt.
                </li>
                <li>
                  Processing - Donation successful; payment to artist scheduled
                  for 10 days later.
                </li>
                <li>
                  Failed - Donor’s payment information incomplete or declined.
                </li>
              </ul>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      width: 110,
      key: "status",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text ?? "processing"].color}>
          {statusTypes[text ?? "processing"].label}
        </Tag>
      ),
    },
    {
      title: "Frequency",
      ...(isMobile ? { width: 120 } : {}),
      dataIndex: "interval",
      render: (text: string) =>
        text === "once"
          ? "One Time"
          : text === "month"
          ? "Recurring - Monthly "
          : "Recurring - Anually",
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      ...(isMobile ? { width: 150 } : {}),
      align: "right",
      key: "amount",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: (
        <>
          Fee
          <Tooltip
            overlay={
              <div>
                All donations to Fiscally Sponsored Artists processed by <TheField/> are subject to a <a href="https://www.thefield.org/faq/#What-fees-do-you-charge-fiscally-sponsored-artists?" target="_blank" rel="noreferrer">7% processing fee</a>, with the following exceptions:
                <br/><br/>
                <ul>
                <li>
                {`Monthly recurring donations initiated before April 26, 2023, when a > 7% fee applied`}
                </li>
                <li>
                Specific awards from granting organizations with whom <TheField/> has agreed to apply a different processing fee.
                </li>
              </ul>
              </div>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "fees",
      ...(isMobile ? { width: 70 } : {}),
      align: "right",
      key: "fees",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Received Amount",
      dataIndex: "received",
      ...(isMobile ? { width: 180 } : {}),
      align: "right",
      key: "received",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "",
      ...(isMobile ? { width: 80 } : {}),
      align: "right",
      key: "action",
      render: (_: any, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() =>
                    history.push(`/dashboard/income/detail/${row.id}`)
                  }
                >
                  View Order Detail
                </Menu.Item>
                <Menu.Item
                  hidden={row.status === 'failed'}
                  onClick={() =>
                    history.push(`/dashboard/income/detail/${row.id}/note`)
                  }
                >
                  View thank you note
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 25,
    keyword: "",
    status: Object.keys(statusTypes).filter((_) => _ !== "failed"),
  });
  const [tableCount, setTableCount] = useState({
    total: 0,
    pageIndex: 0,
    pageTotal: 0,
    fees: 0,
    amount: 0,
    received: 0,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const getList = () => {
    const { range, ...others } = params,
      [startAmount, endAmount] = range || [null, null],
      _data = {
        type: "donation_to_artist",
        ...others,
        startAmount,
        endAmount,
      };
    incomeList(_data).then((data) => {
      if (!data.items?.length) return false;
      setTableData([...tableData, ...data.items]);
      setTableCount({
        ...tableCount,
        ...data?.summary,
        total: data?.meta?.totalItems || 0,
        pageIndex: data?.meta?.currentPage || 0,
        pageTotal: data?.meta?.totalPages || 0,
      });
    });
  };

  useEffect(() => {
    if (params) {
      getList();
    }
    // eslint-disable-next-line
  }, [params]);

  //Modal
  const [show, setShow] = useState(false);
  const [membershipShow, setMembershipShow] = useState(false);

  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/income/export-csv?${queryString.stringify({
        ...others,
        userId,
        type: "donation_to_artist",
        tz: dayjs.tz.guess(),
      })}`,
      "_blank"
    );
  };

  return (
    <GeneralFundContainer className="general-fund-container">
      <h1>General fundraising</h1>
      <div className="table-wrap">
        <div className="flex title">
          <div className="flex">
            <Search
              value={params}
              onChange={(e) => {
                // console.log(e)
                setTableData([]);
                setParams({
                  ...params,
                  ...e,
                  page: 1,
                });
              }}
              // hiddenDropdown
              action={["export"]}
              onExport={handleExport}
            />
          </div>
          <div className="flex">
            <Button
              type="primary"
              onClick={() =>
                hasMembership() ? setShow(true) : setMembershipShow(true)
              }
            >
              Enter Check Donation
            </Button>
          </div>
        </div>
        {isMobile ? (
          <div className="table-item kind-table-item mb-24">
            <InKindTable
              title="Donation details"
              columns={column}
              tableData={tableData}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  keyword: e,
                });
              }}
              pagination={{
                total: tableCount,
                current: params.page,
                pageSize: params.limit,
                simple: isMobile,
                onChange: (page: number, pageSize: number) => {
                  setParams({
                    ...params,
                    ...{
                      page,
                      limit: pageSize,
                    },
                  });
                },
              }}
              scroll={{
                scrollToFirstRowOnChange: true,
                x: "max-content",
                ...(tableData?.length ? { y: "calc(100vh - 650px)" } : {}),
              }}
            />
          </div>
        ) : (
          <div className="table-item">
            <div className="flex table-search-wrap">
              <span>Donation details</span>
              <Input.Search
                onSearch={(e) => {
                  setTableData([]);
                  setParams({
                    ...params,
                    keyword: e,
                    page: 1,
                  });
                }}
                placeholder="Search"
              />
            </div>
            <VirtualTable
              column={column}
              dataSource={tableData}
              tableCount={tableCount}
              pageChange={() => {
                setParams({
                  ...params,
                  page: params.page + 1,
                });
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={membershipShow}
        width={420}
        footer={[
          <Button key="back" onClick={() => setMembershipShow(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            membershipStatus,
            "One More Step to Enter Check Donation"
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            membershipStatus,
            "Fund your artistic projects with tax-deductible donations and grants – and leave the paperwork to us."
          )}
        </p>
      </Modal>
      <GeneralFundModal
        show={show}
        onClose={() => {
          setShow(false);
          setTableData([]);
          setParams({
            page: 1,
            limit: 10,
            keyword: "",
          });
        }}
      />
    </GeneralFundContainer>
  );
};

export default GeneralFund;
