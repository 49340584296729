import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { getInstaAppInfo, getInstaUser } from "../../api/instagram-api";
import { router_insta_auth } from "../../types/enum";
import { localStorageGet, localStorageSet } from "../../utils/storage";
import queryString from "query-string";
const InstagramAuth = () => {
  // get router querys
  const history = useHistory();

  useEffect(() => {
    const queryKV = queryString.parse(history.location.search) as {
      [k: string]: string;
    };
    let { from, code } = queryKV;
    if (from && !code) {
      // here from app
      localStorageSet("inst_ap", null);
      getInstaAppInfo().then((info: any) => {
        const { key } = info;
        info.from = from;
        localStorageSet("inst_ap", info);
        window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${key}&redirect_uri=${window.location.origin}${router_insta_auth}&scope=user_profile,user_media&response_type=code`;
      });
    } else if (code) {
      // redirect from instagram with code
      const info = localStorageGet("inst_ap");
      if (info.waiting) {
        return
      }

      localStorageSet("inst_ap", { ...info, waiting: true });
      code = code.split("#")[0];

      getInstaUser(code)
        .then((instaUser) => {
          const from = info?.from;
          if (from) {
            localStorageSet("inst_user", instaUser);
            history.push(from);
          }
        })
        .catch((err) => {
          message.error((err.message || err).toString());
        });
    } else {
      // error
      message.error("some error, back to homepage");
      history.push("/");
    }
    return
  }, [history])
  return <br />;
};

export default InstagramAuth;
