import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "@/router";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import ScrollTop from "@/ScrollTop";

import "./styles/index.less";
import "./styles/tailwind/output.css";
import { useReaction, useProvider } from "use-reaction";
// this is the entry of App

//AWS S3
const AWS = (window as any)?.AWS;
AWS?.config?.update({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  }),
});
//AWS S3 -- End

function App() {
  useReaction(process.env.NODE_ENV !== "production");
  const StoreProvider = useProvider();
  return (
    <StoreProvider>
      <Router>
        <ScrollTop />
        <AppRouter />
      </Router>
    </StoreProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
