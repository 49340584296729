import { getUser, user } from "@/model/user"
import { useModel } from "use-reaction"
import { ReactComponent as BigEmpty } from '@/images/big_empty.svg'
import { Button, message } from "antd"
import { reactiveAccount } from "@/api/sign-api"
import { useHistory } from "react-router-dom"
import './deactived.less'
import { TheField } from "@/components/GoogleTranslate"
export const DeactivedPage = () => {
    const { store: { info }, doAction } = useModel(user)
    const history = useHistory()
    const handelReactive = () => {
        reactiveAccount().then(res => {
            if (res?.success) {
                message.success('Reactive succeed!', 3)
                doAction(getUser).then(()=> history.push('/'))
            }
        })
    }
    return <div className="deactived-container flex column start align-start">
        <h1>Welcome to <TheField/>, {info.firstName}</h1>
        <div className="contents flex center">
            <div className="box">
                <BigEmpty />
                <h3>Your account has been deactivated</h3>
                <p>Your account has been deactivated.
                    To restore your account, please click on the button below.
                    Please note that all of your content including your artist profile, sponsored artist contract,
                    all active events and campaigns have been unpublished.
                </p>
                <br />
                <Button type="primary" onClick={handelReactive}>Reactivate your account</Button>
            </div>
        </div>
    </div>
}