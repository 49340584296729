//created by Peggy on 2021/3/24
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Breadcrumb,
  Button,
  Divider,
  Form,
  Input,
  Select,
  Radio,
  message,
  Tooltip,
  Checkbox,
} from "antd";
import DatePicker from "@/components/DatePicker";
import { TimePicker } from "@/components/DatePicker";

import { QuestionCircleFilled } from "@ant-design/icons";

import UploadCrop from "@/components/UploadAndCrop/Cropperjs";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { eventGet, eventPut, eventSave } from "@/api/event-api";

import {
  disciplineOptions as options,
  eventTypes,
  timezoneH,
} from "@/types/enum";
import { stateList } from "@/types/enum";
import SelectCheckBox from "@/components/Select/SelectCheckBox";
import { TextEditor } from "@/components/TextEditor/TextEditor";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import { getQuery } from "@/utils/util";

const utc = require("dayjs/plugin/utc");
const timezones = require("dayjs/plugin/timezone");
const dayjs = require("dayjs");
dayjs.extend(utc);
dayjs.extend(timezones);

const disabledDate = (current: any) => {
  return current && current < dayjs().endOf("day");
};

const CreateContainer = styled.div`
  .ant-form-item-required {
    .ant-form-item-label label {
      &:after {
        display: inline-block;
        width: 12px;
        height: 12px;
        line-height: 12px;
        content: "*";
        color: #ff4d4f;
      }
    }
  }
  .ant-form-item.ant-form-image{
    border: none;
  }
  .ant-form-item.ant-form-item-has-error{
    border:1px solid #ff4d4f;
  }
  h4.ant-form-item-required {
    &:after {
      display: inline-block;
      width: 12px;
      height: 12px;
      line-height: 12px;
      margin-left: 4px;
      content: "*";
      color: #ff4d4f;
    }
  }
  .tooltip-field-item {
    color: #b0b0b0;
    margin-right: 4px;
  }
`;
const dateFormat = 'YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'

const Create = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const [form] = Form.useForm();
  const {
    store: { isMobile },
  } = useModel(view);
  const [loading, setLoading] = useState<boolean>(false);
  const [draft, setDraft] = useState(-1);
  const [status, setStatus] = useState("draft");
  const [isRequired, setRequired] = useState(false);
  const [info] = useState<any>({
    frequency: "one_time",
    locationType: "offline",
  });

  const onFinish = (values: any) => {
    let {
      startDate,
      endDate,
      startTime,
      endTime,
      banner,
      thumbnail = [],
      frequency,
      timezone,
      repeatEvery,
      week,
      repeatOn,
      endAfter,
      locationType,
      address,
      city,
      state,
      zipCode,
      eventUrl,
      url,
      label,
      companyName,
      email,
      phone,
      ...others
    } = values,
      _time =
        frequency === "one_time"
          ? {}
          : {
            repeatEvery: repeatEvery + " " + week,
            repeatOn,
            endAfter: endAfter + "",
          },
      _location =
        locationType === "online"
          ? {
            url:
              eventUrl.indexOf("http") === -1 && eventUrl.indexOf("www.") > -1
                ? "https://" + eventUrl
                : eventUrl,
          }
          : {
            address,
            city,
            state,
            zipCode,
          };
    if (isRequired && !(email || phone)) {
      message.error('Please provide email address or Phone Number in contact information section.')
      return
    }

    let _data = {
      ...others,
      thumbnail: thumbnail.map((item: any) => item.url).join(","),
      banner: banner && banner?.map((item: any) => item.url)?.join(","),
      status: "draft",
      time: {
        frequency,
        timezone,
        // startDate,
        // endDate,
        // startTime,
        // endTime,
        startDate: startDate ? startDate.format(dateFormat) : undefined,
        endDate: endDate?.format(dateFormat),
        startTime: startDate ? `${dayjs(startDate).format(dateFormat)}T` + (timeFormat ? startTime.format(timeFormat) : '') : undefined,
        endTime: endDate ? `${dayjs(endDate || startDate).format(dateFormat)} ` + (endTime ? endTime?.format(timeFormat) : '') : undefined,
        ..._time,
      },
      location: {
        type: locationType,
        ..._location,
      },
      button: {
        url: url?.indexOf("http") > -1 || !url ? url : "https://" + url,
        label,
      },
      contactInformation: {
        name: companyName,
        email,
        phone,
      },
    };
    setLoading(true);
    if (id) {
      eventPut(id, _data)
        .then((data) => {
          if (draft === 1) {
            history.push(`/event/${id}/edit`);
          } else {
            history.push(`/dashboard/event`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      eventSave(_data)
        .then((data) => {
          if (draft === 1) {
            history.push(`/event/${data.id}/edit`);
          } else {
            history.push(`/dashboard/event`);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (e: any) => {
    console.log("Failed:", e);
    message.error(e.errorFields[0].errors[0]);
  };

  const handleSubmit = async (val: number) => {
    await setRequired(false);
    await setDraft(val);
    await form.submit();
  };

  const checkRequiredField = ()=>{
    setTimeout(() => {
      const checkField = getQuery('checkField')
      if(checkField === 'true'){
        setRequired(true)
        form.validateFields().catch(e => {
          const parent:any = document.querySelector(`.content-main`)
          const child:any = document.querySelector(`.content-main .ant-form-item-has-error`)
          if(child?.offsetTop){
            parent.scrollTo({
              top: child.offsetTop ,
              behavior: "smooth"
            })
          }
        })
      }  
    }, 500);
  }

  useEffect(() => {
    if (id) {
      eventGet(id).then((data) => {
        const {
          banner,
          button,
          contactInformation: { name, ...otherInfo },
          location: { url, type, ...otherLocation },
          thumbnail,
          time: {
            endDate,
            endTime,
            startDate,
            startTime,
            repeatEvery = "1 week",
            ...otherTime
          },
          ...others
        } = data,
          [repeatE = "1", week = "week"] = repeatEvery?.split(" ");
        form.setFieldsValue({
          ...others,
          ...otherTime,
          ...button,
          ...otherLocation,
          ...otherInfo,
          companyName: name,
          eventUrl: url,
          locationType: type,
          startTime: startTime ? dayjs(startTime) : "",
          endTime: endTime ? dayjs(endTime) : "",
          startDate: startDate ? dayjs(startDate) : "",
          endDate: endDate ? dayjs(endDate) : "",
          repeatEvery: repeatE,
          week,
          showEndItem: !!(endTime || endDate),
          thumbnail: thumbnail ? [thumbnail].map((item: any, index: number) => ({
            uid: index,
            name: item.replace(/[^\d]+/g, ""),
            status: "done",
            url: item,
          })) : [],
          banner:
            (banner &&
              banner?.split(",")?.map((item: any, index: number) => ({
                uid: index,
                name: item.replace(/[^\d]+/g, ""),
                status: "done",
                url: item,
              }))) ||
            "",
        });
        setStatus(data.status);
        checkRequiredField();
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <CreateContainer className="event-create-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/event">Events</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? "Edit" : "Create"} an event</Breadcrumb.Item>
      </Breadcrumb>
      <h1>{id ? "Edit" : "Create"} an event</h1>
      <p>
        Begin by adding your event information in the form below. You will have
        the opportunity to review and edit this content before publishing.
      </p>
      <Form
        layout="vertical"
        name="campaign"
        form={form}
        initialValues={info}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={() => setStatus("draft")}
      >
        <div className="event-form">
          <h2>Event information</h2>
          <Form.Item
            label="event name"
            name="name"
            // allow any character
            // normalize={(e) => {
            //   return e.replace(/[^\w\s\-.]*/g, "");
            // }}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter Event Name",
              },
            ]}
          >
            <Input maxLength={100} placeholder="Enter event name" />
          </Form.Item>
          <Form.Item
            className="textarea-wrap ant-form-item-required"
            label="event description"
            name="description"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Enter event description",
              },
            ]}
          >
            <TextEditor placeholder="Enter event description" />
          </Form.Item>
          <Form.Item
            className="ant-form-item-required"
            label="event type"
            name="type"
            rules={[
              {
                required: isRequired,
                whitespace: false,
                message: "Select an event type",
              },
            ]}
          >
            <Select
              placeholder="Select an event type"
              getPopupContainer={() =>
                document.getElementsByClassName("common-container")[0] as any
              }
            >
              {eventTypes.map((item) => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="ant-form-item-required"
            name="discipline"
            label="event discipline"
            rules={[
              {
                required: isRequired,
                type: "array",
                message: "Select an event discipline",
              },
            ]}
          >
            <SelectCheckBox options={options} />
          </Form.Item>
          <Form.Item
            className="ant-form-item-required"
            label="ticket price"
            name="price"
            rules={[
              {
                required: isRequired,
                message: "Enter ticket price",
              },
            ]}
          >
            <Input style={{ width: "100%" }} placeholder="Enter ticket price" />
          </Form.Item>
          <Divider />
          <h2>Time</h2>
          <Form.Item className="radio-item" name="frequency" label="Frequency">
            <Radio.Group>
              <Radio value="one_time">One-time event</Radio>
              <Radio value="recurring">Recurring event</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.frequency !== curValues.frequency
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("frequency") !== "one_time" && (
                <>
                  <div className="flex start time-option-wrap ">
                    <span>Repeat every</span>
                    <Form.Item noStyle name="repeatEvery">
                      <Select
                        placeholder="Select"
                        size="large"
                        style={{ width: 120 }}
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "common-container"
                          )[0] as any
                        }
                      >
                        {new Array(365).fill(1).map((item, index) => (
                          <Select.Option key={index} value={index - 0 + 1}>
                            {index + 1}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle name="week">
                      <Select
                        placeholder="Select"
                        size="large"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "common-container"
                          )[0] as any
                        }
                      >
                        <Select.Option value="week">Week</Select.Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <div className="flex start time-option-wrap repeat-wrap">
                    <span>Repeat on</span>
                    <Form.Item noStyle name="repeatOn" label="">
                      <Radio.Group defaultValue="a">
                        <Radio.Button value="Monday">Monday</Radio.Button>
                        <Radio.Button value="Tuesday">Tuesday</Radio.Button>
                        <Radio.Button value="Wednesday">Wednesday</Radio.Button>
                        <Radio.Button value="Thursday">Thursday</Radio.Button>
                        <Radio.Button value="Friday">Friday</Radio.Button>
                        <Radio.Button value="Saturday">Saturday</Radio.Button>
                        <Radio.Button value="Sunday">Sunday</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </div>

                  <div className="flex start time-option-wrap">
                    <span>End after</span>
                    <Form.Item
                      noStyle
                      className="time-option-wrap"
                      name="endAfter"
                      label=""
                    >
                      <Select
                        size="large"
                        placeholder="Select"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "common-container"
                          )[0] as any
                        }
                      >
                        {new Array(100).fill(1).map((item, index) => (
                          <Select.Option key={index} value={index - 0 + 1}>
                            {index + 1} occurrences
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </>
              )
            }
          </Form.Item>
          <div className="flex date-item">
            <Form.Item
              label="Start date"
              name="startDate"
              className="ant-form-item-required"
              rules={[
                {
                  required: isRequired,
                  message: "Enter start date",
                },
              ]}
            >
              <DatePicker
                placeholder="Enter start date"
                dropdownClassName="date-picker-wrap"
                disabledDate={disabledDate}
                format={dateFormat}
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "common-container"
                  )[0] as any
                }
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.showEndItem !== curValues.showEndItem ||
                prevValues.frequency !== curValues.frequency
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("showEndItem") && (
                  <Form.Item
                    label="end date"
                    name="endDate"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: false,
                        message: "Select end date",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || !getFieldValue("startDate")) {
                            return Promise.resolve();
                          } else if (
                            dayjs(value) < dayjs(getFieldValue("startDate"))
                          ) {
                            return Promise.reject(
                              new Error(
                                "The start date should be less than the end date !"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select end date"
                      dropdownClassName="date-picker-wrap"
                      disabledDate={disabledDate}
                      format={dateFormat}
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "common-container"
                        )[0] as any
                      }
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </div>
          <Form.Item
            name="timezone"
            label="Select timezone"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                message: "Select timezone",
              },
            ]}
          >
            <Select
              bordered={false}
              showSearch
              placeholder="Select timezone"
              getPopupContainer={() =>
                document.getElementsByClassName(
                  "common-container"
                )[0] as any
              }
            >
              {timezoneH.map((item) => (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex date-item">
            <Form.Item
              label="start time"
              name="startTime"
              className="ant-form-item-required"
              rules={[
                {
                  required: isRequired,
                  message: "Select start name",
                },
              ]}
            >
              <TimePicker
                getPopupContainer={() =>
                  document.getElementsByClassName(
                    "common-container"
                  )[0] as any
                }
                use12Hours
                format="h:mm A"
              />
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.showEndItem !== curValues.showEndItem ||
                prevValues.frequency !== curValues.frequency
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("showEndItem") && (
                  <Form.Item
                    label="end time"
                    name="endTime"
                    className="ant-form-item-required"
                    dependencies={["startDate", "endDate", "startTime"]}
                    rules={[
                      {
                        required: isRequired,
                        message: "Select end Time",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || !getFieldValue("startTime")) {
                            return Promise.resolve();
                          } else if (
                            getFieldValue("startDate") &&
                            value &&
                            getFieldValue("startTime") &&
                            dayjs(
                              (
                                getFieldValue("endDate") ||
                                getFieldValue("startDate")
                              )?.format(dateFormat) +
                              " " +
                              dayjs(value).format(timeFormat)
                            ) <
                            dayjs(
                              getFieldValue("startDate")?.format(
                                dateFormat
                              ) +
                              " " +
                              getFieldValue("startTime")?.format(timeFormat)
                            )
                          ) {
                            return Promise.reject(
                              new Error(
                                "The start time should be less than the end time !"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <TimePicker
                      getPopupContainer={() =>
                        document.getElementsByClassName(
                          "common-container"
                        )[0] as any
                      }
                      use12Hours
                      format="h:mm A"
                    />
                  </Form.Item>
                )
              }
            </Form.Item>
          </div>

          <Form.Item name="showEndItem" valuePropName="checked" noStyle>
            <Checkbox>Show end date & end time</Checkbox>
          </Form.Item>
          <Divider />
          <h2>Location</h2>
          <Form.Item
            className="radio-item"
            name="locationType"
            label="Location type"
          >
            <Radio.Group>
              <Radio value="offline">in person event</Radio>
              <Radio value="online">Online event</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues.locationType !== curValues.locationType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("locationType") === "online" ? (
                <Form.Item
                  label="online host"
                  name="eventUrl"
                  className="ant-form-item-required"
                  rules={[
                    {
                      required: isRequired,
                      // pattern: /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                      message: "need online host",
                    },
                  ]}
                >
                  <Input placeholder="What service is hosting the event online? (e.g Zoom, Twitch, etc)" />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    label="address"
                    name="address"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "Address",
                      },
                    ]}
                  >
                    <Input maxLength={200} placeholder="Address" />
                  </Form.Item>
                  <Form.Item
                    label="City"
                    name="city"
                    className="ant-form-item-required"
                    rules={[
                      {
                        required: isRequired,
                        whitespace: true,
                        message: "City",
                      },
                    ]}
                  >
                    <Input placeholder="City" maxLength={30} />
                  </Form.Item>
                  <div className={`${isMobile ? "" : "flex"}`}>
                    <Form.Item
                      label="State"
                      name="state"
                      className="ant-form-item-required select-container"
                      id="select-container"
                      rules={[
                        {
                          required: isRequired,
                          whitespace: true,
                          message: "State",
                        },
                      ]}
                    >
                      <Select
                        placeholder="State"
                        getPopupContainer={() =>
                          document.getElementsByClassName(
                            "common-container"
                          )[0] as any
                        }
                      >
                        {Object.values(stateList)?.map((item) => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Zip Code"
                      name="zipCode"
                      rules={[
                        {
                          required: false,
                          whitespace: true,
                          message: "Zip Code",
                        },
                      ]}
                    >
                      <Input placeholder="Zip Code" maxLength={5} />
                    </Form.Item>
                  </div>
                </>
              )
            }
          </Form.Item>
          <Divider />
          <h2>Call to action button</h2>
          <Form.Item
            label="button url"
            name="url"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                pattern: /((http(s)?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?/,
                message: "button url",
              },
            ]}
          >
            <Input maxLength={100} placeholder="Insert the url where we can learn more about your event or buy tickets" />
          </Form.Item>
          <Form.Item
            label="button label"
            name="label"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: true,
                message: "button label",
              },
            ]}
          >
            <Input maxLength={30} placeholder={`What is your Call-to-Action? For example "Learn More", "Buy Tickets", etc`} />
          </Form.Item>
          <Divider />
          <h2>Contact information</h2>
          <Form.Item
            label="contact name"
            name="companyName"
            className="ant-form-item-required"
            rules={[
              {
                required: isRequired,
                whitespace: true,
                message: "contact name",
              },
            ]}
          >
            <Input maxLength={30} placeholder="contact name" />
          </Form.Item>
          <div className="contact-item flex">
            <Form.Item
              label="Email address"
              name="email"
              className="ant-form-item-required"
              dependencies={['phone']}
              rules={[
                {
                  // required: false,
                  type: "email",
                  whitespace: true,
                  message: "email invalid",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(isRequired){
                      const phone = getFieldValue('phone');
                      if (!value && !phone) {
                        return Promise.reject();
                      }
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Input placeholder="Email address" />
            </Form.Item>
            <span style={{color: 'gray'}}>OR</span>
            <Form.Item
              label="Phone number"
              name="phone"
              className="ant-form-item-required"
              dependencies={['email']}
              normalize={(e) => {
                return e
                  .replace(/[^\d]*/g, "")
                  .replace(/(\d{3})(?=\d{2})/g, "$1-");
              }}
              rules={[
                {
                  // required: false,
                  message: "Enter phone number",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if(isRequired){
                      const phone = getFieldValue('email');
                      if (!value && !phone) {
                        return Promise.reject();
                      }
                    }
                    return Promise.resolve();
                  },
                })
              ]}
            >
              <Input maxLength={12} placeholder="Enter phone number" />
            </Form.Item>
          </div>
          <h2>Event media</h2>
          <h4 className="ant-form-item-required">
            Cover / Thumbnail photo
            <Tooltip
              className="tooltip-field-item"
              title="The thumbnail photo will appear in the Artist Events/Community Calendar page."
            >
              <QuestionCircleFilled />
            </Tooltip>
          </h4>
          <Form.Item
            label=""
            name="thumbnail"
            className="ant-form-image"
            rules={[
              {
                required: isRequired,
                type: "array",
                message: "Upload a thumbnail",
              },
            ]}
          >
            <UploadCrop
              folder="event"
              listType="picture-card"
              className="upload-item-wrap cover-image"
              showUploadList
              maxCount={1}
            />
          </Form.Item>
          <h4>
            Banner image{" "}
            <Tooltip
              className="tooltip-field-item"
              title="For best results, banner images should be free of text. If no
              banner image is uploaded, the thumbnail will be used"
            >
              <QuestionCircleFilled />
            </Tooltip>
          </h4>
          <Form.Item label="" name="banner" className="ant-form-image">
            <UploadCrop
              folder="event"
              scale={144 / 58}
              listType="picture-card"
              className="upload-item-wrap cover-image"
              showUploadList
              maxCount={1}
            />
          </Form.Item>
          {/*<h4>Featured photos</h4>*/}
          {/*<Form.Item*/}
          {/*  label=""*/}
          {/*  name="photos"*/}
          {/*  noStyle*/}
          {/*  rules={[*/}
          {/*    {*/}
          {/*      required: false,*/}
          {/*      type: "array",*/}
          {/*      message: "Project photos",*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*>*/}
          {/*  <UploadCrop*/}
          {/*    crop={false}*/}
          {/*    listType="picture"*/}
          {/*    className="upload-item-wrap"*/}
          {/*    showUploadList*/}
          {/*    maxCount={15}*/}
          {/*  >*/}
          {/*    <div className="children-upload">*/}
          {/*      <p className="ant-upload-drag-icon">*/}
          {/*        <CloudUploadOutlined />*/}
          {/*      </p>*/}
          {/*      <p className="ant-upload-text">*/}
          {/*        Click or drag file to this area to upload*/}
          {/*      </p>*/}
          {/*      <p className="ant-upload-hint">*/}
          {/*        Please upload 1 file at a time. Strictly prohibit from*/}
          {/*        uploading company data or other band files*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*  </UploadCrop>*/}
          {/*</Form.Item>*/}
        </div>
        <p className={`flex ${isMobile ? "between" : "end"} btn`}>
          <Button loading={loading} onClick={() => handleSubmit(0)}>
            Save as draft
          </Button>
          <Button
            loading={loading}
            type="primary"
            disabled={status === "live"}
            onClick={() => handleSubmit(1)}
          >
            Preview
          </Button>
        </p>
      </Form>
    </CreateContainer>
  );
};

export default Create;
