//created by Peggy on 2021/1/25
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  Divider,
  Input,
  message,
  Radio,
  Tooltip,
} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

import { useModel } from "use-reaction";
import { donation, saveDonation as saveDonationStore } from "@/model/donation";

import { saveDonation } from "@/api/artist-api";
import TotalCard from "@/pages/homepage/donation/TotalCard";
import thefeildonation from "@/images/thefeild_donation.svg";
import { localStorageGet } from "@/utils/storage";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from "@/components/DatePicker";
import GoogleReCaptcha from 'react-google-recaptcha';
import { getEnv } from "@/libs/cfg";
import GAEvent from "@/utils/GAEvent";
import { NoTranslate, TheField } from "@/components/GoogleTranslate";
import { user } from "@/model/user";
import IsThisCorrect from "./IsThisIncorrect";

interface Props {
  artistInfo?: any;
  onNext?: (url?: string) => void;
  _id?: string; // use this value if pass by props, otherwise, use url-param
  _type?: string; // use this value if pass by props, otherwise, use url-param
  pickDate?: (d: Dayjs) => void;
  hideOnetime?: boolean;
}

const role: any = {
  general: "donation_to_artist",
  crowd: "crowdfunding",
  field: "donation_to_the_field",
};

const minAmount = 10;
const DonationInput = ({
  onNext,
  artistInfo = {},
  _id = "0",
  _type = "field",
  pickDate,
  hideOnetime,
}: Props) => {
  const { doAction } = useModel(donation);
  const { email: donorEmail } = localStorageGet("info") || "";
  let { id = _id, type = _type } = useParams() as any;
  const [required, setRequired] = useState<any[]>([]);
  const {store:userStore} = useModel(user);
  const [detail, setDetail] = useState<any>({
    dAmount: "",
    cAmount: "",
    fieldAmount: "",
    cover: false,
    frequency: pickDate ? "day" : "once",
    contribution: false,
    email: donorEmail,
    name: userStore.info.firstName,
    lastName : userStore.info.lastName,
    message: "",
  });
  const [captchaToken, setCaptchaToken] = useState<string>('');

  const onChange = useCallback((ctoken: string | null) => {
    if (ctoken) {
      setCaptchaToken(ctoken );
    }
  }, [])
  const handleSave = async () => {
    let {
      contribution,
      dAmount,
      cAmount,
      fieldAmount,
      name,
      email,
      date,
      ...others
    } = detail;

    setRequired([]);

    let _temp = [];

    if (!dAmount && cAmount < minAmount) {
      _temp.push("amount");
    }

    if (!name) {
      _temp.push("name");
    }

    if (!date) {
      _temp.push("date");
    }

    if (
      !/^[a-zA-Z0-9_\\-\\+\\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)
    ) {
      _temp.push("email");
    }
    setRequired(_temp);

    if (!dAmount && cAmount < minAmount) {
      message.error(`Set minimum donation = $${minAmount}`);
      return false;
    }

    if (pickDate && !date) {
      message.error("Choose date");
      return false;
    }

    if (!name) {
      message.error("Enter your public name");
      return false;
    }

    if (
      !/^[a-zA-Z0-9_\\-\\+\\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)
    ) {
      message.error("Enter valid email");
      return false;
    }

    let _data = {
      ...others,
      name,
      email,
      type: role[type],
      artistId: type === "general" ? id - 0 : 0,
      crowdfundingId: type === "crowd" ? id - 0 : 0,
      fees:
        Number(
          ((artistInfo?.artist?.feeRate ||
            getEnv("DONATION_TO_FIELD_FEE") ||
            "0") as number) * (dAmount || cAmount || 0)
        ).toFixed(2) + "",
      amount: dAmount || cAmount,
      captchaToken,
      fieldAmount: contribution ? (fieldAmount as string) : "0",
    };

    GAEvent("add_to_cart", {
      items: [{
        item_name: artistInfo?.name,       // Name or ID is required.
        item_id: id,
        price: dAmount || cAmount,
        item_category: others.frequency,
        item_category2: fieldAmount,
        quantity: 1
      }],
    })

    saveDonation(_data).then((data) => {
      pickDate && pickDate(date);
      onNext && onNext(`/home/donation/${type}/${id}/1/${data.id}`);
    });
  };
  // useEffect(() => {
  //   if (store) {
  //     setDetail({
  //       ...detail,
  //       ...store,
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, []);
  useEffect(() => {
    doAction(saveDonationStore, {
      ...detail,
      feeRate:
        (artistInfo?.artist?.feeRate as number) ||
        getEnv("DONATION_TO_FIELD_FEE") ||
        "",
    });
    // eslint-disable-next-line
  }, [detail, artistInfo.id]);

  useEffect(() => {
    return () => {
      setCaptchaToken('');
    }
  }, [])
  return (
    <>
      <div className="donation-items-container">
        <div className="card-wrap">
          <div className="flex column start align-start">
            <h2>Donation amount</h2>
            <Radio.Group
              value={detail.dAmount}
              size="large"
              onChange={(e) =>
                setDetail({
                  ...detail,
                  cAmount: "",
                  dAmount: e.target.value,
                })
              }
            >
              <Radio.Button value="25">$25</Radio.Button>
              <Radio.Button value="50">$50</Radio.Button>
              <Radio.Button value="100">$100</Radio.Button>
              <Radio.Button value="250">$250</Radio.Button>
              <Radio.Button value="500">$500</Radio.Button>
              <Radio.Button value="1000">$1000</Radio.Button>
            </Radio.Group>

            <div
              className={`field-form-items ${
                required.includes("amount") ? "field-error-item" : ""
              }`}
            >
              <span>Custom Amount</span>
              <Input
                size="large"
                placeholder="Enter custom amount"
                prefix="$"
                maxLength={8}
                value={detail.cAmount}
                onChange={(e) => {
                  const _temp = JSON.parse(JSON.stringify(required));
                  _temp.splice(
                    _temp.findIndex((item: any) => item === "amount"),
                    1
                  );
                  setRequired(_temp);
                  setDetail({
                    ...detail,
                    dAmount: "",
                    cAmount: e.target.value.replace(/[^\d\\.]+/g, ""),
                  });
                }}
              />
            </div>
            <Checkbox
              checked={detail.cover}
              onChange={(e) => {
                setDetail({
                  ...detail,
                  cover: e.target.checked,
                });
              }}
            >
              I’d like to cover the fees for my donation
            </Checkbox>
          </div>
          <Divider />
          <div className="flex column start align-start">
            <h2>Donation frequency</h2>
            <Radio.Group
              defaultValue={detail.frequency}
              size="large"
              className="frequency-radio"
              onChange={(e) => {
                setDetail({
                  ...detail,
                  frequency: e.target.value,
                });
              }}
            >
              {!hideOnetime && (
                <Radio.Button value="once">One time</Radio.Button>
              )}
              {type !== "crowd" && (
                <Radio.Button value="day">Recurring daily</Radio.Button>
              )}
            </Radio.Group>
            {pickDate && (
              <div
                className={`field-form-items ${
                  required.includes("date") ? "field-error-item" : ""
                }`}
              >
                <span>Recurring Date</span>
                <DatePicker
                  disabledDate={(d) =>
                    d.isBefore(dayjs()) || d.isAfter(dayjs().add(1, "month"))
                  }
                  format="MM/DD/YYYY"
                  onChange={(e) => {
                    const _temp = JSON.parse(JSON.stringify(required));
                    _temp.splice(
                      _temp.findIndex((item: any) => item === "date"),
                      1
                    );
                    setRequired(_temp);
                    setDetail({
                      ...detail,
                      date: e,
                    });
                  }}
                />
              </div>
            )}
            {detail.frequency === "month" && (
              <span className="txt_12">
                {pickDate
                  ? `
                Your donation will be made on the recurring date set above and on the same date 
                of each following month using the payment method selected. 
                You may cancel or change this amount at any time
                `
                  : `
                Your donation will be made today and on the same date of each
                following month using the payment method selected. You may
                cancel or change this amount at any time
                `}
              </span>
            )}
          </div>
          <Divider />
          {type !== "field" && (
            <>
              <div className="flex column start align-start">
                <h2>
                  Contribute to <TheField/>
                  <Tooltip title="This contribution is in addition    toyour donation. Contributions to The   Field help us serve and supportindependent performing artists andtheir companies. Thank you!">
                    <InfoCircleFilled
                      style={{ fontSize: 16, color: "#696969", marginLeft: 4 }}
                    />
                  </Tooltip>
                </h2>
                <Radio.Group
                  value={detail.contribution}
                  className="frequency-radio"
                  size="large"
                  onChange={(e) => {
                    setDetail({
                      ...detail,
                      fieldAmount: "",
                      contribution: e.target.value,
                    });
                  }}
                >
                  <Radio.Button value={false}>No, thanks</Radio.Button>
                  <Radio.Button value={true}>Yes, I’d love to</Radio.Button>
                </Radio.Group>

                {detail.contribution && (
                  <div className="field-form-items">
                    <span>Amount </span>
                    <Input
                      size="large"
                      placeholder="Enter amount"
                      prefix="$"
                      value={detail.fieldAmount}
                      onChange={(e) => {
                        setDetail({
                          ...detail,
                          fieldAmount: e.target.value.replace(/[^\d\\.]+/g, ""),
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <Divider />
            </>
          )}

          <h2>Message to <NoTranslate>{type === "field" ? "The Field" : "artist"}</NoTranslate></h2>
          <IsThisCorrect isStep2/>
          <div
            className={`field-form-items field-required ${
              required.includes("name") ? "field-error-item" : ""
            }`}
          >
            <span>
              First name
              <Tooltip title="Please state how you would like to be publicly acknowledged for your gift. If you prefer to be acknowledged anonymously, write Anonymous. ">
                <InfoCircleFilled />
              </Tooltip>
            </span>
            <Input
              disabled={!!userStore.token}
              size="large"
              placeholder="Enter first name"
              value={detail.name}
              onChange={(e) => {
                const _temp = JSON.parse(JSON.stringify(required));
                _temp.splice(
                  _temp.findIndex((item: any) => item === "name"),
                  1
                );
                setRequired(_temp);
                setDetail({
                  ...detail,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div
            className={`field-form-items field-required ${
              required.includes("lastName") ? "field-error-item" : ""
            }`}
          >
            <span>Last name</span>
            <Input
              disabled={!!userStore.token}
              size="large"
              placeholder="Enter last name"
              value={detail.lastName}
              onChange={(e) => {
                const _temp = JSON.parse(JSON.stringify(required));
                _temp.splice(
                  _temp.findIndex((item: any) => item === "lastName"),
                  1
                );
                setRequired(_temp);
                setDetail({
                  ...detail,
                  lastName: e.target.value,
                });
              }}
            />
          </div>
          <div
            className={`field-form-items field-required ${
              required.includes("email") ? "field-error-item" : ""
            }`}
          >
            <span>Donor email</span>
            <Input
              disabled={!!userStore.token}
              size="large"
              placeholder="Enter email"
              value={detail.email || donorEmail}
              onChange={(e) => {
                const _temp = JSON.parse(JSON.stringify(required));
                _temp.splice(
                  _temp.findIndex((item: any) => item === "email"),
                  1
                );
                setRequired(_temp);
                setDetail({
                  ...detail,
                  email: e.target.value,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Message</span>
            <Input.TextArea
              size="large"
              placeholder="Write your message"
              value={detail.message}
              onChange={(e) => {
                setDetail({
                  ...detail,
                  message: e.target.value,
                });
              }}
            />
          </div>
        </div>
        {(type !== "field" && (
          <p className="desktop card-description">
            * <NoTranslate>{artistInfo?.artist?.displayName}</NoTranslate> is a sponsored artist with The
            Performance Zone Inc (dba The Field), a not-for-profit, tax-exempt,
            501(c)(3) organization serving the performing arts community.
            Contributions to <TheField/> earmarked for{" "}
            <NoTranslate>{artistInfo?.artist?.displayName}</NoTranslate> are tax-deductible to the extent
            allowed by law. For more information about <TheField/>, or for our
            national charities registration, contact: <TheField/>, <NoTranslate>228 Park Ave S, Suite 97217 New York, NY 10003-1502</NoTranslate>, phone: 212-691-6969. A copy of our
            latest financial report may be obtained from <TheField/> or from the
            Office of Attorney General, Charities Bureau, 120 Broadway, New
            York, NY 10271.
          </p>
        )) || (
          <p className="desktop card-description">
            * The Performance Zone Inc (dba The Field) is a nationally
            registered not-for-profit, tax-exempt, 501(c)(3) organization
            serving the performing and media arts community. Your gift is
            tax-deductible to the extent allowed by law. A copy of our latest
            financial report may be obtained from <TheField/> or from the Office
            of the Attorney General, Charities Bureau, 120 Broadway, New York,
            NY 10271.
            <br />
            Many companies have matching gift programs that will double your
            contribution. If your employer has a matching gift program, please
            contact corporate administration for a matching gift form.
            <br />
            <TheField/> has a 2021 Gold Seal of Transparency certified by
            Guidestar.
            <br />
            <TheField/> is a Four Star Charity as rated by Charity Navigator.
            <br />
            <img style={{ marginTop: 8 }} src={thefeildonation} alt="" />
          </p>
        )}
      </div>
      <TotalCard artistInfo={artistInfo} type={type}>
        <>
          <GoogleReCaptcha style={{ marginBottom: "8px" }} sitekey={'6LfxdIslAAAAANU5TuYnaE38do2ANkXPcs_g5ry4'} onChange={onChange} />
          <Button type="primary" block onClick={handleSave}>
            Continue
          </Button>
        </>
      </TotalCard>

      {(type !== "field" && (
        <p className="mobile card-description">
          * <NoTranslate>{artistInfo?.artist?.displayName}</NoTranslate> is a sponsored artist with The
          Performance Zone Inc (dba The Field), a not-for-profit, tax-exempt,
          501(c)(3) organization serving the performing arts community.
          Contributions to <TheField/> earmarked for{" "}
          <NoTranslate>{artistInfo?.artist?.displayName}</NoTranslate> are tax-deductible to the extent
          allowed by law. For more information about <TheField/>, or for our
          national charities registration, contact: <TheField/>, 228 Park Ave S, Suite 97217 New York, NY 10003-1502, phone: 212-691-6969. A copy of our latest
          financial report may be obtained from <TheField/> or from the Office of
          Attorney General, Charities Bureau, 120 Broadway, New York, NY 10271.
        </p>
      )) || (
        <p className="mobile card-description">
          * The Performance Zone Inc (dba The Field) is a nationally registered
          not-for-profit, tax-exempt, 501(c)(3) organization serving the
          performing and media arts community. Your gift is tax-deductible to
          the extent allowed by law. A copy of our latest financial report may
          be obtained from <TheField/> or from the Office of the Attorney General,
          Charities Bureau, 120 Broadway, New York, NY 10271.
          <br />
          Many companies have matching gift programs that will double your
          contribution. If your employer has a matching gift program, please
          contact corporate administration for a matching gift form.
          <br />
          <TheField/> is a Four Star Charity as rated by Charity Navigator.
          <br />
          <img style={{ marginTop: 8 }} src={thefeildonation} alt="" />
        </p>
      )}
    </>
  );
};

export default DonationInput;
