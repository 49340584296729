import React, { useEffect } from "react";
import { useBookingContext } from "../data/BookingContext";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { localStorageGet } from "@/utils/storage";
import { Button, Spin } from "antd";
import { useRequest } from "ahooks";
import {
  addConsultation,
  // getBookingUrl,
  updateConsultationBook,
} from "@/adminApp/apis/consultation-api";
import { useUrlState } from "@/utils/util";
import {
  ConsultationStatus,
  getRate,
} from "@/adminApp/pages/admin_consultation/scheduling/shared";

const Scheduling = () => {
  const [, setParams] = useUrlState({ id: undefined });
  const { state } = useBookingContext();
  const [height, setHeight] = React.useState("");
  const [eventTypeViewed, setEventTypeViewed] = React.useState(false);
  const userInfo = localStorageGet("info");
  const createConsultationRequest = useRequest(addConsultation, {
    manual: true,
  });
  const bookRequest = useRequest(updateConsultationBook, {
    manual: true,
  });
  // useRequest(() => getBookingUrl(state.eventTypeUri), {
  //   ready: !!state.eventTypeUri && !state.bookingUrl && state.step > 1,
  //   refreshDeps: [state.eventTypeUri],
  //   onSuccess: (data) => {
  //     if (data.resource?.scheduling_links?.[0]?.booking_url) {
  //       state.bookingUrl = data.resource.scheduling_links[0].booking_url;
  //     } else {
  //       const service = state.staff?.serviceRates?.find(
  //         (item: any) => item?.uri === state.eventTypeUri
  //       );
  //       state.bookingUrl = service.calendlyEventLink;
  //     }
  //   },
  // });
  const service = state.staff?.serviceRates?.find(
    (item: any) => item?.uri === state.eventTypeUri
  );
  useEffect(() => {
    if (state.eventTypeUri) {
      state.bookingUrl = service.calendlyEventLink;
    } else {
      state.bookingUrl = "";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.eventTypeUri]);
  useCalendlyEventListener({
    onEventTypeViewed: () => setEventTypeViewed(true),
    onDateAndTimeSelected: async () => {
      if (state.consultation?.id === undefined) {
        try {
          const result = await createConsultationRequest.runAsync({
            name: "",
            staffId: state.staff.id,
            artistUserId: userInfo.id,
            topic: state.topic,
            eventTypeUri: state.eventTypeUri,
            bookUrl: state.bookingUrl,
            description: state.description,
          });
          if (result?.id) {
            state.step += 1;
            state.consultation = result;
            setParams({ id: result.id });
          }
        } catch (error) {
          state.step -= 1;
          state.eventTypeUri = "";
        }
      }
    },
    onEventScheduled: async (message) => {
      const result = await bookRequest.runAsync({
        id: state.consultation.id,
        eventUri: message.data.payload.event.uri,
        invitee: message.data.payload.invitee.uri,
      });
      state.step += 1;
      state.consultation = result;
      setParams({ id: result.id });
    },
    onPageHeightResize: (e) => setHeight(e.data.payload.height),
  });
  useEffect(() => {
    setTimeout(() => {
      setEventTypeViewed(true);
    }, 3000);
  }, []);
  return (
    <div className="steps-content">
      <div className="steps-content-head z-10 relative !h-auto py-5">
        <div className="steps-content-head-title">
          <SchedulingHeader />
        </div>
        <div className="steps-action">
          {state.consultation?.status !==
            ConsultationStatus.PENDING_SCHEDULE && (
            <Button style={{ margin: "0 8px" }} onClick={() => state.step--}>
              Previous
            </Button>
          )}
        </div>
      </div>
      <Spin
        spinning={!state.bookingUrl || !eventTypeViewed}
        className="bg-white !max-h-none min-h-96"
      >
        <div
          style={{ height }}
          className={`${
            eventTypeViewed ? "-mt-10" : "mt-10"
          } flex items-center min-h-96 duration-200 overflow-hidden`}
        >
          {state.bookingUrl && (
            <InlineWidget
              styles={{ height, width: "100%", overflow: "visible" }}
              pageSettings={{}}
              url={state.bookingUrl}
              prefill={{
                name: `${userInfo?.firstName} ${userInfo?.lastName}`,
                email: userInfo?.email,
              }}
            />
          )}
        </div>
      </Spin>
      {createConsultationRequest.loading && (
        <Spin className="global-spin-container"></Spin>
      )}
    </div>
  );
};

export const SchedulingHeader = () => {
  const { state } = useBookingContext();
  const staff = state.staff;
  if (!state.staff || !state.eventTypeUri) {
    return null;
  }
  const service = state.staff?.serviceRates?.find(
    (item: any) => item?.uri === state.eventTypeUri
  );
  return (
    <>{`You’re scheduling a ${service?.duration}-minute meeting with ${
      staff?.name
    } for $${getRate(service)}`}</>
  );
};

export default Scheduling;
