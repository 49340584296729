import { Button, Form, Input, Tooltip, message, Spin } from "antd";
import { useBookingContext } from "../data/BookingContext";
import classNames from "classnames";
import { useRequest } from "ahooks";
import { getConsultationTopics } from "@/adminApp/apis/consultation-api";

const PickTopic = () => {
  const { state } = useBookingContext();
  const topicsRequest = useRequest(getConsultationTopics);
  const [form] = Form.useForm();
  const topics = topicsRequest?.data?.list;
  return (
    <div className="steps-content">
      <div className="steps-content-head">
        <div className="steps-content-head-title">
          What do you want to discuss?
        </div>
        <div className="steps-action">
          {state.step > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => state.step--}>
              Previous
            </Button>
          )}
          <Button
            type="primary"
            onClick={async () => {
              try {
                await form.validateFields();
                if (!state.topic) {
                  throw new Error("Please select a topic");
                }
                state.step++;
              } catch (error: any) {
                message.error(
                  error?.errorFields?.[0]?.errors?.[0] ??
                    error?.message ??
                    error ??
                    "Internal Error"
                );
              }
            }}
          >
            Next
          </Button>
        </div>
      </div>
      <Spin spinning={topicsRequest.loading}>
        <Form layout="vertical" form={form}>
          <div className="topic-list step-content-container">
            {topics?.map((item: any) => {
              return (
                <div
                  className={classNames("topic-card", {
                    selected: state.topic === item.title,
                  })}
                  key={item.title}
                  onClick={() => {
                    state.topic = item.title;
                    setTimeout(() => {
                      document
                        .querySelector(".please-specify")
                        ?.scrollIntoView({
                          behavior: "smooth",
                        });
                    }, 0);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    overlayInnerStyle={{
                      width: item.description.length > 400 ? 500 : undefined,
                      padding: 16,
                    }}
                    title={item.description}
                  >
                    <div className="topic-card-content">{item.title}</div>
                  </Tooltip>
                </div>
              );
            })}
            <Form.Item
              label="Please specify"
              name="description"
              initialValue={state.description}
              style={{ margin: 15, width: "100%" }}
              hidden={topicsRequest.loading}
              className="please-specify"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Please specify what you want to discuss",
                },
              ]}
            >
              <Input
                placeholder="Please specify what you want to discuss"
                maxLength={2000}
                value={state.description}
                onChange={(e) => (state.description = e.target.value)}
              />
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default PickTopic;
