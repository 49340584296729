//created by Pegg on 2021/1/27
import React, { useEffect } from "react";
import SignLayout from "@/components/layouts/SignLayout";
import styled from "styled-components";
import success_img from "@/images/icons/icon_donation.svg";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { getUser, user } from "@/model/user";
import { useModel } from "use-reaction";

const SignSuccessContainer = styled.div``;

const SignSuccess = () => {
  const { store, doAction } = useModel(user);

  useEffect(() => {
    doAction(getUser);
    // eslint-disable-next-line
  }, []);
  return (
    <SignLayout>
      <SignSuccessContainer className="sign-success-container flex column start">
        <img src={success_img} alt="" />
        <h1>
          Congratulations!
          <br />
          You’ve successfully signed up.
        </h1>
        {/*<h4>*/}
        {/*  We sent you a verification email and you can verify your account at*/}
        {/*  any time. The next step is to build your artist profile.*/}
        {/*</h4>*/}
        {(store.roles?.includes("artist") && (
          <>
            <Link to={`/dashboard/artist`}>
              <Button type="primary">Build my Artist Profile</Button>
            </Link>
            <Link to="/dashboard/income">Go to Dashboard</Link>
          </>
        )) || <Link to="/dashboard/history">Go to Dashboard</Link>}
      </SignSuccessContainer>
    </SignLayout>
  );
};

export default SignSuccess;
