import React from "react";
import { Redirect } from "react-router-dom";
import { useModel } from "use-reaction";
import { user } from "@/model/user";

const Home = () => {
  const {
    store: { token },
  } = useModel(user);
  return <Redirect to={token ? "/dashboard/income" : "/sign/in"} />;
  // return (
  //   <HomeLayout>
  //     <div className="home-container">
  //       <div
  //         style={{ marginTop: 150, fontWeight: 500, fontSize: 24 }}
  //         className="flex column"
  //       >
  //         <Link to="/contribute-to-an-artist">artist list</Link>
  //         <Link to="/home/donation/field/0/0">the field donation</Link>
  //         <Link to="/explore-artist-community-events">events discover</Link>
  //         <Link to="/grants-and-opportunities">grants discover</Link>
  //       </div>
  //     </div>
  //   </HomeLayout>
  // )
};

export default Home;
