//created by Peggy on 2021/3/24
import React, { useState } from "react";

import { Button, Steps } from "antd";
import { Link, useHistory } from "react-router-dom";

import { getSponsor } from "@/api/merbership-api";
import Modal from "@/components/Modal/Info";
import icon_event from "@/images/icons/icon_event.svg";
import styled from "styled-components";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
const StepContainer = styled.div`
  width: 580px;
  text-align: center;
  .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    font-size: 12px;
  }
  .ant-steps-dot .ant-steps-item-content {
    width: 148px;
  }
`;

const Step = () => {
  const history = useHistory();
  const [flag, setFlag] = useState(false);
  const { store: { isMobile } } = useModel(view)
  const handleCheck = () => {
    let _data = {};
    getSponsor(_data).then((data: any) => {
      history.push("/dashboard/event/create"); // allow create event anyway
      // if (data.status === "completed") {
      //   history.push("/dashboard/event/create");
      // } else {
      //   setFlag(true);
      // }
    });
  };
  return (
    <StepContainer className="step-container flex">
      <div className="step-wrap flex column start">
        <img src={icon_event} alt="icon_event" />
        <h2>You have no events</h2>
        <p>Post an event with just 3 simple steps</p>
        {
          !isMobile &&
          <Steps
            current={0}
            progressDot={(dot: any, { index }: any) => (
              <span className="border-item">{index + 1}</span>
            )}
          >
            <Steps.Step title="Draft" description="Create and edit your event " />
            <Steps.Step
              title="Preview"
              description="Make sure everything looks good"
            />
            <Steps.Step
              title="Publish"
              description="Publish and share your event"
            />
          </Steps>
        }

        <Button type="primary" onClick={handleCheck}>
          create now
        </Button>
      </div>
      <Modal
        visible={flag}
        width={420}
        footer={[
          <Button key="back" onClick={() => setFlag(false)}>
            LATER
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            Apply now
          </Button>,
        ]}
      >
        <h2>One more step to set up your event </h2>
        <p>
          In order to set up an event, you will have to apply for Fiscal
          Sponsorship
        </p>
      </Modal>
    </StepContainer>
  );
};

export default Step;
