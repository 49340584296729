
const GAEvent = (event: string, ecommerce: any) => {
  const wd = window as any;
  wd.dataLayer.push({ ecommerce: null });
  wd.dataLayer.push({
    event,
    ecommerce,
  })
}

export default GAEvent;