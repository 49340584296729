//created by Peggy on 2021/5/22
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Dropdown, Input, Menu, Select, Table } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import DatePicker from "@/components/DatePicker";

import { donationHistoryList } from "@/api/donation-api";
import { donationTypes, paymentTypes } from "@/types/enum";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";

import "@/styles/dashboard/donation-history.less";
import styled from "styled-components";
import queryString from "query-string";
import { getToken } from "@/utils/storage";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import AddRoleButton from "@/pages/dashboard/accountSetting/AddRoleButton";
import { view } from "@/model/view";
import { NoTranslate } from "@/components/GoogleTranslate";
const DonationHistoryContainer = styled.div``;

const DonationHistory = () => {
  const history = useHistory();
  const { store } = useModel(user);
  const { store: { isMobile } } = useModel(view)
  //handler
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/donation/export/csv/${getToken()}?${queryString.stringify({
        ...others,
      })}`,
      "_blank"
    );
  };

  //table
  const columns: any[] = [
    {
      title: "donate to",
      width: 120,
      dataIndex: "receiverName",
      fixed: "left",
      ellipsis: true,
      render: (text: string, row: any) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Donation type",
      dataIndex: "type",
      width: 120,
      ellipsis: true,
      render: (text: string, row: any) => <NoTranslate>{donationTypes[text || "_null"]}</NoTranslate>,
    },
    {
      title: "amount",
      width: 100,
      dataIndex: "amount",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "Payment method",
      width: 180,
      dataIndex: "paymentMethod",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },

    {
      title: "Donation date",
      width: 150,
      dataIndex: "createDate",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "File",
      dataIndex: "id",
      width: 280,
      render: (text: string, row: any) =>
        (text && (
          <Link to={`/dashboard/history/${text}/note`}>
            <FilePdfOutlined /> Acknowledgement-letter.pdf
          </Link>
        )) ||
        "N/A",
    },
    {
      title: "",
      width: 80,
      align: "center",
      key: "action",
      render: (_: any, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item
                  onClick={() => history.push(`/dashboard/history/${row.id}`)}
                >
                  View order
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
  });
  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState([]);

  const getList = () => {
    donationHistoryList(params).then((data) => {
      setTableData(data.items);
      setTotal(data.meta.totalItems);
    });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);
  useEffect(() => { }, []);
  return (
    <DonationHistoryContainer className="donation-history-container">
      <h1 className="flex">
        Donation History {!store.roles.includes("artist") && <AddRoleButton />}
      </h1>
      <div className="search-wrap flex">
        <div className="flex">
          <div className="range-picker-wrap field-form-items">
            <span>date</span>
            <DatePicker.RangePicker
              disabledDate={(current) => {
                return current && current > dayjs().endOf("day");
              }}
              onChange={(e) => {
                const [startDate, endDate] = e || ["", ""];
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  startTime: startDate,
                  endTime: endDate,
                });
              }}
            />
          </div>
          <div className="field-form-items">
            <span>Payment method</span>
            <Select
              bordered={false}
              allowClear
              placeholder="Please select "
              onChange={(e) => {
                setParams({
                  ...params,
                  paymentMethod: e,
                  page: 1,
                  limit: 10,
                });
              }}
            >
              {Object.entries(paymentTypes)
                ?.slice(0, 3)
                .map((item: any) => (
                  <Select.Option key={item[0]} value={item[0]}>
                    {item[1]}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <Dropdown
          className="filter-item"
          trigger={["click"]}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={handleExport}>Export CSV</Menu.Item>
            </Menu>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      </div>
      {/* <div className="table-item"> */}
      <Table
        className="table-item table-container"
        title={() => (
          <div className="flex">
            <span>Donations</span>
            <Input.Search
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          simple: isMobile,
          showTotal: (total: any) => `Total contributions :  ${total} `,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={columns}
        dataSource={tableData}
      />
      {/* </div> */}
    </DonationHistoryContainer>
  );
};

export default DonationHistory;
