//created by Peggy on 2021/3/19
import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, Divider, Form, Input, Select, Tooltip, message } from "antd";
import { stateList } from "@/types/enum";

import { getPayable, savePayable } from "@/api/artist-api";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const SettingContainer = styled.div``;

const Setting = () => {
  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    const {bankStreet,bankCity,bankState,bankZipCode} = values;
    const bankAddress = [bankStreet,bankCity,bankState,bankZipCode].filter(e => !!e).join(', ')
    savePayable({
      ...values,
      bankAddress
    }).then((data) => message.success("save successfully"));
  };

  const onFinishFailed = ({errorFields}: any) => {
    if(errorFields?.length > 0){
      message.error(errorFields[0].errors[0]);  
    }
  };

  useEffect(() => {
    getPayable().then((data) => form.setFieldsValue(data));
    // eslint-disable-next-line
  }, []);

  return (
    <SettingContainer className="setting-membership-container common-container">
      <h1>Payable settings</h1>
      <h4>Please provide your financial institution’s information so you can receive contributions via direct deposit. All fields marked with a red asterisk (*) are required. <b>Please note that we can only disburse funds to U.S-based bank accounts.</b></h4>
      <div className="card-wrap">
        <Form
          layout="vertical"
          size="large"
          name="basic"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h2>Bank information</h2>
          <Form.Item
            label="Bank name"
            name="bankName"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Please enter bank Name",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter bank name"
            />
          </Form.Item>
          <div className="flex start align-start row-flex">
          <Form.Item
            label='BANK STREET ADDRESS'
            name="bankStreet"
            rules={[
              {
                required: true,
                whitespace: false,
                message: "Please enter bank street address.",
              },
            ]}
          >
            <Input placeholder="Enter bank street address" />
          </Form.Item>
          <Form.Item
              label={<>BANK State<Tooltip
                className="tooltip-field-item"
                title="We can only disburse funds to U.S-based bank accounts."
              >
                <ExclamationCircleOutlined style={{fontSize:'12'}}/>
              </Tooltip></>}
              name="bankState"
              className="select-item"
              rules={[
                {
                  required: true,
                  whitespace: false,
                  message: "Please select state",
                },
              ]}
            >

              <Select
                showSearch
                size="large"
                placeholder="State"
              >
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex start align-start row-flex">
            <Form.Item
              label='BANK CITY'
              name="bankCity"
              rules={[
                {required: true, whitespace: false, message: "Please enter bank city." },
              ]}
            >
              <Input maxLength={50} placeholder="Enter bank city" />
            </Form.Item>
            <Form.Item
            label="Zip Code"
            name="bankZipCode"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Please enter your 5-digit zip code.',
                validator: (_, value) => {
                  if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)){
                    return Promise.resolve()
                  }else{
                    return Promise.reject()
                  }
                }
              }
            ]}
          >
            <Input placeholder="Enter Zip Code" minLength={5} maxLength={5} />
          </Form.Item>
          </div>
          <Divider />
          <h2>Account information</h2>
          <Form.Item
            label="Account number"
            name="accountNumber"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter account number",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter account number"
            />
          </Form.Item>
          <Form.Item
            label="account name"
            name="accountName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter account name",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={50}
              placeholder="Enter account name"
            />
          </Form.Item>
          <Form.Item
            label="account type"
            name="accountType"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter account type",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter account type"
            />
          </Form.Item>
          <Form.Item
            label="ABA / routing number"
            name="abaOrRoutingNumber"
            rules={[
              {
                required: true,
                whitespace: true,
                validator: (_, value) =>
                  value.length === 9 ? Promise.resolve() : Promise.reject(new Error('Please enter 9-digit ABA / routing number')),
                message: "Please enter 9-digit ABA / routing number",
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter 9-digit ABA / routing number"
            />
          </Form.Item>
          <Form.Item
            label="Swift code"
            name="swiftCode"
          >
            <Input
              autoComplete="off"
              maxLength={30}
              placeholder="Enter swift code"
            />
          </Form.Item>
        </Form>
      </div>
      <p className="flex end">
        <Button type="primary" onClick={() => form.submit()}>
          save
        </Button>
      </p>
    </SettingContainer>
  );
};

export default Setting;
