//created by Peggy on 2021/3/11
import React from "react";
import styled from "styled-components";

import CardItem from "@/pages/dashboard/income/CardItem";
import { Pie } from "@ant-design/charts";
import { ReactComponent as NoData } from "@/images/icon_stat.svg";
import { useModel } from "use-reaction";
import { view } from "@/model/view";

const PieCardContainer = styled.div``;

interface Props {
  data: any[];
}

const PieCard = ({ data = [] }: Props) => {
  const maxValLen = data.reduce((len, cur) => {
    return Math.max(cur.value.toString().length, len);
  }, 1);

  const {
    store: { isMobile },
  } = useModel(view);
  //pie config
  const pie: any = {
    width: 525,
    height: isMobile ? 530 : 226,
    autoFit: false,
    padding: [30, 320, 30, 16],
    appendPadding: 0,
    data,
    angleField: "value",
    colorField: "type",
    color: ["#FF7456", "#25F2FF", "#B455FF", "#AEDD29"],
    radius: 1,
    innerRadius: 0.72,
    renderer: "svg",
    statistic: {
      title: {
        style: {
          fontSize: "12px",
          color: "#ccc",
          lineHeight: "20px",
          marginTop: "26px",
        },
        customHtml: () => {
          return `<span>total earned</span>`;
        },
      },
      content: {
        style: {
          fontSize: "20px",
          color: "#304659",
          lineHeight: "20px",
          marginTop: "-20px",
        },
      },
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.7,
        },
      },
    },
    meta: {
      value: {
        formatter: function formatter(v: any) {
          const _v = v?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return "$ ".concat(_v);
        },
      },
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: { textAlign: "center" },
      autoRotate: false,
      content: "{value}",
    },
    interactions: [{ type: "element-active" }],
    legend: {
      position: "left",
      flipPage: false,
      offsetX: isMobile ? 20 : 202,
      offsetY: isMobile ? -168 : 0,
      maxItemWidth: 260,
      itemHeight: 24,
      itemValue: {
        alignRight: true,
        formatter: (text: string, item: any, index: number) => {
          return document.body.offsetWidth > 1380 || isMobile
            ? `${Number(
                (data[index].value * 100) /
                  (data.reduce((sum, cur) => sum - 0 + cur?.value, 0) || 1)
              ).toFixed(0)}% ${new Array(
                maxValLen - data[index].value.toString().length + 1
              ).join("   ")}   $${data[index].value
                ?.toString()
                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : "";
        },
      },
    },
  };

  return (
    <PieCardContainer className="pie-card-container flex-1">
      <CardItem title="Income overview">
        {data.reduce((v, cur) => v + cur.value, 0) > 0 ? (
          <Pie {...pie} />
        ) : (
          <div className="no-data">
            <NoData />
            <h4>No statistic yet</h4>
            <p>
              Set up your profile, get funded, and start seeing valuable
              insights{" "}
            </p>
          </div>
        )}
      </CardItem>
    </PieCardContainer>
  );
};

export default React.memo(PieCard);
