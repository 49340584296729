import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const InputContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 56px;
  padding: 8px 11px 4px;
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
  label {
    text-transform: uppercase;
    font-size: 10px;
    color: #a0a0a0;
    height: 12px;
    margin-bottom: 3px;
    letter-spacing: 1px;
  }
  .ant-input-affix-wrapper {
    border: 0;
    padding: 0;
    box-shadow: none;
  }
  input {
    box-shadow: none;
    outline: none;
    border: 0;
    height: 28px;
    font-size: 16px;
  }
`;

interface props {
  value?: any;
  label: string;
  placeholder?: string;
  maxLength?: number;
  onChange?: (e: any) => void;
  className?: string;
}

const Index = ({
  label = "",
  maxLength = 50,
  placeholder = "Input",
  onChange = () => {},
  className = "",
}: props) => {
  return (
    <InputContainer className={`input-component-container ${className}`}>
      <label>{label}</label>
      <Input
        allowClear
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </InputContainer>
  );
};

export default Index;
