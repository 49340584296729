import request from "@/libs/fetch";
import { router_insta_auth } from "@/types/enum";

export const getInstaAppInfo = () => {
  return request("/artist/instagram/app/", { type: 'get' })
};

export const getInstaUser = async (code: string) => {
  return await request('/artist/instagram/user', {
    data: { code, redirect_uri: window.location.origin + router_insta_auth }
  });
};

export async function putInstInfo(info: { id: number, insId: string, insName: string, token: string }) {
  return await request("/artist/instagram", { type: 'put', data: info })
}
export async function delInstInfo(profile: number) {
  return await request(`/artist/instagram/${profile}`, { type: 'delete' })
}
export const getMedias = (id: string | number, size: number) => {
  return request(`/artist/instagram/medias`, {
    type: 'post',
    data: {
      id: id,
      count: size,
    },
  })
};
