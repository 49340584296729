import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb, Dropdown, Input, Menu, message, Table } from "antd";
import dayjs from "dayjs";

import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { paymentTypes, UserRole } from "@/types/enum";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import useConfirm from "@/components/Modal/Confirm";
import { recurring_cancel, recurring_list } from "@/api/payment-api";
import "./recurring_setting.less";
import { user } from "@/model/user";

export const RecurringSetting = () => {
  const history = useHistory();
  const {
    store: { isMobile },
  } = useModel(view);
  const { store: { roles } } = useModel(user)
  const [canceling, setCanceling] = useState<any>();
  const { setShow: deleteShow, component: deleteComponent } = useConfirm(
    <div className="flex column start align-start">
      <p>
      By clicking Cancel Recurring Payment, you will stop this monthly donation. 
      Your payment will be canceled as of {" "}
        {dayjs().format("MM/DD/YYYY")}. 
        You may set up a new one-time or recurring donation anytime.{" "}
      </p>
    </div>,
    {
      title: "Are you sure you want to cancel?",
      className: "suspend-confirm-modal",
      okText: "cancel recurring payment",
      onOk: (id?: any) => {
        recurring_cancel({ id }).then(() => {
          getList();
          message.success("Payment canceled successfully");
        });
      },
    }
  );

  const changeMethod = (row: any) => {
    if (row.productType === "donation") {
      history.push({ pathname: "/dashboard/recurring-change", state: row });
    } else {
      history.push("/dashboard/membership/overview");
    }
  };

  const column: any[] = [
    {
      title: "Product type",
      dataIndex: "productType",
      width: 150,
      fixed: "left",
      render: (text: string) =>
        text === "donation" ? "Donation" : "Fiscal Sponsorship",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      fixed: isMobile ? undefined : "left",
      render: (frequency: string) => {
        switch (frequency) {
          case "month":
            return "Recurring - Monthly";
          case "year":
            return "Recurring - Anually";
          case "once":
          default:
            return "One time";
        }
      },
    },
    {
      title: "Receiver name",
      width: 150,
      dataIndex: "receiverName",
    },
    {
      title: "Amount",
      width: 100,
      dataIndex: "amount",
      render: (text: string, row: any) =>
        Number(text).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: "payment method",
      dataIndex: "paymentMethod",
      width: 180,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "Next charge",
      width: 130,
      dataIndex: "nextCharge",
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "Date created",
      dataIndex: "createDate",
      width: 130,
      render: (text: string, row: any) =>
        (text && dayjs(text).format("MM/DD/YYYY")) || "N/A",
    },
    {
      title: "",
      width: 80,
      fixed: isMobile ? undefined : "right",
      align: "center",
      key: "action",
      render: (_: string, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item key="chg" onClick={() => changeMethod(row)}>
                  Edit this payment
                </Menu.Item>
                <Menu.Item key="ccl" onClick={() => setCanceling(row)}>
                  Cancel
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [text, setText] = useState("");
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 10,
    keyword: "",
  });

  const [total, setTotal] = useState(0);
  const [tableData, setTableData] = useState<any[]>();
  // const [selectedRows, setSelectedRows] = useState<any>([]);

  const getList = () => {
    recurring_list({ ...params })
      .then(({ items, meta }: any) => {
        setTableData(items);
        setTotal(meta.totalItems);
      })
      .catch(() => {
        setTotal(0);
        setTableData([]);
      });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    canceling?.id && deleteShow(canceling.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canceling?.id]);

  return (
    <div className="common-container recurring-container">
      {
        roles?.includes(UserRole.ARTIST) &&
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard/membership/payable">
            Payment settings
          </Breadcrumb.Item>
        </Breadcrumb>
      }
      <h1>Recurring Payments</h1>
      {/* <div className="table-container"> */}
      <Table
        className="table-container"
        title={() => (
          <div className="flex">
            <span>Transaction details</span>
            <Input.Search
              value={text}
              onChange={(e) => setText(e.target.value)}
              onSearch={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  limit: 10,
                  keyword: e,
                });
              }}
              placeholder="Search"
            />
          </div>
        )}
        rowKey="id"
        // rowSelection={{
        //     type: "checkbox",
        //     onChange: (keys, rows) => {
        //         setSelectedRows(rows);
        //     },
        // }}
        pagination={{
          total,
          current: params.page,
          pageSize: params.limit,
          simple: isMobile,
          showTotal: (total: any) => `Total :  ${total} `,
          onChange: (page, pageSize) =>
            setParams({
              ...params,
              page,
              limit: pageSize,
            }),
        }}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
      />
      {/* </div> */}
      {deleteComponent}
    </div>
  );
};
