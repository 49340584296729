import request from "@/libs/fetch";

export const getCustomer = async () =>
  await request("/user/stripe-customer", {
    type: "get",
  });

export const subscribeStripe = async (data: any) =>
  await request("/sponsorship/subscribe-by-stripe", {
    type: "post",
    data,
  });

export const renewByStripe = async (data: any) =>
  await request("/sponsorship/renew-by-stripe", {
    type: "post",
    data,
  });

//
export const subscribePayPal = async (data: any) =>
  await request("/sponsorship/subscribe-by-paypal", {
    type: "post",
    data,
  });

export const getSubscribePrice = async (params: any) =>
  await request("/sponsorship/price-list", {
    type: "get",
    params,
  });

export const subscribeStripeChange = async (data: any) =>
  await request("/sponsorship/change-subscription-by-stripe", {
    type: "post",
    data,
  });

export const subscribePaypalChange = async (data: any) =>
  await request("/sponsorship/change-subscription-by-paypal", {
    type: "post",
    data,
  });

export const subscribeInfo = async () =>
  await request("/sponsorship/subscription-info", {
    type: "get",
  });

export const checkSubscribe = async (data: any) =>
  await request("/sponsorship/check-paypal-subscription", {
    data,
    type: "post",
  });

export const recurring_list = async (params: any) =>
  await request("/recurring", {
    params,
    type: "get",
  });

export const recurring_cancel = async (data: { id: number, email?: boolean }) =>
  await request("/recurring/cancel", {
    data,
    type: "post",
  });

export const recurring_change = async (data: {
  orderId: number,
  recurringDate: Date,
  paymentMethodId?: string// required by stripe
}, by: 'stripe' | 'paypal') =>
  await request("/recurring/change-by-" + by, {
    data,
    type: "post",
  });

export const grant_rush = async (data: {
  orderId: number,
  coupon?: string,
  paymentMethodId?: string// required by stripe
}) =>
  await request("/grant/application/rush-payment", {
    data,
    type: "post",
  });
