//created by Peggy on 2021/3/1
import React, { useEffect, useState } from "react";

import { Button } from "antd";
import Modal from "@/components/Modal/Info";

import styled from "styled-components";
import Search from "./Search";
import InKindModal from "./InKindModal";
import InKindTable from "./InkindTable";

import { InKindList } from "@/api/donation-api";

import "@/styles/dashboard/donation.less";
import { getSponsor } from "@/api/merbership-api";
import { useHistory } from "react-router-dom";
import icon_grant from "@/images/icons/icon_grant.svg";
import { useModel } from "use-reaction";
import { view } from "@/model/view";
import {
  getPopupButtonByMembershipStatus,
  getPopupDescByMembershipStatus,
  getPopupTitleByMembershipStatus,
} from "@/utils/common";

const FundRaiseContainer = styled.div``;

const InKind = () => {
  //table
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState<any>({
    page: 1,
    pageSize: 10,
  });
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [sponsorship, setSponsorship] = useState(true);
  const [membershipStatus, setMembershipStatus] = useState("");
  const {
    store: { isMobile },
  } = useModel(view);
  useEffect(() => {
    getSponsor({}).then((data: any) => {
      setSponsorship(data.status === "completed");
      setMembershipStatus(data.status);
    });
  }, []);

  const getList = () => {
    const { pageSize: limit, ...others } = params;
    let _data = {
      type: "in_kind_donation_to_artist",
      ...others,
      limit,
    };
    InKindList(_data).then((data) => {
      setTableData(data.items);
      setTotal(data?.meta?.totalItems);
    });
  };
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [params]);

  //Modal
  const [show, setShow] = useState(false);

  return (
    <FundRaiseContainer className="fund-raise-container table-component-container flex column start align-start">
      <h1>In-kind donations</h1>
      <div className="table-wrap">
        <div className="flex title">
          <div className="flex">
            <Search
              hiddenPayment
              filter={false}
              value={params}
              onChange={(e) => {
                setTableData([]);
                setParams({
                  ...params,
                  page: 1,
                  ...e,
                });
              }}
              hiddenDropdown
              action={[]}
            />
          </div>
          <div className="flex">
            <Button
              type="primary"
              onClick={() => {
                setShow(true);
              }}
            >
              Add an in-kind donation
            </Button>
          </div>
        </div>
        {/* <div className="table-item"> */}
        <InKindTable
          className="table-item table-container"
          tableData={tableData}
          onSearch={(e) => {
            setTableData([]);
            setParams({
              ...params,
              page: 1,
              keyword: e,
            });
          }}
          scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
          pagination={{
            total,
            current: params.page,
            pageSize: params.pageSize,
            simple: isMobile,
            onChange: (page: number, pageSize: number) => {
              setParams({
                ...params,
                ...{
                  page,
                  pageSize,
                },
              });
            },
          }}
        />
        {/* </div> */}
      </div>
      <InKindModal
        show={show && sponsorship}
        onClose={() => {
          setShow(false);
          getList();
        }}
      />

      <Modal
        visible={!sponsorship}
        width={420}
        className="grant-info-modal"
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => history.push("/dashboard/membership/fiscal")}
          >
            {getPopupButtonByMembershipStatus(membershipStatus)}
          </Button>,
        ]}
        img={icon_grant}
      >
        <h2>
          {getPopupTitleByMembershipStatus(
            membershipStatus,
            "One More Step to Add In-Kind Donations"
          )}
        </h2>
        <p>
          {getPopupDescByMembershipStatus(
            membershipStatus,
            "In order to solicit in-kind donations through The Field, you will have to sign up for Fiscal Sponsorship, and be directed to the Membership - Fiscal Sponsorship flow."
          )}
        </p>
      </Modal>
    </FundRaiseContainer>
  );
};

export default InKind;
