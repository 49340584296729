//created by Peggy on 2021/3/11
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Search from "@/pages/dashboard/donation/Search";
import InKindModal from "@/pages/dashboard/donation/InKindModal";
import GeneralFundModal from "@/pages/dashboard/donation/GeneralFundModal";

import "@/styles/dashboard/income.less";

import { incomeList } from "@/api/donation-api";
import { Dropdown, Input, Menu, Table, Tag, Tooltip } from "antd";
import { ReactComponent as FilterMore } from "@/images/filter-more.svg";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useModel } from "use-reaction";
import { user } from "@/model/user";
import { donationTypes, paymentTypes, statusTypes } from "@/types/enum";
import { view } from "@/model/view";
import { QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NoTranslate } from "@/components/GoogleTranslate";
import dayjs from "dayjs";

const IncomeDetailContainer = styled.div``;

const IncomeDetail = () => {
  const history = useHistory();
  const {
    store: {
      info: { id: userId },
    },
  } = useModel(user);
  //table
  const column: any[] = [
    {
      title: "Donation type",
      dataIndex: "type",
      key: "type",
      width: 110,
      ellipsis: true,
      fixed: "left",
      render: (text: string, row: any) => <NoTranslate>{donationTypes[text || "_null"]}</NoTranslate>,
    },
    {
      title: "Donor email",
      dataIndex: "donorEmail",
      ellipsis: true,
      key: "email",
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Donor",
      dataIndex: "donor",
      ellipsis: true,
      width: 110,
      key: "name",
      render: (text: string) => <NoTranslate>{text}</NoTranslate>,
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      width: 180,
      key: "payment",
      render: (text: string, row: any) => paymentTypes[text || "_null"],
    },
    {
      title: "Donation Date",
      dataIndex: "createDate",
      width: 150,
      key: "createDate",
      render: (text: string, row: any) => {
        if(row.paymentMethod === 'check'){
          if(row.status === 'check_pending'){
            return 'N/A';
          }
          return dayjs(row.billDate || row.createDate).format("MM/DD/YYYY")
        }else{
          return dayjs(row.createDate).format("MM/DD/YYYY")
        }
      },
    },
    {
      title: (
        <>
          STATUS
          <Tooltip
            overlay={
              <ul>
                <li>
                  Paid - Donation successful; payment to artist scheduled or
                  complete.
                </li>
                <li>
                  Pending - Donation initiated; verifying payment information.
                </li>
                <li>
                  Check Pending - Check entered online; will process upon
                  receipt.
                </li>
                <li>
                  Processing - Donation successful; payment to artist scheduled
                  for 10 days later.
                </li>
                <li>
                  Failed - Donor’s payment information incomplete or declined.
                </li>
              </ul>
            }
          >
            <QuestionCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "status",
      width: 110,
      key: "status",
      render: (text: string, _: any) => (
        <Tag color={statusTypes[text ?? "processing"].color}>
          {statusTypes[text ?? "processing"].label}
        </Tag>
      ),
    },
    {
      title: 'Frequency',
      width: 130,
      dataIndex: 'interval',
      render: (text: string) => text === "once"
        ? "One Time"
        : text === "month"
          ? "Recurring - Monthly "
          : "Recurring - Anually"
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      width: 150,
      align: "right",
      key: "amount",
      render: (text: number) =>
        Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        }),
    },
    {
      title: (
        <>
          Fee
          <Tooltip overlay='Fee data may not be available for donations made prior to July of 2021' >
          <ExclamationCircleOutlined
              size={12}
              style={{ padding: 6, minWidth: 20 }}
            />
          </Tooltip>
        </>
      ),
      dataIndex: "fees",
      width: 80,
      align: "right",
      key: "fees",
      render: (text: number,row:any) =>{
        if(dayjs(row.createDate).isBefore('2021-06-05','day')){
          return 'N/A'
        }
        return Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })
      }
    },
    {
      title: "Received Amount",
      dataIndex: "received",
      width: 150,
      align: "right",
      key: "received",
      render: (text: number,row:any) => {
        if(dayjs(row.createDate).isBefore('2021-06-05','day')){
          return 'N/A'
        }
        return Number(text || 0).toLocaleString("en", {
          style: "currency",
          currency: "USD",
        })
      }
    },
    {
      title: "",
      width: 80,
      align: "right",
      key: "action",
      render: (_: any, row: any) => (
        <Dropdown
          trigger={["click"]}
          overlay={() => (
            <>
              <Menu>
                <Menu.Item onClick={() => handLink(row)}>
                  View Order Detail
                </Menu.Item>
                <Menu.Item 
                  hidden={row.status === 'failed'}
                  onClick={() =>
                    history.push(`/dashboard/income/detail/${row.id}/note`)
                  }
                >
                  View thank you note
                </Menu.Item>
              </Menu>
            </>
          )}
        >
          <span className="ant-dropdown-link">
            <FilterMore />
          </span>
        </Dropdown>
      ),
    },
  ];
  const [params, setParams] = useState<any>({
    page: 1,
    limit: 25,
    keyword: "",
    status: Object.keys(statusTypes).filter(_ => !['failed','refund_processing','refunded'].includes(_)),
  });
  const [tableCount, setTableCount] = useState({
    total: 0,
    pageIndex: 0,
    pageTotal: 0,
    fees: 0,
    amount: 0,
    received: 0,
  });
  const [tableData, setTableData] = useState<any[]>([]);

  const handLink = (row: any) => {
    if (row.type === "donation_to_artist" || row.type === "crowdfunding") {
      history.push(`/dashboard/income/detail/${row.id}`);
    } else {
      history.push(`/dashboard/donation/inKind/${row.id}`);
    }
  };

  const getList = () => {
    const { range, ...others } = params,
      [startAmount, endAmount] = range || [null, null],
      _data = {
        ...others,
        startAmount,
        endAmount,
      };
    incomeList(_data).then((data) => {
      if (!data?.items?.length) return false;
      setTableData(data.items);
      setTableCount({
        ...tableCount,
        ...data?.summary,
        total: data?.meta?.totalItems || 0,
        pageIndex: data?.meta?.currentPage || 0,
        pageTotal: data?.meta?.totalPages || 0,
      });
    });
  };
  const reset = () => {
    setTableData([]);
    setTableCount({
      total: 0,
      pageIndex: 0,
      pageTotal: 0,
      fees: 0,
      amount: 0,
      received: 0,
    });
  };

  useEffect(() => {
    if (params) {
      getList();
    }
    // eslint-disable-next-line
  }, [params]);

  //Modal
  const [show, setShow] = useState(false);
  const [showInKind, setShowInKind] = useState(false);
  const {
    store: { isMobile },
  } = useModel(view);
  const handleExport = () => {
    const { page, limit, ...others } = params;

    window.open(
      `${process.env.REACT_APP_API_HOST
      }/income/export-csv?${queryString.stringify({
        ...others,
        userId,
        tz: dayjs.tz.guess()
      })}`,
      "_blank"
    );
  };

  return (
    <IncomeDetailContainer className="income-detail-container common-container">
      <h1>Income details</h1>
      <Search
        value={params}
        filter={!isMobile}
        onChange={(e) => {
          // console.log(e)
          reset();
          setParams({
            ...params,
            ...e,
            page: 1
          });
        }}
        action={[
          {
            text: "Archived Income",
            onClick: () => {
              history.push("/dashboard/income/archived");
            },
          },
          {
            text: "Add check donation",
            onClick: () => {
              setShow(true);
            },
          },
          {
            text: "Add in-kind donation",
            onClick: () => {
              setShowInKind(true);
            },
          },
          "export",
        ]}
        onExport={handleExport}
      />
      {/* <div className="table-wrap"> */}
      <Table
        className="table-item table-container"
        title={() => (
          <div className="flex">
            <span>Donation details</span>
            <Input.Search
              onSearch={(e) => {
                reset()
                setParams({
                  ...params,
                  keyword: e,
                  page: 1
                })
              }
              }
              placeholder="Search"
            />
          </div>
        )}
        columns={column}
        dataSource={tableData}
        sticky
        showHeader={!!tableData?.length}
        scroll={{ scrollToFirstRowOnChange: true, x: "max-content" }}
        pagination={{
          pageSize: params.limit,
          current: tableCount.pageIndex,
          total: tableCount.total,
          simple: isMobile,
          onChange: (_page, limit) => {
            let page = _page
            if (limit !== params.limit) {
              page = 1
            }
            setParams({ ...params, page, limit });
          },
        }}
      />
      {/* </div> */}
      <InKindModal show={showInKind} onClose={() => setShowInKind(false)} />
      <GeneralFundModal show={show} onClose={() => setShow(false)} />
    </IncomeDetailContainer>
  );
};

export default IncomeDetail;
