//created by Pegg on 2021/2/19
import React from "react";
import styled from "styled-components";
import { Button, Select } from "antd";

import {
  EyeInvisibleOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";

const EditBarContainer = styled.div``;

interface Props {
  text?: string;
  hide?: boolean;
  hideButton?: boolean;
  hideShowButton?: boolean;
  onShow?: (val: boolean) => void;
  onHide?: (val: boolean) => void;
  model?: string;
  modelData?: any;
}

const EditBar = ({
  text = "Artist information section",
  hide = true,
  hideButton = false,
  hideShowButton = false,
  onShow,
  onHide = () => {},
  model,
  modelData,
}: Props) => {
  let isMobile = document.body.offsetWidth < 1024;
  return (
    <EditBarContainer className="edit-bar-wrap">
      <i>{text}</i>
      {!hideButton && (!model || isMobile) && (
        <>
          <Button className="ele-desktop" onClick={() => onHide(!hide)}>
            {!hide ? "Show" : "Hide"}
          </Button>
          <Button
            className="ele-mobile"
            onClick={() => onHide(!hide)}
            icon={!hide ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          />
        </>
      )}
      {model === "about" && (
        <div className="model-wrap flex">
          {!isMobile && (
            <div className="flex start">
              <span>Section Visibility:</span>
              <Select
                bordered={false}
                value={Number(!hide)}
                onChange={(e) => onHide(!e)}
              >
                <Select.Option value={0}>Showing</Select.Option>
                <Select.Option value={1}>Hide</Select.Option>
              </Select>
            </div>
          )}

          <div className="flex">
            <Select
              bordered={false}
              value={modelData?.biographyFormat}
              onChange={(e) => modelData?.setBiographyFormat(e)}
            >
              <Select.Option value="plain">Standard Layout</Select.Option>
              <Select.Option value="rich">Custom Layout</Select.Option>
            </Select>
          </div>
        </div>
      )}
      {!hideShowButton && (
        <>
          <Button
            className="ele-desktop"
            type="primary"
            onClick={() => {
              onShow && onShow(true);
            }}
          >
            Edit
          </Button>
          <Button
            className="ele-mobile"
            onClick={() => {
              onShow && onShow(true);
            }}
            icon={<EditOutlined />}
          />
        </>
      )}
    </EditBarContainer>
  );
};

export default EditBar;
