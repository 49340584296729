//created by Peggy on 2021/5/4
import React, { useEffect, useState } from "react";
import { Divider, Button } from "antd";

import FooterLayout from "@/components/layouts/FooterLayout";
import HeaderLayout from "@/components/layouts/HeaderLayout";
import Caption from "@/components/Image/caption";

import { useHistory, useParams } from "react-router-dom";
import { getProject } from "@/api/artist-api";

import Plyr from "plyr-react";
import "@/styles/home/preview.less";

import avatar_img from "@/images/avatar.svg";

import { useModel } from "use-reaction";
import { user } from "@/model/user";
import styled from "styled-components";
import { view } from "@/model/view";
const ProjectContainer = styled.div``;

const Project = () => {
  const { id } = useParams() as any;
  const history = useHistory();
  const [info, setInfo] = useState<any>("");
  const { store } = useModel(user);
  const { store: { isMobile } } = useModel(view)
  useEffect(() => {
    getProject(id).then((data: any) => {
      setInfo(data);
    });
  }, [id]);
  return (
    <ProjectContainer className="project-preview-container event-preview-container common-container">
      <div className="event-header">
        {store?.info?.id === info?.artist?.id && !isMobile && (
          <Button
            className="back-to-btn"
            size="small"
            type='primary'
            onClick={() =>
              history.push(
                `/home/profile/${info?.artist?.id}/edit?tab=project&projectId=${info?.id}`
              )
            }
          >
            Back to edit
          </Button>
        )}
        <HeaderLayout hideMenu />
        <div className="header-content">
        {store?.info?.id === info?.artist?.id && isMobile && (
          <Button
            className="back-btn"
            size="small"
            type='primary'
            style={{marginBottom: '8px'}}
            onClick={() =>
              history.push(
                `/home/profile/${info?.artist?.id}/edit?tab=project&projectId=${info?.id}`
              )
            }
          >
            Back to edit
          </Button>
        )}
          <h4>PROJECT</h4>
          <h1>{info?.title}</h1>
          <p
            className="event-content-user"
            onClick={() =>
              history.push(
                `/profile/${(info?.artist?.displayName || "-").replace(
                  /[^\w]/g,
                  "-"
                )}/${info?.artist?.id}`
              )
            }
          >
            <img src={info?.artist?.avatar || avatar_img} alt="" />{" "}
            {info?.artist?.displayName}
          </p>
          <p>{info?.synopsis}</p>
        </div>
      </div>
      <Divider />
      <div className="event-media">
        {(info?.video && (
          <Plyr
            source={
              info?.video?.indexOf("youtu") > -1
                ? {
                  type: "video",
                  sources: [
                    {
                      src:
                        info?.video
                          ?.match(
                            /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
                          )
                          ?.pop() || "acc",
                      provider: "youtube",
                    },
                  ],
                }
                : {
                  type: "video",
                  sources: [
                    {
                      src:
                        info?.video?.match(/vimeo\.com\/([\d]{8,})/)?.pop() ||
                        "acc",
                      provider: "vimeo",
                    },
                  ],
                }
            }
          />
        )) || (
            <Caption
              className="feature-photo"
              src={info?.featurePhotos && info?.featurePhotos[0]}
            />
          )}
      </div>
      <div className="event-content">
        <input type="checkbox" hidden id="more-text" />
        <p
          dangerouslySetInnerHTML={{
            __html: info?.description || "Tell your story",
          }}
        />
      </div>
      <div className="event-photo-list">
        {info?.photos?.map((item: any) => (
          <Caption src={item} key={item} />
        ))}
      </div>
      <FooterLayout />
    </ProjectContainer>
  );
};

export default Project;
