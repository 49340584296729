//created by Pegg on 2021/1/25
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button } from "antd";

import HomeLayout from "@/components/layouts/HomeLayout";
import success_img from "@/images/icons/icon_donation.svg";

import { getToken } from "@/utils/storage";
import { getDonor } from "@/api/sign-api";

import styled from "styled-components";
import { user, userLogin } from "@/model/user";
import { useModel } from "use-reaction";
import { donation, saveDonation as saveDonationStore } from "@/model/donation";
import { TheField } from "@/components/GoogleTranslate";
const DonationSuccessContainer = styled.div``;

const Success = () => {
  const history = useHistory();
  const { id } = useParams() as any;
  const { doAction: donationAction } = useModel(donation);

  const { doAction } = useModel(user);

  const [isRegister, setRegister] = useState<boolean>(false);

  const createAccount = () => {
    getDonor(id).then(async (data) => {
      await doAction(userLogin, {
        save: true,
        token: data.token,
      });
      history.push(`/sign/password/change/${data.id}/${data.salt}/donor`);
    });
  };

  useEffect(() => {
    getDonor(id).then((data) => setRegister(data.login));
    donationAction(saveDonationStore, {
      dAmount: "",
      cAmount: "",
      fieldAmount: "",
      cover: false,
      frequency: "once",
      message: "",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <HomeLayout>
      <DonationSuccessContainer className="donation-success-container flex column">
        <img src={success_img} alt="" />
        <h1>Donation received. Thank you!</h1>
        <h4>
          Save your info so you can check out faster, manage your donations, and
          get
          <br /> updates on artists you love.
        </h4>
        {!getToken() &&
          ((!isRegister && (
            <Button type="primary" onClick={createAccount}>
              Create a donor account
            </Button>
          )) || (
            <p style={{ textAlign: "center" }}>
              You have created an account on <TheField/> already. <br /> Please
              click here to <Link to="/sign/in"> sign in </Link>.
            </p>
          ))}

        <p>
          <a href={`mailto:support@thefield.org`}>Contact us</a>
        </p>
      </DonationSuccessContainer>
    </HomeLayout>
  );
};

export default Success;
